import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserDto, UserShortDto } from "../../../api";
import { Avatar, Spin, Text } from "../../uiKit";
import { UserTooltip } from "../userTooltip/UserTooltip";
import { TUserInfoNameDisplayMode } from "./UserInfo";
import "./UserInfo.scss";
import { getUserShortFullName } from "../../../helpers/stringFunctions";
import { useUserNameFormat } from "../../../hooks/useUserNameFormat";

// import { useUserNameFormat } from "../../../hooks/useUserNameFormat";

export interface IUserInfoView {
  className?: string;
  isLoading?: boolean;
  user: UserDto | UserShortDto | undefined;
  isTooltipDisabled?: boolean;
  avatarSize?: number;
  nameDisplayMode?: TUserInfoNameDisplayMode;
  width?: string;
  nameStyle?: React.CSSProperties;
  onError: () => void;
  zIndex?: number;
  isStaticImg?: boolean;
  isLineThrough?: boolean;
  isShowIsFired?: boolean;
}

export const UserInfoViewMemo: React.FC<IUserInfoView> = (props) => {
  const { t } = useTranslation();
  // const { getAvatarText, getUserShortFullName, getFullName } = useUserNameFormat();
  const { getFullName,  getUserShortFullName, getAvatarText } = useUserNameFormat();

  const getUserNameByPropsType = (type: TUserInfoNameDisplayMode | undefined): string => {
    const userFullName = getUserShortFullName(
      props.user?.lastName ?? "",
      props.user?.firstName ?? "",
      props.user?.middleName ?? ""
    ) ?? t("ui:placeholder.new_employee", {id: props.user?.id ?? 0}) ?? "";

    // const userLastNameAndFirstName = getFullName((props.user?.lastName ?? "").trim(), (props.user?.firstName ?? "").trim());
    const userLastNameAndFirstName = getFullName(
      props.user?.lastName ?? "",
      props.user?.firstName ?? ""
    );

    switch (type) {
      case "none":
        return "";
      case "lastNameAndFirstName":
        return userLastNameAndFirstName ? userLastNameAndFirstName : userFullName;
      case "fullName":
        return userFullName;
    }
    return userFullName;
  };

  const getIcon = useMemo(() => {
    return props.user?.avatar?.image?.url ? <img src={props.user?.avatar?.image?.url} alt="" /> : null
  }, [props.user?.avatar?.image?.url]);

  const renderUserName = useMemo(() => {
    if (props.isLoading == undefined) {
      return t("parse:user_deleted");
    }
    const additional = props.isShowIsFired && props.user?.isFired
      ? (<span>({t("parse:user_deactivated")})</span>)
      : ""
    return (
      <>
        {getUserNameByPropsType(props.nameDisplayMode)}
        {additional}
      </>
    )
  }, [props.user, props.nameDisplayMode, props.isShowIsFired]);

  return (
    <UserTooltip
      tooltipProps={{ open: props.isTooltipDisabled == true || props.isLoading == undefined ? false : undefined }}
      user={props.user}
      zIndex={props.zIndex}
    >
      <div
        className={[
          "user-info__container",
          props.className ?? "",
          props.isTooltipDisabled ? "__tooltip-hidden" : "",
        ].join(" ")}
      >
        {props.isLoading == true ? (
          <Spin style={{ lineHeight: 1 }} size="small" />
        ) : (
          <>
            {props.isStaticImg && props.user?.avatar?.image?.url ? (
              <img
                width={props.avatarSize}
                height={props.avatarSize}
                src={props.user?.avatar?.image?.url ?? ''}
                alt=""
                style={{
                  borderRadius: '50%'
                }}
              />
            ) : (
              <Avatar
                // text={getAvatarText(props.user?.lastName ?? "", props.user?.firstName ?? "")}
                color={props.isLoading == null ? "rgb(39, 31, 48)" : props.user?.color}
                text={getAvatarText(props.user?.lastName ?? "", props.user?.firstName ?? "")}

                src={props.user?.avatar?.image?.url}
                size={props.avatarSize ?? "small"}
              />
            )}
            {props.nameDisplayMode != "none" && (
              <Text
                className="ml-2 user-info__name"
                style={{
                  ...props.nameStyle,
                  textDecoration: props.isLineThrough ? "line-through" : "none",
                }}
                children={renderUserName}
              />
            )}
          </>
        )}
      </div>
    </UserTooltip>
  );
};

export const UserInfoView = React.memo(
  UserInfoViewMemo,
  (x, y) => x?.user?.id == y?.user?.id && x.isLoading == y.isLoading
);
