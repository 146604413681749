import React, { useEffect, useMemo, useState } from "react";
import EmployeeInviteDialogView from "./EmployeeInviteDialogView";
import { AutocompleteModel, CompanyInvite2RoleDto, CompanyInviteDto } from "../../../../api";
import { useNotifier, useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { api } from "../../../../services";
import { useTranslation } from "react-i18next";
import { ExecutorTypes } from "../../../pages/driver/types/DriverTypes";
import { getDifferenceBetweenTwoArrays } from "../../../../helpers/arrayFunctions";
import { AccessTypeEnum } from "../../../../api/models/CompanyInviteDto";
import { useUsersLimits } from "../../../../hooks/useUsersLimits";
import { cu } from "@fullcalendar/core/internal-common";

export interface IEmployeeInviteDialog {
  open: boolean;

  handleClose(): void;
}

function EmployeeInviteDialog(props: IEmployeeInviteDialog) {
  const { authStore, orgchartStore } = useRootStore();
  const currentCompanyId = authStore.getCurrentCompanyId;
  const currentCompany = authStore.getCurrentCompany;

  const notifier = useNotifier();
  const { t } = useTranslation();
  const [isAllInvitesDialogOpened, setIsAllInvitesDialogOpened] = useState(false);
  const [isShowInviteDialogOpened, setIsShowInviteDialogOpened] = useState(false);
  // const [fullAccessUsersCount, setFullAccessUsersCount] = useState<number>(0);

  const fullAccessUsersCount = useMemo(() => {
    return currentCompany?.usersCount?.Full ?? 0
  }, [currentCompany?.usersCount]);

  const restrictedAccessUsersCount = useMemo(() => {
    return currentCompany?.usersCount?.Restricted ?? 0
  }, [currentCompany?.usersCount]);

  const { usersLimitFull, usersLimitRestricted } = useUsersLimits();

  const [allRoles, setAllRoles] = useState<(AutocompleteModel & { orgchartId: number })[]>([]);
  const [isFunctionsSelectOpen, setIsFunctionsSelectOpen] = useState({
    admin: false,
    specialist: false,
    trainee: false,
  });
  const [selectedOrgchartIds, setSelectedOrgchartIds] = useState<number[]>([
    orgchartStore.getCurrentOrgchartId as number,
  ]);
  const [inviteData, setInviteData] = useState<CompanyInviteDto>({
    id: 0,
    companyId: currentCompanyId ?? undefined,
    email: "",
    isReusable: false,
    roles: [],
    accessLevel: AccessTypeEnum.Full,
  });

  // const allRoles = useApi(() =>
  //   api.role.autocomplete({ orgchartId: selectedOrgchartIds?.[0], pageSize: 150, orderBy: "name", order: "asc" })
  // );

  const getRolesByOrgchartId = async (orgchartId: number) => {
    const r = await api.role.autocomplete({ orgchartId: orgchartId, pageSize: -1, orderBy: "name", order: "asc" });
    if (r == null) return;
    setAllRoles(
      [...allRoles, ...(r.items?.map((i) => ({ ...i, orgchartId: orgchartId })) ?? [])].filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      )
    );
  };

  // const getFullAccessUsersCount = async () => {
  //   const r = await api.user.autocomplete({ companyId: authStore.getCurrentCompanyId, accessLevel: AccessTypeEnum.Full, pageSize: 0 });
  //   setFullAccessUsersCount(r?.totalItems ?? 0);
  // };

  const handleAllInvitesDialogClose = () => {
    setIsAllInvitesDialogOpened(false);
  };

  const handleAllInvitesDialogOpen = async () => {
    setIsAllInvitesDialogOpened(true);
  };

  const handleShowInviteDialogCLose = () => {
    setIsShowInviteDialogOpened(false);
    props.handleClose();
  };

  const handleInviteCreate = async () => {
    if (inviteData.isReusable) {
      setInviteData({
        ...inviteData,
        email: undefined,
      });
    }
    const r = await api.companyInvite.create(inviteData);
    notifier.showSuccessError(
      t("notifier:success.good_invite_create"),
      t("notifier:error.bad_invite_create"),
      r != null
    );
    if (r) {
      setInviteData(r);
      setIsShowInviteDialogOpened(true);
      // props.handleClose();
    }
  };

  const handleFunctionsSelectSwitchState = (type: string, value: boolean) => {
    switch (type) {
      case "admin":
        setIsFunctionsSelectOpen({ ...isFunctionsSelectOpen, admin: value });
        break;
      case "specialist":
        setIsFunctionsSelectOpen({ ...isFunctionsSelectOpen, specialist: value });
        break;
      case "trainee":
        setIsFunctionsSelectOpen({ ...isFunctionsSelectOpen, trainee: value });
        break;
    }
  };

  const handleRolesChange = (roleIds: string[], type: ExecutorTypes) => {
    const newData: CompanyInvite2RoleDto[] =
      roleIds?.map((id: string) => ({ roleId: Number(id), user2RoleType: type })) ?? [];
    setInviteData({
      ...inviteData,
      roles: [...(inviteData.roles?.filter((r) => r.user2RoleType != type) ?? []), ...newData],
    });
  };

  const handleOrgchartIdsChange = async (value: number[]) => {
    const oIdsBeforeState = selectedOrgchartIds;
    setSelectedOrgchartIds(value);
    if (value.length > oIdsBeforeState.length) {
      // Selected new orgchart
      const newId = getDifferenceBetweenTwoArrays(value, oIdsBeforeState)?.[0];
      if (newId == null) return;
      await getRolesByOrgchartId(newId);
    } else {
      // Disabled some orgchart
      const newId = getDifferenceBetweenTwoArrays(oIdsBeforeState, value)?.[0];
      if (newId == null) return;
      const newAllRoles = [...allRoles.filter((r) => r.orgchartId != newId)];
      setAllRoles(newAllRoles);
      setInviteData({
        ...inviteData,
        roles: [...(inviteData.roles?.filter((r) => newAllRoles.some((a) => a.id == r.roleId)) ?? [])],
      });
    }
  };

  const isStringEmail = (str: string): boolean => {
    let re = /\S+@\S+\.\S+/;
    return re.test(str);
  };

  useEffect(() => {
    if (typeof usersLimitFull === "number") {
      if (fullAccessUsersCount >= usersLimitFull) {
        setInviteData({ ...inviteData, accessLevel: AccessTypeEnum.Restricted });
      }
    }
  }, [fullAccessUsersCount, usersLimitFull]);

  useEffect(() => {
    if (typeof usersLimitRestricted === "number") {
      if (restrictedAccessUsersCount >= usersLimitRestricted) {
        setInviteData({ ...inviteData, accessLevel: AccessTypeEnum.Full });
      }
    }
  }, [restrictedAccessUsersCount, usersLimitRestricted]);


  useEffect(() => {
    if (props.open) {
      getRolesByOrgchartId(orgchartStore.getCurrentOrgchartId as number);
      // getFullAccessUsersCount();
    } else {
      // setFullAccessUsersCount(0);
      setAllRoles([]);
      setSelectedOrgchartIds([]);
    }
  }, [props.open]);

  return (
    <EmployeeInviteDialogView
      inviteData={inviteData}
      setInviteData={setInviteData}
      handleClose={props.handleClose}
      fullAccessUsersCount={fullAccessUsersCount}
      fullAccessUsersLimit={usersLimitFull}
      restrictedAccessUsersCount={restrictedAccessUsersCount}
      restrictedAccessUsersLimit={usersLimitRestricted}
      open={props.open}
      selectedOrgchartIds={selectedOrgchartIds}
      onOrgchartIdsChange={handleOrgchartIdsChange}
      handleInviteCreate={handleInviteCreate}
      isAllInvitesDialogOpened={isAllInvitesDialogOpened}
      setIsAllInvitesDialogOpened={setIsAllInvitesDialogOpened}
      handleAllInvitesDialogClose={handleAllInvitesDialogClose}
      handleAllInvitesDialogOpen={handleAllInvitesDialogOpen}
      isShowInviteDialogOpened={isShowInviteDialogOpened}
      handleShowInviteDialogCLose={handleShowInviteDialogCLose}
      allRoles={allRoles ?? []}
      handleRolesChange={handleRolesChange}
      handleApplyFunctionsButtonClick={handleFunctionsSelectSwitchState}
      isFunctionsSelectOpen={isFunctionsSelectOpen}
      setIsFunctionsSelectOpen={handleFunctionsSelectSwitchState}
      isStringEmail={isStringEmail}
    />
  );
}

export default observer(EmployeeInviteDialog);
