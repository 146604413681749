import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownMenu } from "../../../../../service/dropdownMenu/dropdownMenu";
import { AIAssistantButtonProps } from "./AIAssistantButton.interface";
import { AIAssistantType } from "../../../../../../types/AIAssistantType.interface";

export const AIAssistantButton = (props: AIAssistantButtonProps) => {
  const { t } = useTranslation();

  const items = [
    {
      id: 1,
      text: t("ui:regulation_actions.ai_assistant.check_mission"),
      action: () => {
        props.onChangeAssistantType(AIAssistantType.MISSION);
      }
    },
    {
      id: 2,
      text: t("ui:regulation_actions.ai_assistant.check_vision"),
      action: () => {
        props.onChangeAssistantType(AIAssistantType.VISION);
      }
    },
  ];

  return (
    <DropdownMenu
      items={items}
      iconType="chevron"
      trigger={["click"]}
      buttonProps={{
        variant: "outlined",
        text: t("ui:regulation_actions.ai_assistant.button"),
      }}
    />
  )
}
