import React, { KeyboardEvent, useState } from "react";
import { Button, IconButton, Input, TextArea } from "../../../../../../uiKit";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { AIAssistantFooterProps } from "./AIAssistantFooter.interface";
import "./AIAssistantFooter.scss";
import clsx from "clsx";

export const AIAssistantFooter = (props: AIAssistantFooterProps) => {
  const [request, setRequest] = useState<string>("");

  const handleSendReview = () => {
    props.onReview(request);
    setRequest("");
  }

  const handleClickSendReview = () => {
    props.onReview("");
  }


  return (
    <div className="ai-assistant-footer">
      <div className="ai-assistant-footer__input">
        <TextArea
          autoSize={{ maxRows: 7 }}
          onChange={(e) => setRequest(e.target.value)}
          value={request}
        />
        <IconButton
          className={clsx("ai-assistant-footer__btn", {
            "ai-assistant-footer__btn_disabled": !request.length,
          })}
          size={"small"}
          disabled={props.isLoading}
          icon={<FiSend size={20} color={!request.length ? "transparent" : "inherit"} />}
          onClick={handleSendReview}
        />
      </div>
      <div className="ai-assistant-footer__actions">
        <Button
          disabled={props.isLoading}
          onClick={handleClickSendReview}
          variant="outlined"
          style={{
            height: 36,
            borderRadius: 24,
          }}
        >
          { props.buttonText }
        </Button>
      </div>
    </div>
  )
}
