import React, { useCallback, useEffect, useState } from "react";
import { EmployeeIndividualRegulationBlockView } from "./EmployeeIndividualRegulationBlockView";
import { api } from "../../../../../services";
import useDebounce from "../../../../../hooks/useDebounce";
import { usePagingWithController } from "../../../../../hooks/usePaging";
import { useNotifier } from "../../../../../hooks";
import { useTranslation } from "react-i18next";

interface IEmployeeIndividualRegulationBlockProps {
  userId?: number;
}

export const EmployeeIndividualRegulationBlock = (props: IEmployeeIndividualRegulationBlockProps) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  const debouncedSearchString = useDebounce(searchString, 400);

  const regulationData = usePagingWithController(
    api.regulation,
    {
      name: searchString,
      withContextForUserId: props.userId,
      withStudyStatusForUserId: props.userId,
    },
    {
      pageSize: -1,
    }
  );

  const sectionData = usePagingWithController(
    api.section,
    {
      name: searchString,
      withContextForUserId: props.userId,
      withStudyStatusForUserId: props.userId,
    },
    {
      pageSize: -1,
    }
  );

  const handleGoToRestudy = async (itemId: number, type: "regulation" | "section") => {
    if (props.userId == null) return;
    setIsLoading(true);
    let r = null;
    if (type == "section") {
      r = await api.section.unStudy(itemId, props.userId);
    } else {
      r = await api.regulation.unStudy(itemId, props.userId);
    }
    setIsLoading(false);
    return r == null
      ? notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" })
      : reloadAllData(true);
  };

  const handleGoToStudy = useCallback(() => async (itemId: number) => {
    setIsLoading(true);
    const r = await api.regulation.study(itemId, props.userId);
    setIsLoading(false);
    return r == null
      ? notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" })
      : reloadAllData(true);
  }, []);

  const reloadAllData = async (withoutStateReset?: boolean) => {
    if (!withoutStateReset) {
      regulationData.reset();
      sectionData.reset();
    }
    regulationData.restart(withoutStateReset);
    sectionData.restart(withoutStateReset);
  };

  useEffect(() => {
    reloadAllData();
  }, [debouncedSearchString]);

  return (
    <EmployeeIndividualRegulationBlockView
      searchString={searchString}
      userId={props.userId}
      isLoading={{
        regulationStudied: regulationData.info.isLoading || isLoading,
        regulationNotStudied: regulationData.info.isLoading || isLoading,
        sectionStudied: sectionData.info.isLoading || isLoading,
        sectionNotStudied: sectionData.info.isLoading || isLoading,
      }}
      isDone={{
        regulationStudied: regulationData.info.isDone,
        regulationNotStudied: regulationData.info.isDone,
        sectionStudied: sectionData.info.isDone,
        sectionNotStudied: sectionData.info.isDone,
      }}
      items={{
        regulationStudied: regulationData.items.filter((r) => r.isStudiedBySpecifiedUser),
        regulationNotStudied: regulationData.items.filter((r) => !r.isStudiedBySpecifiedUser),
        sectionStudied: sectionData.items.filter((s) =>
          (s.stats?.studiedPercent ?? 0) === 100
        ),
        sectionNotStudied: sectionData.items.filter((s) =>
          (s.stats?.studiedPercent ?? 0) < 100
        ),
      }}
      onGoToRestudy={handleGoToRestudy}
      onGoToStudy={handleGoToStudy}
      setSearchString={setSearchString}
    />
  );
};
