import React, { memo, useMemo, useState } from "react";
import { MetricDataContent } from "../../modules/pages/dashboards/components/MetricDataContent/MetricDataContent";
import { IPrintMetricTable } from "./PrintMetricTable.interface";
import { ITableDataEl } from "../../modules/dialogs/metricDataDialog/MetricDataDialog";
import { Text } from "../../uiKit"
import { useTranslation } from "react-i18next";
import "./PrintMetricTable.scss";

export const PrintMetricTable = memo((props: IPrintMetricTable) => {
  const { t } = useTranslation();
  const changedData = () => {
    console.log("changedData");
  }


  const handleDataChange = (
    record: ITableDataEl,
    value: string | number | null | undefined,
    type: "quota" | "value" | "reset"
  ) => {
    console.log('record', record);
    console.log("value", value);
    console.log("type", type);
  };

  const [loadingState, setLoadingState] = useState<{
    isLoading: boolean;
    isDone: boolean;
  }>({
    isLoading: false,
    isDone: true,
  });

  const handleLoadMore = () => {
    console.log("handleLoadMore");
  }

  const metricTitle = useMemo(() => {
    return props.data[props.index]?.title || props.metric.name;
  }, [])

  return (
    <div className="print-metric-table pt-3">
      <div className="print-metric-table__title mb-3">
        <Text
          style={{
            fontSize: 14,
            lineHeight: 1.4,
            fontWeight: 600,
            color: "var(--color-radio-border)",
          }}
        >
          {t("common:menu.metric_settings_dropdown.data")}
        </Text>
      </div>
      <div className="print-metric-table__title mb-6">
        <Text
          style={{
            fontSize: 14,
            lineHeight: 1.4,
            fontWeight: 600,
          }}
        >
          {metricTitle}
        </Text>
      </div>
      <MetricDataContent
        metric={props.metric}
        changedData={[]}
        currentMetricSource2UserId={props.metricSource2UserId!}
        onDataChange={handleDataChange}
        loadingState={loadingState}
        data={props.data}
        onLoadMore={handleLoadMore}
        isPrint
      />
    </div>
  )
});
