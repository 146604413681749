import React, { memo } from "react";
import { UserInfo } from "../../../../../../../elements/userInfo/UserInfo";
import { IIssueActivityUser } from "./IssueActivityUser.interface";
import "./IssueActivityUser.scss";

export const IssueActivityUser = memo((props: IIssueActivityUser) => {
  return (
    <div
      className={"issue-activity-user"}
      style={{
        background: props.background,
      }}
    >
      <UserInfo
        avatarSize={17}
        user={props.user }
        isLineThrough={props.isLineThrough}
      />
    </div>

  )
});
