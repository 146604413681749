import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../../../../services";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { UpdateNoteCreateHeader } from "../../headers/updateNoteCreateHeader/UpdateNoteCreateHeader";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { UpdateNoteCreateContent } from "../../contents/UpdateNoteCreateContent/UpdateNoteCreateContent";
import { UpdateNoteContentDto, UpdateNoteDto } from "../../../../../../api";
import { IInitialUpdateNotes, initialNotes } from "../../misc/constants";
import { useNavigate } from "react-router-dom";
import { NotificationType } from "../../../../../../hooks/useNotifier";
import { validationNotesForSave } from "../../misc/validation";

export const UpdateNoteCreate = () => {
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const navigate = useNavigate();
  const editorRef = useRef<{ resetEditor: () => void }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updateNotes, setUpdateNotes] = useState<IInitialUpdateNotes>(initialNotes);
  const [isHideUpdateModal, setIsHideUpdateModal] = useState<boolean>(false);

  const [currentLocale, setCurrentLocale] = useState<LocalesKeys>(
    (appStore.getAppLocale ?? LocalesKeys.en) as LocalesKeys
  );

  // @ts-ignore
  const currentNote: UpdateNoteContentDto = updateNotes[currentLocale];

  const handleChangeNoteContent = (content: string) =>
    setUpdateNotes((prev) => {
      // @ts-ignore
      prev[currentLocale].content = content;
      return prev;
    });

  const handleChangeNoteName = (name: string) =>
    setUpdateNotes((prev) => {
      // @ts-ignore
      prev[currentLocale].name = name;
      return Object.assign({}, prev);
    });

  const handleChangeCurrentLocale = (newLocale: LocalesKeys) => setCurrentLocale(newLocale);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const showNotification = (message: string, theme: NotificationType = "error") => notifier.show({ message, theme });

  const notesValidation = validationNotesForSave({ t, notifier: showNotification }, );

  const handleCreateNote = async () => {
    setIsLoading(true);
    const resultValidation = notesValidation(Object.values(updateNotes), currentLocale);
    if (!resultValidation) return setIsLoading(false);

    const updateNote: UpdateNoteDto = {
      isPublished: false,
      showUpdateModal: !isHideUpdateModal,
      contents: Object.values(updateNotes),
    };
    const request = await api.updateNote.create(updateNote);
    setIsLoading(true);
    if (request == null) return handleApiError();
    notifier.show({ message: t("notifier:success.release_create"), theme: "success" });
    navigate(`/update-note/${request.id}`);
    setUpdateNotes(initialNotes);
  };

  useEffect(() => {
    editorRef.current?.resetEditor?.();
  }, [currentLocale]);

  useEffect(() => {
    return () => {
      setUpdateNotes(initialNotes);
    };
  }, []);

  return (
    <>
      <PageHeader>
        <UpdateNoteCreateHeader
          currentLocale={currentLocale}
          onChangeLocale={handleChangeCurrentLocale}
          isHideUpdateModal={isHideUpdateModal}
          onChangeIsHideUpdateModal={setIsHideUpdateModal}
        />
      </PageHeader>
      <PageContent contentPosition={{ maxWidth: "1000px" }}>
        <UpdateNoteCreateContent
          ref={editorRef}
          noteName={currentNote.name!}
          onChangeNoteName={handleChangeNoteName}
          onCreate={handleCreateNote}
          isCreateLoading={isLoading}
          onChangeNoteContent={handleChangeNoteContent}
          noteContent={currentNote.content!}
        />
      </PageContent>
    </>
  );
};
