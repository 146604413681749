import React from "react";
import { useRootStore } from "../../../../../hooks";
import ruContent from "../../../../../utils/guides-lexical/employee/ru.json";
import enContent from "../../../../../utils/guides-lexical/employee/en.json";
import ukContent from "../../../../../utils/guides-lexical/employee/uk.json";
import zhContent from "../../../../../utils/guides-lexical/employee/zh.json";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";

export const getContent = (localeKey: string | null): any | null => {
  if (localeKey?.includes("uz")) {
    return ruContent;
  }

  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ukContent;
    case "zh":
      return zhContent;
    default:
      return enContent;
  }
};

export const InviteEmployeeGuide = () => {
  const { hintStore } = useRootStore();

  const anchorId = hintStore.getAnchorId;

  return (
    // <TextEditorOld
    //   readOnly={true}
    //   anchorKey={anchorId as string}
    //   id="guide-dasboard"
    //   onChange={() => {}}
    //   value={getContent(appStore.getAppLocale)}
    // />
    <TextEditorLexical
      readOnly
      anchorKey={anchorId as string}
      value={getContent(hintStore.getLang)}
      withoutBackground
    />
  );
};
