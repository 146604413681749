import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./IssueSwitchStatus.scss";
import { IssueSwitchStatusView } from "./IssueSwitchStatusView";
import { IIssueSwitchStatus } from "./IssueSwitchStatus.interface";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore } from "../../../hooks";
import {
  BoardStatusActionRuleDto,
  BoardStatusTransitionActionDto,
  BoardStatusTransitionActionRuleDto,
} from "../../../api";
import { api } from "../../../services";
import IssueRequiredActionsDialog from "../../modules/dialogs/issueRequiredActionsDialog/IssueRequiredActionsDialog";
import { observer } from "mobx-react-lite";
import { custonFieldsKeys, IssueActionConsts } from "../../modules/forms/types/consts";
import { ActionBaseRuleKey } from "../../modules/pages/communications/constants/keys";
import { toJS } from "mobx";
import { BroadcastChannel } from "broadcast-channel";

const IssueSwitchStatusMemo = (props: IIssueSwitchStatus) => {
  const { issueInitDataStore, boardStore, authStore } = useRootStore();

  const { t } = useTranslation();
  const notifier = useNotifier();
  const issueChannel = new BroadcastChannel("issue");

  const [nextStatus, setNextStatus] = useState<BoardStatusTransitionActionDto | null>(null);
  const [currentAction, setCurrentAction] = useState<IssueActionConsts | null>(null);
  const [isOpenRequireActionsDialog, setIsOpenRequireActionsDialog] = useState<boolean>(false);
  const [isOpenIssueRejectDialog, setIsOpenIssueRejectDialog] = useState<boolean>(false);
  const [isOpenIssueSendAgainDialog, setIsOpenIssueSendAgainDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApiError = useCallback(
    () =>
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      }),
    [notifier, t]
  );

  const currentStatus = useMemo(() => props.issueData?.status, [props.issueData?.status]);

  const currentStatusFromBoard = useMemo(() => {
    return boardStore.getBoard?.statuses?.find((bS) => bS.id === currentStatus?.id);
  }, [boardStore.getBoard?.statuses, currentStatus?.id]);

  const onCreateIssueHistoryAction = useCallback(
    async (actionKey: string) => {
      setIsLoading(true);
      const r = await api.issueHistory.create({
        issueId: props.issueData!.id,
        actions: [{ key: actionKey }],
      });
      if (r == null) notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      issueInitDataStore.getOnStatusChange && issueInitDataStore.getOnStatusChange();
      await issueChannel.postMessage({
        issueId: props.issueData!.id,
        type: "issueStatus",
      });
    },
    [issueInitDataStore, notifier, props, t, issueChannel]
  );

  const handleCheckActionRule = useCallback(
    (rule: BoardStatusActionRuleDto): boolean => {
      switch (rule.baseRuleKey) {
        case ActionBaseRuleKey.AllowToParticipator:
          return !!props.issueData?.participants?.find((p) => p.userId === authStore.getInitialInfo?.identity?.id);
        case ActionBaseRuleKey.AllowToExecutor:
          return props.issueData?.executorUserId === authStore.getInitialInfo?.identity?.id;
        case ActionBaseRuleKey.AllowToCreator:
          return props.issueData?.createdByUserId === authStore.getInitialInfo?.identity?.id;
        case ActionBaseRuleKey.AllowToInitiator:
          return props.issueData?.initiatorUserId === authStore.getInitialInfo?.identity?.id;
        case ActionBaseRuleKey.AllowToAny:
          return true;
        case ActionBaseRuleKey.IfProofIsSet:
          return props.issueData?.proof != null;
        case ActionBaseRuleKey.IfStrictDeadlineIsTrue:
          return !!props.issueData?.fields?.find((f) => f.key === custonFieldsKeys.strictDeadline)?.valueBool;
        case ActionBaseRuleKey.IfStrictDeadlineIsFalse:
          return !props.issueData?.fields?.find((f) => f.key === custonFieldsKeys.strictDeadline)?.valueBool;
        case ActionBaseRuleKey.IfIsManagerApprovalRequiredIsTrue:
          return !!props.issueData?.fields?.find((f) => f.key === custonFieldsKeys.managerApproval)?.valueBool;
        case ActionBaseRuleKey.IfIsManagerApprovalRequiredIsFalse:
          return !props.issueData?.fields?.find((f) => f.key === custonFieldsKeys.managerApproval)?.valueBool;
        default:
          throw new Error("New base rule key");
      }
    },
    [
      authStore.getInitialInfo?.identity?.id,
      props.issueData?.createdByUserId,
      props.issueData?.executorUserId,
      props.issueData?.fields,
      props.issueData?.initiatorUserId,
      props.issueData?.participants,
      props.issueData?.proof,
    ]
  );

  const handleCheckActionRules = useCallback(
    (rules: BoardStatusTransitionActionRuleDto[]): boolean => {
      if (rules == null || !rules.length) {
        return true;
      }
      let result = false;
      rules.forEach((rule) => {
        if (handleCheckActionRule(rule) || rule.isOr) {
          result = true;
        } else {
          return false;
        }
      });
      return result;
    },
    [handleCheckActionRule]
  );

  const handleGetCurrentCallback = useCallback(
    (value: IssueActionConsts) => {
      const currentDestinationStatus = props.actions?.find((a) => a.baseAction?.key === value)?.destinationStatus;
      const currentNextStatus = toJS(currentStatusFromBoard)?.transitionsTo?.find(
        (tT) => tT.boardStatusTo?.id === currentDestinationStatus?.id
      );
      const currentAction = currentNextStatus?.requiredActions?.filter((rA) => handleCheckActionRules(rA.rules ?? []));
      // if (currentNextStatus?.requiredActions && currentNextStatus.requiredActions?.length > 0) {
      if (currentAction?.length) {
        if (currentAction.length > 1) {
          throw new Error("current action length > 1");
        }
        setIsLoading(true);
        setNextStatus({
          ...currentAction?.[0],
          actionKey: value,
        });
        setCurrentAction(value);
        setIsOpenRequireActionsDialog(true);
      } else {
        return onCreateIssueHistoryAction(value);
      }
    },
    [currentStatusFromBoard, handleCheckActionRules, props, onCreateIssueHistoryAction]
  );
  const handleCloseDialog = useCallback(() => {
    setIsOpenRequireActionsDialog(false);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [props.issueData]);

  // useEffect(() => {
  //   if (nextStatuses != null) setIsLoading(false);
  // }, [nextStatuses]);

  useEffect(() => {
    !isOpenRequireActionsDialog && handleCloseDialog();
  }, [handleCloseDialog, isOpenRequireActionsDialog]);

  return (
    <>
      {isOpenRequireActionsDialog && (
        <IssueRequiredActionsDialog
          open={isOpenRequireActionsDialog}
          boardData={nextStatus}
          currentDestinationActionKey={currentAction}
          issueData={props.issueData!}
          onClose={handleCloseDialog}
          onRefreshData={props.onRefreshData}
        />
      )}
      <IssueSwitchStatusView
        currentStatus={currentStatus}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        onChange={handleGetCurrentCallback}
        // nextStatuses={nextStatuses}
        isDisabled={props.isDisabled}
        actions={props.actions}
        placeholder={props.placeholder}
      />
    </>
  );
};

export const IssueSwitchStatus = React.memo(
  observer(IssueSwitchStatusMemo),
  (prev, next) =>
    prev.issueData?.status?.id == next.issueData?.status?.id &&
    prev.issueData?.calculated?.nextStatuses === next.issueData?.calculated?.nextStatuses &&
    prev.issueData?.dateWorkStart === next.issueData?.dateWorkStart &&
    prev.issueData?.dateDeadline === next.issueData?.dateDeadline &&
    prev.issueData?.timePlan === next.issueData?.timePlan &&
    prev.issueData?.timeFact === next.issueData?.timeFact &&
    prev.issueData?.timeFactForApproval === next.issueData?.timeFactForApproval
);
