export const carrotQuestLocaleEn = {
  thanks: "Thank you",
  dialogs_history: "Chat history",
  powered_by_text_before_logo: "Powered&nbsp;by",
  powered_by_text_after_logo: "",
  enter_message: "Enter a message…",
  enter_email: "Enter email",
  enter_phone: "Enter phone number",
  leave_email: "You can leave your email and continue the conversation via mail:",
  page_title_new_message: "New message",
  privacy_policy: "Privacy Policy",
  errorFileType:
    "Invalid file format. You can upload files: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "File too large<br/>Maximum file size is 10 MB.",
  errorManyRequests: "Too many files<br/>You are uploading files too frequently. Please wait a moment.",
  errorReplyTooBig: "Message cannot be longer than 20,000 characters.",
  errorUserBan: "You are banned<br/>You can no longer send new messages in the chat.",
  error: "Something went wrong.<br/>An error occurred while uploading. Please try again.",
  close: "Close",
  button_start_message: "Write",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Start or continue a conversation on {{networkName}}",
  social_network_page_desc_vk: [
    "Open VK using the button below to go to the chat",
    "Send us your question",
  ],
  social_network_page_desc_viber: [
    "Open Viber using the button below to go to the chat",
    "Send us your question",
  ],
  social_network_page_desc_telegram: [
    "Open Telegram using the button below to go to the chat",
    "Press the 'Start' button in Telegram",
    "Send us your question",
  ],
  social_network_page_desc_facebook: [
    "Open Facebook using the button below to go to the chat",
    "Press the 'Start' button in Facebook",
    "Send us your question",
  ],
  social_network_page_desc_instagram: [
    "Open Instagram using the button below to go to the required page",
    "Click the 'Send Message' button on Instagram",
    "Ask us your question",
  ],
  social_network_page_desc_whatsapp: [
    "Open WhatsApp using the button below to go to the chat",
    "Send the pre-filled message",
    "Ask us your question",
  ],
  social_network_page_open_button: "Open {{networkName}}",
  social_network_qr_code_title: "Scan this QR code to open {{networkName}} from another device",
  bumperText:
    'We use cookies to improve site performance, analyze traffic, and personalize content. By using this site or clicking "I agree," you accept our privacy and cookie policy as described in the <a href="{{privacy-policy-link}}" target="_blank">Privacy Policy</a>.',
  bumperButton: "I agree",
  confirmSubscriptionWithCheckbox:
    'I agree with the <a href="{{subscription-policy}}" target="_blank">data processing terms</a>',
  confirmSubscriptionWithoutCheckbox:
    'By clicking the button, you agree with the <a href="{{subscription-policy}}" target="_blank">data processing terms</a>',
  appeal: "You",
  vote_comment: "What happened?",
  vote_text: "Please rate the operator's service",
  vote_thanks: "Thanks for your feedback!",
  cart_button: "More details",
  cart_no_name: "No name",
  cart_zero: "Cart data could not be loaded",
  article_link: "Read article",
  to_knowledge_base: "Find an answer in the knowledge base",
  typing_status: "Typing…",
  notification_enter_message: "Enter a message…",
  notification_message_sending: "Sending message…",
  notification_message_sent: "Message sent",
  notification_file_sending: "Uploading file…",
  notification_file_sent: "File sent",
  notification_typing_status: "is typing",
  history_zero_data_title: "We haven't talked yet",
  history_zero_data_desc: "Your chat history will appear here",
  history_group_collapse: "Collapse",
  history_section_actual: "Recent",
  history_section_archive: "History",
  back_to_chat: "Back to chat",
  integration_zoom_button_text: "Join",
  integration_zoom_tip: "☝️ Tip: You can join the video conference directly from your browser",
  dateMonth: {
    "0": "January",
    "1": "February",
    "2": "March",
    "3": "April",
    "4": "May",
    "5": "June",
    "6": "July",
    "7": "August",
    "8": "September",
    "9": "October",
    "10": "November",
    "11": "December",
  },
  fileSize: {
    "0": "B",
    "1": "KB",
    "2": "MB",
    "3": "GB",
  },
  at: "at",
  edited: "Edited",
  removed: "Removed",
  removedMessage: "Message deleted",
  bot_answer_placeholder: "Enter your answer",
  bot_buttons_placeholder: "Choose an option",
  connection_problem_toast: "<b>No connection to the chat.</b> We will try to reconnect automatically.",
  input_error_email: "Enter a valid email",
  input_error_phone: "Enter a valid phone number",
  popup_close_all_button: "Hide all",
  offline: "offline",
  online: "online",
  whatsapp_auth_text:
    "Send this message to us to transfer your web chat ID: {{userId}} here to WhatsApp. If you edit or delete this message, you will create a new chat and contact another operator.",
  popup_desc: "Popup",
  image_desc: "Image",
  emoji_search_placeholder: "Search",
  emoji_zero_data: "Nothing found",
  emoji_category_people: "Smileys & People",
  emoji_category_nature: "Animals & Nature",
  emoji_category_food: "Food & Drink",
  emoji_category_activity: "Activity",
  emoji_category_places: "Travel & Places",
  emoji_category_objects: "Objects",
  emoji_category_symbols: "Symbols",
  emoji_category_flags: "Flags",
};

export const carrotAdditionalSettingsEn = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "We are here and ready to help",
  messenger_offline_message: "An operator will connect just for you.",
  messenger_welcome_message:
    "Good day! You have contacted the Business Booster Platform support team. Please describe your issue, and we will respond as soon as possible. Thank you for reaching out!",
};
