import React from "react";
import { Button, Checkbox, Dialog, DialogActions, Icon, Text } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import "./MeetingBusinessEngineer.scss";
import { Calendar, MessageCircle } from "lucide-react";
import { MeetingBusinessEngineerProps } from "./MeetingBusinessEngineer.interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export const MeetingBusinessEngineer = (props: MeetingBusinessEngineerProps) => {
  const { t } = useTranslation();

  const handleClickBookMeeting = () => {
    const link = props.isPaid
      ? "https://calendly.com/d-dervoed-platform-bbooster/30min"
      : "https://calendly.com/a-reznikov-platform-bbooster/30min";
    window.open(link, "_blank", "noreferrer");
  }
  const handleClickSupport = () => {
    window.open("https://t.me/BBPlatformSupportBot?start=1928227999354718742_bfaa8a30a146fd905c38318f86a5f4c979a72b0c", "_blank", "noreferrer");
  }

  const handleChangeDontShowAgain = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    props.onChange(checked);
  }

  return (
    <Dialog
      width={919}
      zIndex={10000}
      title={t("ui:meeting_business_engineer.title")}
      open={true}
      onClose={props.onClose}
      isNewDesign
      className={"meeting-business-engineer-dialog"}
    >
      <div className="meeting-business-engineer-dialog-content">
        <p className="mb-8">
          <Text className="meeting-business-engineer-dialog-text">
            <span dangerouslySetInnerHTML={{ __html: t("ui:meeting_business_engineer.text1") }} />
          </Text>
        </p>
        <p className="mb-8">
          <Text className="meeting-business-engineer-dialog-text">
            <span dangerouslySetInnerHTML={{ __html: t("ui:meeting_business_engineer.text2") }} />
          </Text>
        </p>
        <p className="mb-8">
          <Text className="meeting-business-engineer-dialog-text">
            <span dangerouslySetInnerHTML={{ __html: t("ui:meeting_business_engineer.text3") }} />
          </Text>
        </p>
      </div>
      <DialogActions>
        <div className={"meeting-business-engineer-dialog-footer"}>
          <div className="meeting-business-engineer-dialog-footer__buttons">
            <Button
              onClick={handleClickBookMeeting}
              size={"large"}
            >
              <Icon
                className={"meeting-business-engineer-dialog-footer__icon"}
                component={() => <Calendar size={16} />}
              />
              {t("ui:meeting_business_engineer.button1")}
            </Button>
            <Button
              onClick={handleClickSupport}
              size={"large"}
              type={"default"}
            >
              <Icon component={() => <MessageCircle size={16} />}/>

              {t("ui:meeting_business_engineer.button2")}
            </Button>
            <Checkbox
              isNewDesign
              onChange={handleChangeDontShowAgain}
            >
              <Text type={"secondary"}>
                {t("ui:checkbox.dont_show_again")}
              </Text>
            </Checkbox>
          </div>
        </div>
      </DialogActions>
    </Dialog>
)
}
