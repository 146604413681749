import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { TextEditor } from "../../../textEditor/TextEditor";
import { ToolbarType } from "../../../textEditor/types/types";
import { Checkbox, Text, TimeSpanPicker } from "../../../../uiKit";
import React, { useEffect } from "react";
import { IssueDto, StaticFileDto } from "../../../../../api";
import FileUploader from "../../../../service/fileUploader/FileUploader";
import { actionsKeysConst } from "../../../forms/types/consts";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { TimePickerMask } from "../../../../uiKit/dataEntry/pickers/timePickerMask/TimePickerMask";
import { TIME_PLAN_FOR_APPROVAL_QUICK_VALUES, TIME_PLAN_QUICK_VALUES } from "../../../../../constants/QuickValues";

interface IIssueRequiredActionsDialogTabAttachComment {
  comment: string;
  onInput: (value: string) => void;
  isChecked: boolean;
  onIsCheckedChange: (isChecked: boolean, key: actionsKeysConst) => void;
  editorRef: React.MutableRefObject<{ isEmptyEditor: () => boolean; resetEditor: () => void } | undefined>;
  showCheckboxToSaveLastComment: boolean;
  attachments?: StaticFileDto[];
  // isShowErrorValidateMessage: boolean;
  currentTimeFact: string;
  issueData: IssueDto;
  onCurrentTimeFactChange: (value: string) => void;
  isShowErrorValidateMessage: { showCommonError: boolean; showTimeFactError: boolean; showDeadlineError: boolean },
  isApprove?: boolean;

}

export function IssueRequiredActionsDialogTabAttachComment(props: IIssueRequiredActionsDialogTabAttachComment) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.currentTimeFact.length && props.issueData?.timePlanForApproval) {
      props.onCurrentTimeFactChange(props.issueData?.timePlanForApproval);
    }
  }, []);

  return (
    <div
      className="d-stack-column spacing-3"
      style={{ overflow: "hidden" }}
    >
      {props.isApprove && (
        <div className="d-stack-row justify-space-between">
          <div style={{ width: "48%" }}>

            <SubheaderText text={t("ui:planning_table.plan")} />
            <TimePickerMask
              size="large"
              disabled
              className="full-width"
              value={props.issueData?.timePlanForApproval}
            />
          </div>
          <div style={{ width: "48%" }}>
            <SubheaderText text={t("ui:planning_table.fact")} />
            <TimePickerMask
              size="large"
              className="full-width"
              value={props.currentTimeFact.length ? props.currentTimeFact : null}
              onChange={(value) => props.onCurrentTimeFactChange(value ?? "")}
              quickValues={TIME_PLAN_QUICK_VALUES}
            />
            {props.isShowErrorValidateMessage.showTimeFactError && (
              <Text
                size="12px"
                style={{ color: "var(--color-txt-error)" }}
                children={t("validation:issue_complete_dialog_validate.time_error")}
              />
            )}
          </div>
        </div>
      )}

      <Form.Item
        className="my-0"
        // extra={
        //   t("validation:min_char_number", { number: 1 }) +
        //   ". " +
        //   t("common:misc.entered_characters_number", { number: getLengthContent(props.comment) })
        // }
      >
        {/*<SubheaderText text={t("ui:subheader.message")} />*/}
        <TextEditor
          id="ds-attach-comment-editor"
          ref={props.editorRef}
          placeholder={t("ui:placeholder.write_reason")}
          // variant={props.isShowErrorValidateMessage ? "error" : undefined}
          value={props.comment}
          disabled={props.isChecked}
          initialValue={props.comment}
          onChange={props.onInput}
          toolbarType={ToolbarType.Hidden}
        />
      </Form.Item>
      <FileUploader hideInfo hideButton smallSize readonly value={props.attachments} />
      {props.showCheckboxToSaveLastComment && (
        <div className="mt-2">
          <Checkbox
            checked={props.isChecked}
            onChange={(e) => props.onIsCheckedChange(e.target.checked, actionsKeysConst.attachComment)}
          >
            {t("ui:label.last_comment_is_reason")}
          </Checkbox>
        </div>
      )}
    </div>
  );
}
