export const carrotQuestLocaleUzCyrl = {
  thanks: "Раҳмат",
  dialogs_history: "Мулоқот тарихи",
  powered_by_text_before_logo: "Ишлайди&nbsp;",
  powered_by_text_after_logo: "",
  enter_message: "Хабар ёзинг…",
  enter_email: "E-mail киритинг",
  enter_phone: "Телефон рақамингизни киритинг",
  leave_email: "Сиз e-mail қолдириб, биз билан почта орқали мулоқотни давом эттиришингиз мумкин:",
  page_title_new_message: "Янги хабар",
  privacy_policy: "Маълумотлар қайта ишлаш шартлари",
  errorFileType:
    "Юклаш учун файл формати нотўғри. Сиз қуйидаги файлларни юклашингиз мумкин: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Файл ҳаддан ташқари катта<br/>Максимал файл ҳажми — 10 MB.",
  errorManyRequests: "Жуда кўп файллар юкланди<br/>Сиз файлларни жуда тез юкламоқдасиз. Бироз кутинг",
  errorReplyTooBig: "Хабар 20 000 белгидан ошмаслиги керак",
  errorUserBan: "Сиз блокландингиз<br/>Чатда янги хабар ёзиш имконияти йўқ",
  error: "Нимадир нотўғри кетди.<br/>Юклашда хатолик юз берди. Қайта уриниб кўринг.",
  close: "Ёпиш",
  button_start_message: "Ёзиш",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "{{networkName}} орқали мулоқотни бошлаш ёки давом эттириш",
  social_network_page_desc_vk: [
    "Қуйидаги тугмани босиб, VK орқали мулоқотни бошланг",
    "Бизга саволингизни ёзинг",
  ],
  social_network_page_desc_viber: [
    "Қуйидаги тугмани босиб, Viber'ни очинг ва керакли мулоқотга ўтинг",
    "Бизга саволингизни ёзинг",
  ],
  social_network_page_desc_telegram: [
    "Қуйидаги тугмани босиб, Telegram'ни очинг ва керакли мулоқотга ўтинг",
    "Telegram'да «Start» тугмасини босинг",
    "Бизга саволингизни ёзинг",
  ],
  social_network_page_desc_facebook: [
    "Қуйидаги тугмани босиб, Facebook'ни очинг ва керакли мулоқотга ўтинг",
    "Facebook'да «Бошлаш» тугмасини босинг",
    "Бизга саволингизни ёзинг",
  ],
  social_network_page_desc_instagram: [
    "Қуйидаги тугмани босиб, Instagram'ни очинг ва керакли саҳифага ўтинг",
    "Instagram'да «Хабар ёзиш» тугмасини босинг",
    "Бизга саволингизни ёзинг",
  ],
  social_network_page_desc_whatsapp: [
    "Қуйидаги тугмани босиб, WhatsApp'ни очинг ва керакли мулоқотга ўтинг",
    "Тайёрланган хабарни юборинг",
    "Бизга саволингизни ёзинг",
  ],
  social_network_page_open_button: "{{networkName}}'ни очиш",
  social_network_qr_code_title: "Ушбу QR-кодни сканер қилинг ва {{networkName}}'ни бошқа қурилмада очинг",
  bumperText:
    "Биз сайт фаолиятини яхшилаш, трафикни таҳлил қилиш ва персонализация учун cookies'дан фойдаланамиз. Сайтдан фойдаланиш ёки «Мен розиман» тугмасини босиш орқали сиз шахсий маълумотлар ва cookies сиёсатига мувофиқ розилик билдирган бўласиз. Сиёсатимизни <a href=\"{{privacy-policy-link}}\", target=\"_blank\">бу ерда</a> ўқишингиз мумкин",
bumperButton: "Мен розиман",
  confirmSubscriptionWithCheckbox:
'<a href="{{subscription-policy}}" target="_blank">Маълумотларни қайта ишлаш шартлари</a> билан розиман',
  confirmSubscriptionWithoutCheckbox:
'Тугмани босиш орқали <a href="{{subscription-policy}}" target="_blank">маълумотларни қайта ишлаш шартлари</a> билан розилик билдирган бўласиз',
  appeal: "Сиз",
  vote_comment: "Нима бўлди?",
  vote_text: "Илтимос, оператор ишини баҳоланг",
  vote_thanks: "Баҳолаганингиз учун раҳмат!",
  cart_button: "Батафсил",
  cart_no_name: "Номсиз",
  cart_zero: "Саватдаги товарлар маълумотлари юкланмади",
  article_link: "Мақолани ўқиш",
  to_knowledge_base: "Жавобни билимлар базасида топиш",
  typing_status: "Ёзмоқда…",
  notification_enter_message: "Хабар ёзинг…",
  notification_message_sending: "Хабар юборилмоқда…",
  notification_message_sent: "Хабар юборилди",
  notification_file_sending: "Файл юкланмоқда…",
  notification_file_sent: "Файл юборилди",
  notification_typing_status: "ёзмоқда",
  history_zero_data_title: "Биз сиз билан ҳали мулоқот қилмаганмиз",
  history_zero_data_desc: "Бу ерда сиз мулоқот тарихини кўрасиз",
  history_group_collapse: "Ёпиш",
  history_section_actual: "Яқинда",
  history_section_archive: "Тарих",
  back_to_chat: "Чатга қайтиш",
  integration_zoom_button_text: "Қўшилиш",
  integration_zoom_tip: "☝️ Маслаҳат: видеоконференцияга браузер орқали қўшилишингиз мумкин",
  dateMonth: {
  "0": "январ",
    "1": "феврал",
    "2": "март",
    "3": "апрел",
    "4": "май",
    "5": "июн",
    "6": "июл",
    "7": "август",
    "8": "сентябр",
    "9": "октябр",
    "10": "ноябр",
    "11": "декабр",
},
fileSize: {
  "0": "Б",
    "1": "КБ",
    "2": "МБ",
    "3": "ГБ",
},
at: "да",
  edited: "Таҳрирланган",
  removed: "Ўчирилган",
  removedMessage: "Хабар ўчирилган",
  bot_answer_placeholder: "Жавобни киритинг",
  bot_buttons_placeholder: "Жавоб вариантларини танланг",
  connection_problem_toast: "<b>Чат билан алоқа йўқ.</b> Биз автоматик равишда қайта уриниб кўрамиз.",
  input_error_email: "Тўғри Email киритинг",
  input_error_phone: "Тўғри телефон рақамини киритинг",
  popup_close_all_button: "Ҳаммасини ёпиш",
  offline: "офлайн",
  online: "онлайн",
  whatsapp_auth_text:
"Ушбу хабарни бизга юборинг, веб-чат идентификаторингизни: {{userId}} WhatsApp'га ўтказиш учун. Агар сиз бу хабарни таҳрирласангиз ёки ўчирсангиз, янги чат яратиласиз ва бошқа оператор билан боғланасиз",
  popup_desc: "Поп-ап",
  image_desc: "Расм",
  emoji_search_placeholder: "Қидириш",
  emoji_zero_data: "Ҳеч нарса топилмади",
  emoji_category_people: "Смайликлар ва одамлар",
  emoji_category_nature: "Ҳайвонлар ва табиат",
  emoji_category_food: "Овқат ва ичимликлар",
  emoji_category_activity: "Фаолият",
  emoji_category_places: "Саёҳат ва жойлар",
  emoji_category_objects: "Предметлар",
  emoji_category_symbols: "Рамзлар",
  emoji_category_flags: "Байроқлар",
};

export const carrotAdditionalSettingsUzCyrl = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Биз шу ердамиз ва ёрдам беришга тайёрмиз",
  messenger_offline_message: "Оператор сиз учун махсус уланади.",
  messenger_welcome_message:
    "Ассалому алайкум! Сиз Business Booster Platform техқўллаб-қувватлаш хизматига мурожаат қилдингиз. Илтимос, муаммоингизнинг моҳиятини ёзиб қолдиринг, биз энг яқин вақтда жавоб берамиз. Мурожаатингиз учун раҳмат!",
};
