import { TextEditorView } from "./TextEditorView";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { initialEditorContent, isContentEmpty } from "../../../utils/textEditor";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LinkNode } from "@lexical/link";
import { ITextEditor } from "./TextEditor";

export const TextEditorContainer = memo(
  forwardRef((props: ITextEditor, ref) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [isVisibleToolbar, setIsVisibleToolbar] = useState<boolean>(false);
    const [editor] = useLexicalComposerContext();
    const [isActivated, setIsActivated] = useState<boolean>(false);


    const handleIsLoadingChange = useCallback((value: boolean) => {
      setIsLoading(value);
    }, []);

    const handleIsVisibleToolbarChange = useCallback((value: boolean) => {
      setIsVisibleToolbar(value);
      if (props.onFocus) {
        props.onFocus();
      }
    }, []);

    const handleCheckContentIsEmpty = useCallback((): boolean => {
      return isContentEmpty(editor);
    }, [editor]);

    const handleResetEditor = useCallback(
      (content?: string) => {
        const editorState = editor.parseEditorState(content ?? props.initialValue!);
        editor.setEditorState(editorState);
      },
      [editor, props.initialValue]
    );

    const hiddenToolbar = useCallback(() => {
      setIsVisibleToolbar(false);
    }, []);

    useImperativeHandle(ref, () => ({
      isEmptyEditor: handleCheckContentIsEmpty,
      resetEditor: handleResetEditor,
      hiddenToolbar,
      setIsActivated
    }));

    useEffect(() => {
      editor.registerNodeTransform(LinkNode, (linkNode) => {
        if (linkNode.getTarget() === null) {
          linkNode.setTarget("_blank");
        }
      });
    }, [editor]);

    useEffect(() => {
      if (props.anchorKey && isFirstRender && props.value !== initialEditorContent) {
        const timeout = setTimeout(() => {
          const element = props.anchorKey && document.getElementById(props.anchorKey);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          setIsFirstRender(false);
        }, 1500);
        return () => clearTimeout(timeout);
      }
    }, [isFirstRender, props.anchorKey, props.value]);

    useEffect(() => {
      if (props.id === "add-proof-editor-with-last-comment") {
        handleResetEditor();
        setIsFirstRender(false);
      }
    }, [editor, handleResetEditor, isFirstRender, props.id]);

    return (
      <TextEditorView
        {...props}
        editor={editor}
        isLoading={isLoading}
        isLoadingChange={handleIsLoadingChange}
        isVisibleToolbar={isVisibleToolbar}
        setIsVisibleToolbar={handleIsVisibleToolbarChange}
        isActivated={props.isVisibleContent ?? isActivated}
        setIsActivated={setIsActivated}

      />
    );
  })
);
