export const carrotQuestLocaleRu = {
  thanks: "Спасибо",
  dialogs_history: "История диалогов",
  powered_by_text_before_logo: "Работает&nbsp;на",
  powered_by_text_after_logo: "",
  enter_message: "Введите сообщение…",
  enter_email: "Введите e-mail",
  enter_phone: "Введите телефон",
  leave_email: "Вы можете оставить свой e-mail, и продолжить общение с нами через почту:",
  page_title_new_message: "Новое сообщение",
  privacy_policy: "Условия обработки данных",
  errorFileType:
    "Недопустимый формат файла для загрузки. Вы можете загрузить файлы: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf , mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Файл слишком большой<br/>Максимальный размер файла — 10 MB.",
  errorManyRequests: "Слишком большое количество файлов<br/>Вы отправляете файлы слишком часто. Надо немного подождать",
  errorReplyTooBig: "Сообщение не может быть длиннее 20 000 символов",
  errorUserBan: "Вы заблокированы<br/>У вас больше нет возможности писать новые сообщения в чат",
  error: "Что-то пошло не так.<br/>Произошла ошибка при загрузке. Попробуйте еще раз.",
  close: "Закрыть",
  button_start_message: "Написать",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Начать или продолжить диалог в {{networkName}}",
  social_network_page_desc_vk: [
    "Откройте VK с помощью кнопки ниже, чтобы перейти в нужный диалог",
    "Напишите нам свой вопрос",
  ],
  social_network_page_desc_viber: [
    "Откройте Viber с помощью кнопки ниже, чтобы перейти в нужный диалог",
    "Напишите нам свой вопрос",
  ],
  social_network_page_desc_telegram: [
    "Откройте Telegram с помощью кнопки ниже, чтобы перейти в нужный диалог",
    "Нажмите кнопку «Start» в Telegram",
    "Напишите нам свой вопрос",
  ],
  social_network_page_desc_facebook: [
    "Откройте Facebook с помощью кнопки ниже, чтобы перейти в нужный диалог",
    "Нажмите кнопку «Начать» в Facebook",
    "Напишите нам свой вопрос",
  ],
  social_network_page_desc_instagram: [
    "Откройте Instagram с помощью кнопки ниже, чтобы перейти на нужную страницу",
    "Нажмите кнопку «Написать сообщение» в Instagram",
    "Напишите нам свой вопрос",
  ],
  social_network_page_desc_whatsapp: [
    "Откройте WhatsApp с помощью кнопки ниже, чтобы перейти в нужный диалог",
    "Отправьте предзаполненное сообщение",
    "Напишите нам свой вопрос",
  ],
  social_network_page_open_button: "Открыть {{networkName}}",
  social_network_qr_code_title: "Отсканируйте этот QR-код, чтобы открыть {{networkName}} с&nbsp;другого устройства",
  bumperText:
    'Мы используем cookies для улучшения работы сайта, анализа трафика и персонализации. Используя сайт или кликая на Я согласен, вы соглашаетесь с нашей политикой использования персональных данных и cookies в соответствии с Политикой о персональных данных. Вы можете прочитать нашу политику <a href="{{privacy-policy-link}}", target="_blank">здесь</a>',
  bumperButton: "Я согласен",
  confirmSubscriptionWithCheckbox:
    'Я согласен с <a href="{{subscription-policy}}" target="_blank">условиями&nbsp;обработки&nbsp;данных</a>',
  confirmSubscriptionWithoutCheckbox:
    'Нажимая на кнопку, вы соглашаетесь с <a href="{{subscription-policy}}" target="_blank">условиями&nbsp;обработки&nbsp;данных</a>',
  appeal: "Вы",
  vote_comment: "Что произошло?",
  vote_text: "Пожалуйста, оцените работу оператора",
  vote_thanks: "Спасибо за оценку!",
  cart_button: "Подробнее",
  cart_no_name: "Без названия",
  cart_zero: "Данные о товарах в корзине не загрузились",
  article_link: "Читать статью",
  to_knowledge_base: "Найти ответ в базе знаний",
  typing_status: "Печатает…",
  notification_enter_message: "Введите сообщение…",
  notification_message_sending: "Сообщение отправляется…",
  notification_message_sent: "Сообщение отправлено",
  notification_file_sending: "Файл загружается…",
  notification_file_sent: "Файл отправлен",
  notification_typing_status: "печатает",
  history_zero_data_title: "Мы с вами ещё не общались",
  history_zero_data_desc: "Здесь будет история ваших диалогов",
  history_group_collapse: "Свернуть",
  history_section_actual: "Недавно",
  history_section_archive: "История",
  back_to_chat: "Вернуться в чат",
  integration_zoom_button_text: "Присоединиться",
  integration_zoom_tip: "☝️ Подсказка: присоединиться к видеоконференции можно прямо из браузера",
  dateMonth: {
    "0": "января",
    "1": "февраля",
    "2": "марта",
    "3": "апреля",
    "4": "мая",
    "5": "июня",
    "6": "июля",
    "7": "августа",
    "8": "сентября",
    "9": "октября",
    "10": "ноября",
    "11": "декабря",
  },
  fileSize: {
    "0": "Б",
    "1": "КБ",
    "2": "МБ",
    "3": "ГБ",
  },
  at: "в",
  edited: "Изменено",
  removed: "Удалено",
  removedMessage: "Сообщение удалено",
  bot_answer_placeholder: "Введите ответ",
  bot_buttons_placeholder: "Выберите вариант ответа",
  connection_problem_toast: "<b>Нет соединения с чатом.</b> Мы попробуем переподключиться автоматически.",
  input_error_email: "Введите корректный Email",
  input_error_phone: "Введите корректный телефон",
  popup_close_all_button: "Скрыть все",
  offline: "офлайн",
  online: "онлайн",
  whatsapp_auth_text:
    "Отправьте это сообщение нам, чтобы перенести свой идентификатор веб-чата: {{userId}} сюда, в Whatsapp. Если вы отредактируете или удалите это сообщение, вы создадите новый чат и свяжетесь с другим оператором",
  popup_desc: "Поп-ап",
  image_desc: "Изображение",
  emoji_search_placeholder: "Поиск",
  emoji_zero_data: "Ничего не найдено",
  emoji_category_people: "Смайлики и люди",
  emoji_category_nature: "Животные и природа",
  emoji_category_food: "Еда и напитки",
  emoji_category_activity: "Активность",
  emoji_category_places: "Путешествия и местности",
  emoji_category_objects: "Предметы",
  emoji_category_symbols: "Символы",
  emoji_category_flags: "Флаги",
};

export const carrotAdditionalSettingsRu = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Мы тут и готовы помочь",
  messenger_offline_message: "Оператор подключится специально для вас.",
  messenger_welcome_message:
    "Добрый день! Вы обратились в техподдержку Business Booster Platform. Напишите, пожалуйста, суть Вашей проблемы, и мы ответим вам в ближайшее время. Спасибо за Ваше обращение!",
};
