import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../containers/issueUpdateContainer/IssueUpdateContainer.scss";
import { IssueHistorySenderBlock } from "./components/IssueHistorySenderBlock/IssueHistorySenderBlock";
import { IssueAceptHistoryLocalesConst, IssueHistoryConst, IssueIndicatorsKeysConst } from "../../../types/consts";
import { IssueHistoryListComments } from "./components/issueHistoryListComments/IssueHistoryListComments";
import { IIssueHistoryModule } from "./IssueHistory.interface";
import { IssueCommentAttachmentDto, IssueHistoryDto, StaticFileDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { initialEditorContent } from "../../../../../../utils/textEditor";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { DeleteCommentDialog } from "../../../../dialogs/deleteCommentDialog/DeleteCommentDialog";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { BroadcastChannel } from "broadcast-channel";
import { customEvent } from "../../../../../../helpers/eventBus";
import { HistorySendBlock } from "../../../../../uiKit/History/HistorySendBlock";

const IssueHistoryModuleMemo = (props: IIssueHistoryModule) => {
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  // const editorRef = useRef<{ clear: () => void }>();
  const editorRef = useRef<{ isEmptyEditor: () => boolean; resetEditor: () => void }>();
  const attachmentsCommentCopy = useRef<StaticFileDto[]>([]);

  // const [comment, setComment] = useState<Value>(initialContent);
  // const [comment, setComment] = useState<string>(initialEditorContent);
  // const [attachments, setAttachments] = useState<StaticFileDto[]>([]);
  const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);
  const [openDeleteCommentDialog, setOpenDeleteCommentDialog] = useState<boolean>(false);
  const [issueHistoryId, setIssueHistoryId] = useState<number | null>(null);
  const issueChannel = new BroadcastChannel("issue");

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });


  const isAllowAcceptButton = useMemo(
    () => !!props.indicators?.find((item) => item.key === IssueIndicatorsKeysConst.HasMessage),
    [props.indicators]
  );

  // const setAttachmentsCommentSync = useCallback(
  //   (v: StaticFileDto[]) => {
  //     setAttachments(v);
  //     attachmentsCommentCopy.current = v;
  //   },
  //   [setAttachments]
  // );

  // const handleChangeComment = (comment: Value) => setComment(comment);
  // const handleChangeComment = useCallback((comment: string) => {
  //   setComment(comment);
  // }, []);
  //
  // const handleDeleteAttachmentById = (fileId: number) => {
  //   if (fileId == null) return;
  //   setAttachmentsCommentSync((attachments ?? []).filter((x) => x.id != fileId));
  // };
  //
  // const handleAcceptIssueHistory = useCallback(async (): Promise<IssueHistoryDto | void> => {
  //   const localeType = appStore.getAppLocale;
  //   const createDto: IssueHistoryDto = {
  //     issueId: props.issueId,
  //     comment: {
  //       text:
  //         localeType === IssueAceptHistoryLocalesConst.Ru.locale
  //           ? IssueAceptHistoryLocalesConst.Ru.message
  //           : IssueAceptHistoryLocalesConst.En.message,
  //     },
  //   };
  //   const r = await api.issueHistory.create(createDto);
  //   if (r == null) return handlerApiError();
  //   // editorRef.current?.clear();
  //   // setComment(initialEditorContent);
  //   // setAttachments([]);
  //   return r;
  // }, [appStore]);

  const handleCreateIssueHistory = useCallback(
    async (text, attachments: StaticFileDto[]) => {
      setIsLoadingSend(true);
      const createDto: IssueHistoryDto = {
        issueId: props.issueId,
        comment: {
          text,
          attachments: attachments ?? [],
        },
      };
      const r = await api.issueHistory.create(createDto);
      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueHistory",
      });
      setIsLoadingSend(false);
      if (r) {
        // setComment(initialEditorContent);
        customEvent.dispatch("reloadIssueHistory", { issueId: props.issueId });
        // setAttachmentsCommentSync([]);
      }
      return !!r;
      // editorRef.current?.resetEditor();
    },
    [
      // comment,
      // handlerApiError,
      issueChannel,
      // props?.createdByUserId,
      // props?.executorUserId,
      // props.isExecutor,
      props.issueId,
      // setAttachmentsCommentSync,
    ]
  );

  const handleEditComment = useCallback(
    async (editorContent: string, attachmentsList?: IssueCommentAttachmentDto[]) => {
      console.log("issueHistoryId", issueHistoryId);
      if (issueHistoryId) {
        const attachments = [
          ...(attachmentsList ?? []).map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        ];
        const r = await api.issueHistory.edit(issueHistoryId, { comment: { text: editorContent, attachments } });
        if (r == null) {
          notifier.show({
            message: t("notifier:error.something_wrong"),
            theme: "error",
          });
          return false;
        }
        notifier.show({
          message: t("notifier:success.saved"),
          theme: "success",
        });
        setIssueHistoryId(null);
        // await issueChannel.postMessage({
        //   issueId: props.issueId,
        //   type: "issueHistory",
        // });
        return true;
      }
      return false;
    },
    [issueChannel, issueHistoryId, notifier, props.issueId, t]
  );

  const handleDeleteComment = useCallback(async (id?: number) => {
    if (issueHistoryId || id) {
      const historyId = issueHistoryId ?? id;
      const r = await api.issueHistory.del(historyId!);
      if (r == null) {
        notifier.show({
          message: t("notifier:error.something_wrong"),
          theme: "error",
        });
        return false;
      }
      notifier.show({
        message: t("notifier:success.deleted"),
        theme: "success",
      });

      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueHistory",
      });
      return true;
    }
    // setIsEditMode(false);
    setOpenDeleteCommentDialog(false);
    return false;
  }, [issueChannel, issueHistoryId, notifier, props.issueId, t]);

  const handleOpenDeleteCommentDialog = useCallback((id: number) => {
    setIssueHistoryId(id);
    setOpenDeleteCommentDialog(true);
  }, []);
  const handleCloseDeleteCommentDialog = useCallback(() => {
    setOpenDeleteCommentDialog(false);
  }, []);
  const handleIssueHistoryIdChange = useCallback((id: number) => {
    console.log("handleIssueHistoryIdChange", id);
    setIssueHistoryId(id);
  }, []);

  return (
    <>
      {openDeleteCommentDialog && (
        <DeleteCommentDialog
          onClose={handleCloseDeleteCommentDialog}
          open={openDeleteCommentDialog}
          onDelete={handleDeleteComment}
          title={t("ui:title.confirm_delete_comment")}
          description={t("ui:text.confirm_delete_comment")}
        />
      )}
      <div className="flex-grow-1 pl-5" style={{ overflow: "auto", width: "45%" }}>
        {/*TODO: Empty Character*/}
        <div style={{ visibility: "hidden" }} children={<SubheaderText text={"ㅤ"} />} />
        <HistorySendBlock
          itemId={props.issueId}
          isLoading={isLoadingSend}
          onCreateHistory={handleCreateIssueHistory}
          isSimplifiedToolbar
          isShowUpload
          isShowBorder
          isIssue
        />

        <IssueHistoryListComments
          issueId={props.issueId}
          onOpenDeleteCommentDialog={handleOpenDeleteCommentDialog}
          onIssueHistoryIdChange={handleIssueHistoryIdChange}
          issueHistoryId={issueHistoryId}
          onEditComment={handleEditComment}
          onDeleteComment={handleDeleteComment}
          currentStatusId={props.currentStatusId}
          isOldHistory={props.isOldHistory}
        />
      </div>
    </>
  );
};

export const IssueHistoryModule = React.memo(IssueHistoryModuleMemo);
