import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IPlanningPageMetricContent } from "./PlanningPageMetricContent.interface";
import { Metrics } from "../../../dashboards/components/Metrics/Metrics";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { MetricReadDto } from "../../../../../../api";
import { MetricFilter } from "../../../../../../api/filters/metricFilter";
import { api } from "../../../../../../services";
import dayjs from "dayjs";
import { useNotifier } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import "./PlanningPageMetricContent.scss";

export const PlanningPageMetricContent = memo((props: IPlanningPageMetricContent) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const todayDate = new Date();
  const halfYearAgoDate = dayjs(todayDate).subtract(15, "year").toDate();
  const nextMonthDate = dayjs(todayDate).add(2, "year").toDate();

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });


  const getMetricFilter = useMemo((): MetricFilter => {
    return {
      responsibleUserId: props.userId,
      strictByUserId: props.userId,
    };
  }, [props.userId]);

  const metrics = usePagingWithController<MetricReadDto, MetricFilter>(
    api.metricRead,
    {
      ...getMetricFilter,
      dateFrom: halfYearAgoDate.toISOString(),
      dateTo: nextMonthDate.toISOString(),
    },
    { pageSize: 6, order: "desc", orderBy: "is_main" },
    undefined,
    () => handleApiError(),
    () => setIsLoading(false)
  );

  useEffect(() => {
    metrics.restart();
  }, [getMetricFilter]);

  const handleDeleteMetric = (metricSource2UserId: number) => {
    console.log("handleDeleteMetric", metricSource2UserId);
  }

  const handleRestartLoad = () => {
    console.log("onRestartLoad");
  }

  const [printMetricId, setPrintMetricId] = useState<number | null>(null);
  const handleSetIsPrintMetricId = useCallback((id: number | null) => {
    setPrintMetricId(id);
  }, []);

  const handlePrintMetric = useCallback(() => {
    window.onafterprint = () => {
      handleSetIsPrintMetricId(null);
    };
    return window.print();
  }, []);

  useEffect(() => {
    if (printMetricId) {
      setTimeout(() => {
        handlePrintMetric();
      }, 1);

    }
  }, [printMetricId]);

  return (
    <div className="planning-page-metric-content d-stack-row spacing-2">
      <Metrics
        metrics={metrics.items ?? []}
        isLoading={metrics.info.isLoading}
        isDone={metrics.info.isDone}
        loadNext={metrics.loadNext}
        // weekReportStart={props.weekReportStart}
        dashboardColumnsAmount={1}
        deleteMetric={handleDeleteMetric}
        restart={handleRestartLoad}
        printMetricId={printMetricId}
        onSetIsPrintMetricId={handleSetIsPrintMetricId}
        isRow
      />
    </div>
  )
});
