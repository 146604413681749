import React, { memo, useCallback, useState } from "react";
import { Button, Dropdown, IconButton } from "../../../../../uiKit";
import { PlusIcon } from "../../../../../../constants/icon";
import "./RegulationEditorToolbarAction.scss";
import { IRegulationEditorToolbarActionProps } from "./IRegulationEditorToolbarAction";
import { useTranslation } from "react-i18next";

export const RegulationEditorToolbarAction = memo((props: IRegulationEditorToolbarActionProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleIsOpenChange = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  const handleClickCreateTesting = () => {
    props.handleClickOpenTest();
    handleIsOpenChange(false);
  }

  return (
    // "filled" | "outlined" | "text" | "link" | "default";
    <Button
      onClick={ handleClickCreateTesting }
      variant="outlined"
      className="ml-1"
    >
      { t("ui:regulation_actions.testing.button") }
    </Button>
    // <Dropdown
    //   open={isOpen}
    //   onOpenChange={handleIsOpenChange}
    //   placement={"bottom"}
    //   rootClassName={"regulation-editor-toolbar-action-drop-down"}
    //   trigger={["click"]}
    //   dropdownRender={() => {
    //     return (
    //       <div
    //         className="regulation-editor-toolbar-action"
    //       >
    //         <div
    //           className="regulation-editor-toolbar-action-item"
    //           onClick={handleClickCreateTesting}
    //         >
    //           <div className="regulation-editor-toolbar-action-item__title">
    //             { t("ui:regulation_actions.testing.title") }
    //           </div>
    //           <div className="regulation-editor-toolbar-action-item__description">
    //             { t("ui:regulation_actions.testing.text") }
    //           </div>
    //         </div>
    //         {/*<div className="regulation-editor-toolbar-action-item">*/}
    //         {/*  <div className="regulation-editor-toolbar-action-item__title">*/}
    //         {/*    Оглавление*/}
    //         {/*  </div>*/}
    //         {/*  <div className="regulation-editor-toolbar-action-item__description">*/}
    //         {/*    Создать оглавление на основе заголовков страницы*/}
    //         {/*  </div>*/}
    //         {/*</div>*/}
    //       </div>
    //     );
    //   }}
    // >
    //   <IconButton
    //     onClick={() => handleIsOpenChange(true)}
    //     icon={<PlusIcon size={16} />}
    //   />
    // </Dropdown>
  )
});
