import React from "react";
import "./TopAppBar.scss";
import { Divider, Icon, IconButton, LayoutHeader } from "../../../uiKit";
import { FiMenu, FiX } from "react-icons/fi";
import CompanySwitcher from "../companySwitcher/CompanySwitcher";
import { GlobalSearch } from "./components/globalSearch/GlobalSearch";
import { FaCat } from "react-icons/fa";
import { FiDribbble } from "@react-icons/all-files/fi/FiDribbble";
import { FiCpu } from "@react-icons/all-files/fi/FiCpu";
import { ThemeSwitcher } from "../../pages/settings/themeSwitcher/ThemeSwitcher";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { NotificationCenter } from "../../../service/notificationCenter/NotificationCenter";
import TopAppBarMenu from "../topAppBarMenu/TopAppBarMenu";
import { useIsMobile, useRootStore } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

function TopAppBarObserved() {
  const { appStore } = useRootStore();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const onRedirectToSettings = () => navigate("/settings");

  return (
    <LayoutHeader className="top-app-bar">
      {isMobile && (
        <IconButton
          className="flex-shrink-0 ml-2 mr-2"
          icon={
            appStore.getLeftDrawerState == "__collapsed" ? (
              <Icon component={() => <FiMenu opacity={0.7} fontSize={20} />} />
            ) : (
              <Icon component={() => <FiX opacity={0.7} fontSize={20} />} />
            )
          }
          onClick={() =>
            appStore.setDrawerBehavior(
              appStore.getLeftDrawerState == "__collapsed" ? "alwaysExpanded" : "alwaysCollapsed"
            )
          }
        />
      )}
      <CompanySwitcher />
      <GlobalSearch />
      <div className="flex-grow-1" />
      {(window.location?.hostname == "localhost" ||
        window.location?.hostname == "beta.tonnus.io" ||
        window.location.hostname == "dev.bbooster.io") && (
        <div className="d-stack spacing-3 mr-3">
          <IconButton className="flex-shrink-0" onClick={() => navigate("/service/dev")}>
            <FaCat fontSize={24} color="darkKhaki" />
          </IconButton>
          <IconButton className="flex-shrink-0" onClick={() => navigate("/service/ui")}>
            <FiDribbble fontSize={24} color="mediumVioletRed" />
          </IconButton>
          <IconButton className="flex-shrink-0" onClick={() => navigate("/sandbox")}>
            <FiCpu fontSize={24} color="green" />
          </IconButton>
        </div>
      )}
      <div className="d-stack spacing-3 mr-3">
        <ThemeSwitcher displayMode="button" />
        <IconButton className="flex-shrink-0" onClick={onRedirectToSettings}>
          <FiSettings fontSize={24} />
        </IconButton>
        <NotificationCenter />
      </div>
      <div style={{ height: "36px" }} className="mx-3">
        <Divider isVertical />
      </div>
      <TopAppBarMenu />
    </LayoutHeader>
  );
}

export const TopAppBar = observer(TopAppBarObserved);
