export const carrotQuestLocaleUk = {
  thanks: "Дякуємо",
  dialogs_history: "Історія діалогів",
  powered_by_text_before_logo: "Працює&nbsp;на",
  powered_by_text_after_logo: "",
  enter_message: "Введіть повідомлення…",
  enter_email: "Введіть e-mail",
  enter_phone: "Введіть телефон",
  leave_email: "Ви можете залишити свій e-mail і продовжити спілкування через пошту:",
  page_title_new_message: "Нове повідомлення",
  privacy_policy: "Політика конфіденційності",
  errorFileType:
    "Неприпустимий формат файлу. Ви можете завантажити файли: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Файл занадто великий<br/>Максимальний розмір файлу — 10 MB.",
  errorManyRequests: "Занадто багато файлів<br/>Ви надсилаєте файли занадто часто. Зачекайте трохи.",
  errorReplyTooBig: "Повідомлення не може бути довшим за 20 000 символів.",
  errorUserBan: "Вас заблоковано<br/>Ви більше не можете надсилати нові повідомлення в чат.",
  error: "Щось пішло не так.<br/>Сталася помилка під час завантаження. Спробуйте ще раз.",
  close: "Закрити",
  button_start_message: "Написати",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Почати або продовжити діалог у {{networkName}}",
  social_network_page_desc_vk: [
    "Відкрийте VK за допомогою кнопки нижче, щоб перейти в чат",
    "Напишіть нам своє питання",
  ],
  social_network_page_desc_viber: [
    "Відкрийте Viber за допомогою кнопки нижче, щоб перейти в чат",
    "Напишіть нам своє питання",
  ],
  social_network_page_desc_telegram: [
    "Відкрийте Telegram за допомогою кнопки нижче, щоб перейти в чат",
    "Натисніть кнопку «Start» у Telegram",
    "Напишіть нам своє питання",
  ],
  social_network_page_desc_facebook: [
    "Відкрийте Facebook за допомогою кнопки нижче, щоб перейти в чат",
    "Натисніть кнопку «Почати» у Facebook",
    "Напишіть нам своє питання",
  ],
  social_network_page_desc_instagram: [
    "Відкрийте Instagram за допомогою кнопки нижче, щоб перейти на потрібну сторінку",
    "Натисніть кнопку 'Написати повідомлення' в Instagram",
    "Напишіть нам своє запитання",
  ],
  social_network_page_desc_whatsapp: [
    "Відкрийте WhatsApp за допомогою кнопки нижче, щоб перейти в чат",
    "Надішліть попередньо заповнене повідомлення",
    "Напишіть нам своє запитання",
  ],
  social_network_page_open_button: "Відкрити {{networkName}}",
  social_network_qr_code_title: "Відскануйте цей QR-код, щоб відкрити {{networkName}} з іншого пристрою",
  bumperText:
    'Ми використовуємо cookies для покращення роботи сайту, аналізу трафіку та персоналізації. Використовуючи сайт або натискаючи "Я згоден", ви приймаєте нашу політику конфіденційності та використання cookies відповідно до <a href="{{privacy-policy-link}}" target="_blank">Політики конфіденційності</a>.',
  bumperButton: "Я згоден",
  confirmSubscriptionWithCheckbox:
    'Я згоден з <a href="{{subscription-policy}}" target="_blank">умовами обробки даних</a>',
  confirmSubscriptionWithoutCheckbox:
    'Натискаючи кнопку, ви погоджуєтеся з <a href="{{subscription-policy}}" target="_blank">умовами обробки даних</a>',
  appeal: "Ви",
  vote_comment: "Що сталося?",
  vote_text: "Будь ласка, оцініть роботу оператора",
  vote_thanks: "Дякуємо за вашу оцінку!",
  cart_button: "Докладніше",
  cart_no_name: "Без назви",
  cart_zero: "Дані про товари в кошику не завантажилися",
  article_link: "Читати статтю",
  to_knowledge_base: "Знайти відповідь у базі знань",
  typing_status: "Друкує…",
  notification_enter_message: "Введіть повідомлення…",
  notification_message_sending: "Надсилання повідомлення…",
  notification_message_sent: "Повідомлення надіслано",
  notification_file_sending: "Завантаження файлу…",
  notification_file_sent: "Файл надіслано",
  notification_typing_status: "друкує",
  history_zero_data_title: "Ми ще не спілкувалися",
  history_zero_data_desc: "Тут буде історія ваших чатів",
  history_group_collapse: "Згорнути",
  history_section_actual: "Нещодавно",
  history_section_archive: "Історія",
  back_to_chat: "Повернутися в чат",
  integration_zoom_button_text: "Приєднатися",
  integration_zoom_tip: "☝️ Підказка: приєднатися до відеоконференції можна прямо з браузера",
  dateMonth: {
    "0": "січня",
    "1": "лютого",
    "2": "березня",
    "3": "квітня",
    "4": "травня",
    "5": "червня",
    "6": "липня",
    "7": "серпня",
    "8": "вересня",
    "9": "жовтня",
    "10": "листопада",
    "11": "грудня",
  },
  fileSize: {
    "0": "Б",
    "1": "КБ",
    "2": "МБ",
    "3": "ГБ",
  },
  at: "о",
  edited: "Змінено",
  removed: "Видалено",
  removedMessage: "Повідомлення видалено",
  bot_answer_placeholder: "Введіть відповідь",
  bot_buttons_placeholder: "Виберіть варіант відповіді",
  connection_problem_toast: "<b>Немає з'єднання з чатом.</b> Ми спробуємо перепідключитися автоматично.",
  input_error_email: "Введіть коректний Email",
  input_error_phone: "Введіть коректний номер телефону",
  popup_close_all_button: "Приховати все",
  offline: "офлайн",
  online: "онлайн",
  whatsapp_auth_text:
    "Надішліть нам це повідомлення, щоб перенести ваш ідентифікатор веб-чату: {{userId}} у WhatsApp. Якщо ви зміните або видалите це повідомлення, ви створите новий чат і зв’яжетеся з іншим оператором.",
  popup_desc: "Попап",
  image_desc: "Зображення",
  emoji_search_placeholder: "Пошук",
  emoji_zero_data: "Нічого не знайдено",
  emoji_category_people: "Смайли та люди",
  emoji_category_nature: "Тварини та природа",
  emoji_category_food: "Їжа та напої",
  emoji_category_activity: "Активність",
  emoji_category_places: "Подорожі та місця",
  emoji_category_objects: "Об'єкти",
  emoji_category_symbols: "Символи",
  emoji_category_flags: "Прапори",
};

export const carrotAdditionalSettingsUk = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Ми тут і готові допомогти",
  messenger_offline_message: "Оператор підключиться спеціально для вас.",
  messenger_welcome_message:
    "Добрий день! Ви звернулися до служби підтримки Business Booster Platform. Будь ласка, опишіть суть вашої проблеми, і ми відповімо вам найближчим часом. Дякуємо за ваше звернення!",
};
