export const carrotQuestLocaleUzLatan = {
  thanks: "Rahmat",
  dialogs_history: "Muloqot tarixi",
  powered_by_text_before_logo: "Ishlaydi&nbsp;",
  powered_by_text_after_logo: "",
  enter_message: "Xabar yozing…",
  enter_email: "E-mail kiriting",
  enter_phone: "Telefon raqamingizni kiriting",
  leave_email: "Siz e-mail qoldirib, biz bilan pochta orqali muloqotni davom ettirishingiz mumkin:",
  page_title_new_message: "Yangi xabar",
  privacy_policy: "Ma'lumotlar qayta ishlash shartlari",
  errorFileType:
    "Yuklash uchun fayl formati noto‘g‘ri. Siz quyidagi fayllarni yuklashingiz mumkin: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Fayl haddan tashqari katta<br/>Maksimal fayl hajmi — 10 MB.",
  errorManyRequests: "Juda ko‘p fayllar yuklandi<br/>Siz fayllarni juda tez yuklamoqdasiz. Biroz kuting",
  errorReplyTooBig: "Xabar 20 000 belgidan oshmasligi kerak",
  errorUserBan: "Siz bloklandingiz<br/>Chatda yangi xabar yozish imkoni yo‘q",
  error: "Nimadir noto‘g‘ri ketdi.<br/>Yuklashda xatolik yuz berdi. Qayta urinib ko‘ring.",
  close: "Yopish",
  button_start_message: "Yozish",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "{{networkName}} orqali muloqotni boshlash yoki davom ettirish",
  social_network_page_desc_vk: [
    "Quyidagi tugmani bosib, VK orqali muloqotni boshlang",
    "Bizga savolingizni yozing",
  ],
  social_network_page_desc_viber: [
    "Quyidagi tugmani bosib, Viber'ni oching va kerakli muloqotga o‘ting",
    "Bizga savolingizni yozing",
  ],
  social_network_page_desc_telegram: [
    "Quyidagi tugmani bosib, Telegram'ni oching va kerakli muloqotga o‘ting",
    "Telegram'da «Start» tugmasini bosing",
    "Bizga savolingizni yozing",
  ],
  social_network_page_desc_facebook: [
    "Quyidagi tugmani bosib, Facebook'ni oching va kerakli muloqotga o‘ting",
    "Facebook'da «Boshlash» tugmasini bosing",
    "Bizga savolingizni yozing",
  ],
  social_network_page_desc_instagram: [
    "Quyidagi tugmani bosib, Instagram'ni oching va kerakli sahifaga o‘ting",
    "Instagram'da «Xabar yozish» tugmasini bosing",
    "Bizga savolingizni yozing",
  ],
  social_network_page_desc_whatsapp: [
    "Quyidagi tugmani bosib, WhatsApp'ni oching va kerakli muloqotga o‘ting",
    "Tayyorlangan xabarni yuboring",
    "Bizga savolingizni yozing",
  ],
  social_network_page_open_button: "{{networkName}}'ni ochish",
  social_network_qr_code_title: "Ushbu QR-kodni skaner qiling va {{networkName}}'ni boshqa qurilmada oching",
  bumperText:
    "Biz sayt faoliyatini yaxshilash, trafikni tahlil qilish va personalizatsiya uchun cookies'dan foydalanamiz. Saytdan foydalanish yoki «Men roziman» tugmasini bosish orqali siz shaxsiy ma'lumotlar va cookies siyosatiga muvofiq rozilik bildirgan bo‘lasiz. Siyosatimizni <a href=\"{{privacy-policy-link}}\", target=\"_blank\">bu yerda</a> o‘qishingiz mumkin",
  bumperButton: "Men roziman",
  confirmSubscriptionWithCheckbox:
"<a href=\"{{subscription-policy}}\" target=\"_blank\">Ma'lumotlarni qayta ishlash shartlari</a> bilan roziman",
confirmSubscriptionWithoutCheckbox:
  "Tugmani bosish orqali <a href=\"{{subscription-policy}}\" target=\"_blank\">ma'lumotlarni qayta ishlash shartlari</a> bilan rozilik bildirgan bo‘lasiz",
appeal: "Siz",
  vote_comment: "Nima bo‘ldi?",
  vote_text: "Iltimos, operator ishini baholang",
  vote_thanks: "Baholaganingiz uchun rahmat!",
  cart_button: "Batafsil",
  cart_no_name: "Nomsiz",
  cart_zero: "Savatdagi tovarlar ma'lumotlari yuklanmadi",
  article_link: "Maqolani o‘qish",
  to_knowledge_base: "Javobni bilimlar bazasida topish",
  typing_status: "Yozmoqda…",
  notification_enter_message: "Xabar yozing…",
  notification_message_sending: "Xabar yuborilmoqda…",
  notification_message_sent: "Xabar yuborildi",
  notification_file_sending: "Fayl yuklanmoqda…",
  notification_file_sent: "Fayl yuborildi",
  notification_typing_status: "yozmoqda",
  history_zero_data_title: "Biz siz bilan hali muloqot qilmaganmiz",
  history_zero_data_desc: "Bu yerda siz muloqot tarixini ko‘rasiz",
  history_group_collapse: "Yopish",
  history_section_actual: "Yaqinda",
  history_section_archive: "Tarix",
  back_to_chat: "Chatga qaytish",
  integration_zoom_button_text: "Qo‘shilish",
  integration_zoom_tip: "☝️ Maslahat: videokonferensiyaga brauzer orqali qo‘shilishingiz mumkin",
  dateMonth: {
  "0": "yanvar",
    "1": "fevral",
    "2": "mart",
    "3": "aprel",
    "4": "may",
    "5": "iyun",
    "6": "iyul",
    "7": "avgust",
    "8": "sentyabr",
    "9": "oktyabr",
    "10": "noyabr",
    "11": "dekabr",
},
fileSize: {
  "0": "B",
    "1": "KB",
    "2": "MB",
    "3": "GB",
},
at: "da",
  edited: "Tahrirlangan",
  removed: "O‘chirildi",
  removedMessage: "Xabar o‘chirildi",
  bot_answer_placeholder: "Javobni kiriting",
  bot_buttons_placeholder: "Javob variantlarini tanlang",
  connection_problem_toast: "<b>Chat bilan aloqa yo‘q.</b> Biz avtomatik ravishda qayta urinib ko‘ramiz.",
  input_error_email: "To‘g‘ri Email kiriting",
  input_error_phone: "To‘g‘ri telefon raqamini kiriting",
  popup_close_all_button: "Hammasini yopish",
  offline: "oflayn",
  online: "onlayn",
  whatsapp_auth_text:
"Ushbu xabarni bizga yuboring, veb-chat identifikatoringizni: {{userId}} WhatsApp'ga o‘tkazish uchun. Agar siz bu xabarni tahrirlasangiz yoki o‘chirsangiz, yangi chat yaratiladi va boshqa operator bilan bog‘lanasiz",
  popup_desc: "Pop-ap",
  image_desc: "Rasm",
  emoji_search_placeholder: "Qidirish",
  emoji_zero_data: "Hech narsa topilmadi",
  emoji_category_people: "Smayliklar va odamlar",
  emoji_category_nature: "Hayvonlar va tabiat",
  emoji_category_food: "Ovqat va ichimliklar",
  emoji_category_activity: "Faoliyat",
  emoji_category_places: "Sayohat va joylar",
  emoji_category_objects: "Predmetlar",
  emoji_category_symbols: "Ramzlar",
  emoji_category_flags: "Bayroqlar",
};

export const carrotAdditionalSettingsUzLatan = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Biz shu yerdamiz va yordam berishga tayyormiz",
  messenger_offline_message: "Operator siz uchun maxsus ulanadi.",
  messenger_welcome_message:
    "Assalomu alaykum! Siz Business Booster Platform texqo‘llab-quvvatlash xizmatiga murojaat qildingiz. Iltimos, muammoingizning mohiyatini yozib qoldiring, biz eng yaqin vaqtida javob beramiz. Murojaatingiz uchun rahmat!",
};
