import React from "react";
import { User2RoleDto } from "../../../../../api";
import { groupArrayOfObjectsByKey } from "../../../../../helpers/arrayFunctions";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../hooks";
import { Tooltip } from "../../../../uiKit";
import "./EmployeeRolesList.scss";
import { useTranslation } from "react-i18next";

interface IEmployeeRolesList {
  roles: User2RoleDto[];
  maxRolesInOrgchartCount?: number;
  maxOrgchartsCount?: number;
}

function List(props: IEmployeeRolesList) {
  const { orgchartStore } = useRootStore();
  const { t } = useTranslation();
  const getOrgchartById = (id: number) => orgchartStore.getOrgchartsList.find((o) => o.id == id);
  const rList = Object.entries(groupArrayOfObjectsByKey(props.roles, "role.orgchartId")).filter(
    (oR) => getOrgchartById(Number(oR[0])) != null
  ); // ["orgchartId", ["role"]]

  return (
    <div className="d-stack spacing-3 align-center flex-wrap employee-card_roles-list">
      {rList.slice(0, props.maxOrgchartsCount ?? undefined).map((oR) => (
        <div className="d-stack spacing-1 align-center flex-wrap" key={oR[0]}>
          <Tooltip title={getOrgchartById(Number(oR[0]))?.name}>
            <div
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                flexShrink: 0,
                background:
                  (getOrgchartById(Number(oR[0]))?.colorHex != null &&
                  (getOrgchartById(Number(oR[0]))?.colorHex ?? "").trim().length > 0
                    ? getOrgchartById(Number(oR[0]))?.colorHex
                    : "var(--color-layout-fill-base)") ?? "var(--color-layout-fill-base)",
              }}
            />
          </Tooltip>
          {((oR?.[1] ?? []) as User2RoleDto[])
            .slice(0, props.maxRolesInOrgchartCount ?? undefined)
            .map((u2r, index) => (
              <div className="d-stack spacing-1" style={{ whiteSpace: "nowrap" }}>
                {index > 0 ? <span className="__role-divider" children="·" /> : null}
                <span className="__role-name" children={u2r.role?.name} />
              </div>
            ))}
          {((oR?.[1] ?? []) as User2RoleDto[]).slice(0, props.maxRolesInOrgchartCount ?? undefined).length <
            ((oR?.[1] ?? []) as User2RoleDto[]).length && (
            <div className="d-stack spacing-1" style={{ whiteSpace: "nowrap" }}>
              <span className="__role-divider" children="·" />
              <span
                className="__role-more"
                children={t("parse:more_count", {
                  number:
                    ((oR?.[1] ?? []) as User2RoleDto[]).length -
                    ((oR?.[1] ?? []) as User2RoleDto[]).slice(0, props.maxRolesInOrgchartCount ?? undefined).length,
                })}
              />
            </div>
          )}
        </div>
      ))}
      {rList.slice(0, props.maxOrgchartsCount ?? undefined).slice(0, props.maxOrgchartsCount ?? undefined).length <
        rList.length && (
        <div className="d-stack spacing-1 align-center flex-wrap">
          <div
            style={{
              width: "12px",
              height: "12px",
              borderRadius: "50%",
              flexShrink: 0,
              background: "var(--color-layout-fill-base)",
            }}
          />
          <span
            className="__role-more"
            children={t("parse:more_count", {
              number:
                rList.length -
                rList.slice(0, props.maxOrgchartsCount ?? undefined).slice(0, props.maxOrgchartsCount ?? undefined)
                  .length,
            })}
          />
        </div>
      )}
    </div>
  );
}

export const EmployeeRolesList = observer(List);
