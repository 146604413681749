import React, { useEffect, useState } from "react";
import { IListEducationSections, ITrainingData } from "./ListEducationSections.interface";
import { ListEducationSectionsView } from "./ListEducationSectionsView";
import VideoDialog from "../../../../dialogs/videoDialog/VideoDialog";
import { useSearchParams } from "react-router-dom";
import { useRootStore } from "../../../../../../hooks";
import { api } from "../../../../../../services";

export const ListEducationSections = (props: IListEducationSections) => {
  const { appStore } = useRootStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [trainingDialogIsOpen, setTrainingDialogIsOpen] = useState<boolean>(false);
  const [currentTraining, setCurrentTraining] = useState<ITrainingData>({
    videoId: undefined,
    trainingKey: undefined,
    itemId: undefined,
  });

  const onCloseGuideDialog = () => {
    setTrainingDialogIsOpen(false);
    setCurrentTraining({ videoId: undefined, trainingKey: undefined });
    searchParams.delete("eduVidKey");
    searchParams.delete("eduVidLang");
    setSearchParams(searchParams);
  };

  const handleOpenGuideDialog = (trainingData: ITrainingData, fromQuery?: boolean) => {
    setCurrentTraining({
      videoId: trainingData.videoId,
      trainingKey: trainingData.trainingKey,
      itemId: trainingData.itemId,
    });
    setTrainingDialogIsOpen(true);
    if (!fromQuery) {
      searchParams.set("eduVidKey", trainingData.trainingKey ?? "");
      searchParams.set("eduVidLang", appStore.getAppLocale ?? "en");
      setSearchParams(searchParams);
    }
  };

  const preloadVideoData = async () => {
    //
  };

  const handleOpenDialogByQuery = async () => {
    if (searchParams.has("eduVidKey")) {
      const itemKeyFromQuery = searchParams.get("eduVidKey");
      const langKeyFromQuery = searchParams.get("eduVidLang");
      const langKey =
        langKeyFromQuery &&
        (langKeyFromQuery == "en" || langKeyFromQuery == "ru" || langKeyFromQuery == "uk" || langKeyFromQuery == "zh"  || langKeyFromQuery == "de")
          ? searchParams.get("eduVidLang") ?? "en"
          : appStore.getAppLocale ?? "en";
      if (itemKeyFromQuery == null || itemKeyFromQuery.trim().length == 0) return;
      const r = await api.education.getEducationItem({ key: itemKeyFromQuery, lang: langKey });
      if (r == null) {
        searchParams.delete("eduVidKey");
        searchParams.delete("eduVidLang");
        setSearchParams(searchParams);
        return;
      }
      return handleOpenGuideDialog(
        {
          itemId: r[0].id,
          videoId: r[0].videoId,
          trainingKey: r[0].key,
        },
        true
      );
      // const educationItemId = searchParams.get("eduVidKey");
      // const educationItemLang = searchParams.get("eduVidLang");
      // const educationItem = props.educationItems
      //   .map((eI) => eI.children)
      //   .flat()
      //   .find((i) => i?.id == Number(educationItemId));
      //
      // if (educationItemId && educationItem && currentTraining.itemId != educationItem.id && educationItem.isAvailable) {
      //   return handleOpenGuideDialog({
      //     itemId: educationItem.id,
      //     trainingKey: educationItem.key,
      //     videoId: educationItem.videoId,
      //   });
      // }
      // searchParams.delete("eduVidId");
      // setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    handleOpenDialogByQuery();
  }, []);

  // useEffect(() => {
  //   handleOpenDialogByQuery();
  // }, [props.educationItems]);

  return (
    <>
      {trainingDialogIsOpen && currentTraining.videoId && currentTraining.trainingKey && (
        <VideoDialog
          noShowTitle
          open={trainingDialogIsOpen}
          videoId={currentTraining.videoId}
          trainingKey={currentTraining.trainingKey}
          name="communication"
          autoPlay
          onClose={onCloseGuideDialog}
          onWatchedClickObject={{ communication: true }}
          hidden={false}
          refetch={props.refetch}
        />
      )}
      <ListEducationSectionsView items={props.educationItems} onClick={handleOpenGuideDialog} />
    </>
  );
};
