import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import { useClaims, useNotifier, useRootStore } from "../../../hooks";
import { api } from "../../../services";
import EmployeeInviteDialog from "../../modules/dialogs/employeeInviteDialog/EmployeeInviteDialog";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import EmployeeDeleteDialog from "../../modules/dialogs/employeeDeleteDialog/EmployeeDeleteDialog";
import { permissionKeys } from "../../../utils/permissions";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { usePagingWithController } from "../../../hooks/usePaging";
import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import useDebounce from "../../../hooks/useDebounce";
import { Avatar, Button, Card, Divider, Icon, Input, Select, SelectOption, Tag, Tooltip } from "../../uiKit";
import "./EmployeePage.scss";
import { observer } from "mobx-react-lite";
import { ColorModeKeys } from "../../elements/counterChip/misc/keys";
import { CounterChip } from "../../elements/counterChip/CounterChip";
import { theme } from "antd";
import { FiInfo } from "react-icons/fi";
import EmployeeMainCard from "../../modules/pages/employee/employeeMainCard/EmployeeMainCard";
import { useSearchParams } from "react-router-dom";
import { User2CompanyAccessLevel } from "../../../api/models/CompanyUserLimitDto";
import { UsersCount } from "../../../api/models/CompanyForAdminDto";

function EmployeePage() {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore, orgchartStore } = useRootStore();
  const claims = useClaims();
  const { useToken } = theme;
  const { token } = useToken();
  const [searchParams, setSearchParams] = useSearchParams();

  const [orgchartFilter, setOrgchartFilter] = useState<number[]>(parseQueryFilters().orgcharts ?? []);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userToManageId, setUserToManageId] = useState<number | null>(null);
  const [employeeFilters, setEmployeeFilters] = useState<any>({
    name: parseQueryFilters().name,
  });
  const [companyUsersInformationTooltipData, setCompanyUsersInformationTooltipData] = useState<{
    text: string;
    type: "default" | "error";
  }>({
    text: " ",
    type: "error",
  });

  // const data = useApi(() => api.user.getAll({ pageSize: 10000 }));
  const users = usePagingWithController(
    api.user,
    { name: employeeFilters.name, orgchartIds: orgchartFilter ?? [] },
    { pageSize: 20, orderBy: "name", order: "asc" }
  );
  const debouncedFilters = useDebounce(employeeFilters, 500);

  // useEffect(() => {
  //   console.log("useEffect0");
  //   users.restart();
  // }, []);

  useEffect(() => {
    users.reset();
    users.restart();
    (employeeFilters.name ?? "").trim().length > 0
      ? searchParams.set("userName", employeeFilters.name)
      : searchParams.delete("userName");
    (orgchartFilter ?? []).length > 0
      ? searchParams.set("orgchartIds", orgchartFilter.join(","))
      : searchParams.delete("orgchartIds");
    setSearchParams(searchParams);
  }, [debouncedFilters, orgchartFilter]);

  const handleInviteDialogClose = () => {
    setIsInviteDialogOpen(false);
  };

  const handleDeleteOpen = (userId: number) => {
    setIsDeleteDialogOpen(true);
    setUserToManageId(userId);
  };

  const handleChangeAccessType = useCallback(async (userId: number, type: number) => {
    const resp = await api.user.editPatch(userId, [{
      value: type,
      path: "currentAccessType",
      op: "replace"
    }]);

    if (resp === null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    } else {
      users.updateItem(resp.id!, resp);
    }
  }, [users.items]);

  const handleDeleteSuccess = async () => {
    setIsDeleteDialogOpen(false);
    users.reset();
    await users.restart();
    // await data.fetch();
  };

  function parseQueryFilters() {
    return {
      name: searchParams.get("userName") ?? "",
      orgcharts:
        searchParams.get("orgchartIds") != null
          ? searchParams
              .get("orgchartIds")
              ?.split(",")
              .map((o) => Number(o))
              .filter((o) => orgchartStore.getOrgchartsList.some((i) => i.id == o)) ?? []
          : [],
    };
  }

  const generateCompanyUsersInformationTooltipData = async () => {
    const currentCompany = authStore.getCurrentCompany;
    const { userLimits = [], usersCount = {}} = currentCompany ?? {};

    const fullUserCount = usersCount.Full ?? 0;
    const restrictedUserCount = usersCount.Restricted ?? 0;

    const fullAccessLevel = userLimits.find(({accessLevel}) => accessLevel === User2CompanyAccessLevel.Full)
    const restrictedAccessLevel = userLimits.find(({accessLevel}) => accessLevel === User2CompanyAccessLevel.Restricted)

    const usersLimitFull = fullAccessLevel?.limit ?? "∞";
    const usersLimitRestricted = restrictedAccessLevel?.limit ?? "∞";

    setCompanyUsersInformationTooltipData({
      type: (usersLimitFull == "∞" || fullUserCount < usersLimitFull) && (usersLimitRestricted == "∞" || restrictedUserCount < usersLimitRestricted) ? "default" : "error",
      text: t("parse:employees_limit_text", {
        usersCountFullAccess: fullUserCount,
        usersFullAccessLimit: usersLimitFull == "∞" ? "∞" : Math.max(0, usersLimitFull - fullUserCount),
        usersCountRestrictedAccess: restrictedUserCount,
        usersRestrictedAccessLimit: usersLimitRestricted == "∞" ? "∞" : Math.max(0, usersLimitRestricted - restrictedUserCount),
      }),
    });
  };

  useEffect(() => {
    generateCompanyUsersInformationTooltipData();
  }, []);

  return (
    <>
      {isInviteDialogOpen && <EmployeeInviteDialog open={true} handleClose={handleInviteDialogClose} />}
      <PageHeader>
        <div className="employees-header">
          <div className="employees-header__row d-flex align-center flex-grow-1">
            <span
              className="ant-typography uiKit-text mr-2 mb-0"
              style={{fontSize: 20}}
            >
              { t("common:page_title.employees") }
            </span>
            <CounterChip className="mr-2" colorMode={ColorModeKeys.primary} count={users?.info?.totalItems ?? 0} />
            <Select
              style={{ minWidth: "220px" }}
              mode="multiple"
              value={orgchartFilter.filter((x) => x != null)}
              onChange={(value) => setOrgchartFilter(value)}
              className="orgchart-select-employee"
              autoFocus={false}
              bordered={false}
              showSearch={false}
              showArrow={true}
              maxTagCount={4}
              maxTagTextLength={12}
              dropdownRender={(menu) => (
                <>
                  <Button onClick={() => setOrgchartFilter([])} variant="text" className="full-width br-none">
                    {t("parse:all")}
                  </Button>
                  <Divider className="mb-1" />
                  {menu}
                </>
              )}
              tagRender={(p) => {
                const { label, value } = p;
                return (
                  <Tag
                    style={{
                      borderRadius: "24px",
                      paddingLeft: "0",
                      border: `1px solid ${token.colorFill}`,
                      backgroundColor: token.colorFillAlter
                    }}
                  >
                    {label}
                  </Tag>
                );
              }}
            >
              {orgchartStore.getOrgchartsList.map((o) => (
                <SelectOption key={o.id} value={o.id as number}>
                  <span className="mr-2">
                    <Avatar
                      size="small"
                      isNoFlex
                      color={o.colorHex}
                      text={o.name}
                      src={o.image?.url}
                    />
                  </span>
                  <span children={o.name} />
                </SelectOption>
              ))}
            </Select>
            <div className="flex-grow-1" />
            {claims.has(permissionKeys.user.companyInvite.add) && (
              <div className="ml-2 d-stack">
                <Tooltip
                  placement="bottom"
                  title={companyUsersInformationTooltipData.text}
                  isBreakSpaces
                >
                  <Icon
                    className="mr-3"
                    component={() => (
                      <FiInfo
                        color={
                          companyUsersInformationTooltipData.type == "error" && users.items.length != 0
                            ? "var(--color-error-base)"
                            : "var(--color-primary-base)"
                        }
                        fontSize="20px"
                      />
                    )}
                  />
                </Tooltip>
                <Button
                  variant="outlined"
                  icon={<Icon component={() => <FiPlus />} />}
                  onClick={() => setIsInviteDialogOpen(true)}
                  // size="small"
                >
                  {t("ui:button.invite_employee")}
                </Button>
              </div>
            )}
          </div>
          <div className="employees-header__row d-flex align-center flex-grow-1">
            <Input
              className={"employees-header__search"}
              value={employeeFilters.name}
              onInput={(event: any) => {
                event.stopPropagation();
                setEmployeeFilters({ ...employeeFilters, name: event.target.value });
              }}
              prefix={<FiSearch />}
              placeholder={t("ui:placeholder.search_by_employees")}
            />
          </div>
          </div>
      </PageHeader>
      <PageContent isLoading={users.info.isLoading}>
      <div className="d-flex flex-column full-height full-width">
          <div className="employee-container flex-grow-1" >
            <div className="flex-grow-1" style={{ overflow: "auto", maxHeight: "100%"}}>
            <EmployeeDeleteDialog
              open={isDeleteDialogOpen}
              userId={userToManageId as number}
              companyId={authStore.getInitialInfo?.identity?.currentCompanyId as number}
              onSuccess={handleDeleteSuccess}
              onClose={() => setIsDeleteDialogOpen(false)}
            />
            {users.items.length >= 0 && (
              <>
                <div
                  className="d-stack-column spacing-3"
                  children={users.items?.map((u, index) => (
                    <EmployeeMainCard
                      onDelete={handleDeleteOpen}
                      employeeData={u}
                      key={u.id}
                      onChangeAccessType={handleChangeAccessType}
                    />
                  ))}
                />
                <ScrollTrigger
                  disabled={users.info.isDone}
                  onIntersection={() => users.loadNext()}
                  marginTop={users.items.length > 0}
                  hidden={users.info.isDone}
                />
              </>
            )}
            {users.items.length == 0 && !users.info.isLoading && (
              <div className="companies-management-page__panel pa-2 mb-3 br-sm">{t("text:no_elements")}</div>
            )}
          </div>
          </div>
        </div>
      </PageContent>
    </>
  );
}

export default observer(EmployeePage);
