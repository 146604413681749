import React, { memo } from "react";
import { AIAssistantMessageItemProps } from "./AIAssistantMessageItem.interface";
import { IconButton, Text } from "../../../../../../uiKit";
import "./AIAssistantMessageItem.scss";
import { useTranslation } from "react-i18next";
import { AIAssistantLoader } from "../AIAssistantLoader/AIAssistantLoader";
import ReactMarkdown from 'react-markdown';
import { useCopyToClipboard } from "../../../../../../../hooks";
import { FiCopy } from "react-icons/fi";

export const AIAssistantMessageItem = memo((props: AIAssistantMessageItemProps) => {
  const [copyFn] = useCopyToClipboard({timeout: 0.7});

  const handleClickCopy = () => {
    copyFn(props.item.response ?? "")
  }

  return (
    <div className="ai-assistant-message">
      <div className="ai-assistant-message__request">
        {props.item.request || props.requestPlaceholder}
      </div>
      <div className="ai-assistant-message__response">
        {props.isReviewLoading && !props.item.response ? (
          <AIAssistantLoader />
        ) : (
          <>
            <ReactMarkdown>
              {props.item.response ?? ""}
            </ReactMarkdown>
            <IconButton
              icon={<FiCopy size={20}/>}
              onClick={handleClickCopy}
            />
          </>
        )}

      </div>
    </div>
  )
})
