import React from "react";
import { Icon, IconButton, Text } from "../../../../../uiKit";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import { useTranslation } from "react-i18next";
import { colorSchemeType } from "../../../../../../utils/colorScheme";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { Label } from "../../../../../uiKit/label/Label";

export const PlanningPageMyPlanMenu = (props: IPlanningPageMenu) => {
  const { t } = useTranslation();

  return (
    <div className="planning-page-menu d-stack-column">
      <div className="d-stack-row justify-space-between">
        <div className="d-stack-row align-center">
            <IconButton
              onClick={props.onSidebarOpenChange}
              children={<Icon component={() => <FiSidebar />} />}
              className="planning-sidebar-collapse-button mr-4"
            />
          <Text
            weight="bold"
            className="planning-page-main-content__title mr-6"
            children={t("ui:subheader.week_plan")}
          />
          <AutocompleteAsync
            style={{ width: "295px" }}
            type="plan"
            value={props.currentPlanId}
            onChange={props.onPlanIdChange}
            requestOptions={{ userId: props.userId, orderBy: "date_created", orderByType: "desc" }}
            showSearch={false}
            isNewDesign
            isNoBorder
            isBigSize
          />
        </div>
        { !!props.plan?.statusName && !!props.plan?.plannedIssues?.length && (
          <Label
            type={props.plan?.statusColorScheme as colorSchemeType}
            text={t(`ui:plan_status.type${props.plan?.status}`)}
          />
        )}
      </div>
    </div>
  );
};
