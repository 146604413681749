import React from "react";
import "./AIAssistantLoader.scss";

export const AIAssistantLoader = () => (
  <div className="ai-assistant-loader">
    <span className="ai-assistant-loader__dot"></span>
    <span className="ai-assistant-loader__dot"></span>
    <span className="ai-assistant-loader__dot"></span>
  </div>
)
