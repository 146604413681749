import { useNotifier, useRootStore } from "../../../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  RegulationContentQuestionDto,
  RegulationDto,
  SectionDto,
  UserRegulationStatsDto,
  type UserShortDto
} from "../../../../../api";
import { errorKeys } from "../../../forms/createRegulatiomForm/consts";
import { resetIds, validateTest, validateTestStatus } from "../../../forms/createTestsForm/helpers";
import { UserSelectionRule } from "../../../../../api/types/userSelectionRule";
import { initialEditorContent } from "../../../../../utils/textEditor";
import { api } from "../../../../../services";
import { GlobalAuthorizedContext } from "../../../../../contexts/globalAuthorizedContext";
import { useTranslation } from "react-i18next";
import { findId } from "../../../../../helpers/pathname";
import { HomeIcon } from "../../../../../constants/icon";
import { NavigationMenuItemDto } from "../../../../../api/models/NavigationMenuDto";
import { NO_ACCESS_TO_RESOURCE } from "../../../../../constants/ApiErrorCode";
import { AIAssistantType } from "../../../../../types/AIAssistantType.interface";

export const useRegulationHooks = () => {
  const { t } = useTranslation();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const { regulationStore } = useRootStore();


  const notifier = useNotifier();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

  const [regulationTitle, setRegulationTitle] = useState<string>("");
  const [titleError, setTitleError] = useState<boolean>(false);

  const [isOpenAccessSettingsDialog, setIsOpenAccessSettingsDialog] = useState<boolean>(false);


  const editorRef = useRef<any>();
  const testRef = useRef<{ test: RegulationContentQuestionDto[] }>({ test: [] });

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false); // confirm creating regulation without test;


  const handleOpenConfirmDialog = useCallback(() => setIsOpenConfirmDialog(true), []);


  const [isShowAIContent, setIsShowAIContent] = useState<boolean>(false);
  const handleCloseAIAssistant = useCallback(() => {
    setIsShowAIContent(false);
  }, []);

  const [assistantType, setAssistantType] = useState<AIAssistantType>(AIAssistantType.VISION);
  const handleChangeAssistantType = (type: AIAssistantType) => {
    setAssistantType(type);
    setIsShowAIContent(true);
    // handleSave(false, true);
  };


  const handleValidate = (ignoreTest: boolean | undefined, ignoreValidationContent?: boolean): void => {
    if (regulationTitle.trim().length === 0) {
      setTitleError(true);
      throw Error(errorKeys.emptyTitle);
    }
    if (!isShowAIContent && !ignoreValidationContent) {
      // if (contentIsEmpty(regulationContent)) throw Error(errorKeys.emptyContent);
      if (editorRef.current.isEmptyEditor()) throw Error(errorKeys.emptyContent);
      // if(isOpenCreatingTest && !ignoreTest) throw Error(errorKeys.noCompleteTest);
      if (testRef.current?.test.length > 0) validateTest(testRef.current.test);
    }
  };
  const [userSelectionRules, setUserSelectionRules] = useState<UserSelectionRule[]>([]);
  const [regulationContent, setRegulationContent] = useState<string>(initialEditorContent);
  const validateErrorHandler = (errorMessage: string) => {
    setIsLoading(false);
    switch (errorMessage) {
      case errorKeys.noCompleteTest:
        handleOpenConfirmDialog();
        break;
      case errorKeys.emptyTitle:
        notifier.show({ message: t("validation:enter_regulation_name"), theme: "error" });
        break;
      case errorKeys.emptyContent:
        notifier.show({ message: t("validation:policy_content_is_empty"), theme: "error" });
        break;
      case errorKeys.noAnswers:
        notifier.show({ message: t("validation:no_answers"), theme: "error" });
        break;
      case errorKeys.noRightsAnswer:
        notifier.show({ message: t("validation:no_rights_answer"), theme: "error" });
        break;
      case errorKeys.noNameAnswer:
        notifier.show({ message: t("validation:no_name_answer"), theme: "error" });
        break;
      case errorKeys.noNameQuestion:
        notifier.show({ message: t("validation:no_name_question"), theme: "error" });
        break;
    }
  };

  const generationKeys = (id: number, parentKey?: string) => {
    return parentKey ? `${parentKey}-s${id}` : `s${id}`;
  }

  const handleSave = (ignoreTest?: boolean, ignoreValidationContent?: boolean) => {
    try {
      handleValidate(ignoreTest, ignoreValidationContent);
    } catch (error: any) {
      validateErrorHandler(error.message as string);
      return;
    }


    const validTest = !isShowAIContent && !ignoreValidationContent
      ? true :
      validateTestStatus(testRef.current.test);

    try {
      setIsLoadingButton(true);
      const parentId = regulationStore.getParentId;
      const regulationGenerated: RegulationDto = {
        name: regulationTitle,
        userSelectionRules: userSelectionRules,
        draftContent: {
          content: regulationContent,
          questions: testRef.current && validTest ? resetIds(testRef.current.test) : undefined,
        },
      };
      api.regulation
        .create(regulationGenerated)
        .then(async(res) => {
          if (parentId && res) {
            const resp = parentId !== -1
              ? await api.navigationMenu.addRegulationToSection({id: parentId, regulationId: res.id!})
              : {};
            if (!isShowAIContent && !ignoreValidationContent) {
              notifier.show({ message: t("notifier:success.regulation_created"), theme: "success" });
              localStorage.removeItem("create-regulation-editor");
            }

              const uri = resp && parentId !== -1
                ? `/policy/topics/${parentId}/policy/${resp.id}`
                : `/policy/all/${res?.id}`
            navigate(uri, { state: isShowAIContent || ignoreValidationContent ? { regulation: res } : undefined});
              globalAuthorizedContext?.regulation?.reloadSidebarData?.(parentId);
              globalAuthorizedContext?.regulation?.reloadSidebarData?.(res.id, true, parentId);

          }
          localStorage.removeItem("create-regulation-editor");

        })
        .catch(() => {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        })
        .finally(() => {
          // globalAuthorizedContext?.regulation?.reloadSidebarData?.(parentId);
          setIsLoadingButton(false);

        });
    } catch (error: any) {
      validateErrorHandler(error.message as string);
    }
  };

  const { pathname } = useLocation();

  const [navigationMenuItem, setNavigationMenuItem] = useState<NavigationMenuItemDto>();

  const breadcrumbsList = useMemo(() => {
    if (!navigationMenuItem) return [{
      name: (<HomeIcon/>),
      id: -1,
      path: "all",
    },];
    const isCreate = pathname.includes('/policy/create-policy');

    const { id: navigationMenuItemId, regulation = {} } = navigationMenuItem;
    const { name: navigationMenuItemName } = regulation;

    const breadcrumbsList = (navigationMenuItem.breadcrumbs ?? []).length
      ? (navigationMenuItem.breadcrumbs ?? []).map((item) => {
        const { name, id } = item;
        return {
          name: navigationMenuItemId === id && !isCreate ? navigationMenuItemName : name,
          id: navigationMenuItemId === id && !isCreate  ? -2 : id,
          path: navigationMenuItemId === id && !isCreate  ? '' : `topics/${item.id}`,
        }
      })
      : [{
        name: navigationMenuItem.name!,
        id: navigationMenuItem.id!,
        path: `topics/${navigationMenuItem.id!}`,
      }];

    return [
      {
        name: (<HomeIcon/>),
        id: -1,
        path: "all",
      },
      ...breadcrumbsList,
    ]
  }, [navigationMenuItem?.breadcrumbs]);

  const handleError = (err: any) => {
    const { data: { apiError, data } } =  err.response;
    if (apiError === NO_ACCESS_TO_RESOURCE) {
      const [user] = data.ResponsibleUsers ?? [];
      const [item] = data.Items ?? [];
      const noAccessData = {
        user: user as UserShortDto,
        title: item?.name ?? "",
        link: window.location.href,
      }
      regulationStore.setNoAccessData(noAccessData);
      navigate(`/policy/all/`);
    } else {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    }
  }

  const getRegulationData = async (id:number, isAll: boolean, regulation?: RegulationDto) => {
    if (regulation) {
      return regulation;
    }
    return isAll
      ? await api.regulation.getById(id, undefined, undefined, handleError)
      : await api.navigationMenu.getItemById(id, handleError);
  }

  const fetchNavigationMenuItem = async (regulation?: RegulationDto) => {
    setIsLoading(!regulation);
    const isCreate = pathname.includes('/policy/create-policy');
    const pathIndex = isCreate ? 3 : 1;
    const id = findId(pathname, pathIndex);

    const isAll = pathname.includes("/all/");
    if (id) {
      const res = await getRegulationData(id, isAll, regulation)
      setIsLoading(false);
      if (res) {
        const { id, name, regulationId } = res;
        const navigationMenuItem = isAll
          ? {
            id,
            name,
            order: 0,
            breadcrumbs: [],
            isFavorite: false,
            regulation: {
              ...res,
              withoutATopic: !res.breadcrumbs?.length
            },
            regulationId: id,
          }
          : res;
        const item = navigationMenuItem as NavigationMenuItemDto;
        const parentId = isCreate ? id : regulationId ?? id;

        setNavigationMenuItem(item);
        if (regulationId && regulationStore.getParentId !== parentId) {
          regulationStore.setParentId(parentId!);
        }
        if (regulation) {
          regulationStore.setRegulationIsEditable(true);
          navigate(pathname, { replace: true, state: null });

        }
      }
    }
  };


  return {
    editorRef,
    testRef,
    isLoading,
    isLoadingButton,
    handleSave,
    globalAuthorizedContext,
    regulationTitle,
    setRegulationTitle,
    handleValidate,
    validateErrorHandler,
    setIsLoading,
    setUserSelectionRules,
    setIsOpenConfirmDialog,
    isOpenConfirmDialog,
    titleError,
    setTitleError,
    isOpenAccessSettingsDialog,
    setIsOpenAccessSettingsDialog,
    regulationContent,
    setRegulationContent,
    breadcrumbsList,
    navigationMenuItem,
    fetchNavigationMenuItem,
    isShowAIContent,
    handleCloseAIAssistant,
    assistantType,
    handleChangeAssistantType,
  }
}
