import { PagingOptions, RegulationDto, SectionDto } from "../../../../../../api";
import { regulationContentType } from "../../misc/headerTools";
import { RegulationFilter } from "../../../../../../api/filters/regulationFilter";
import { NavigationMenuItemDto } from "../../../../../../api/models/NavigationMenuDto";
import { SortOrder } from "antd/lib/table/interface";

export enum paginationTypeKeys {
  byButton = "by-button",
  byScroll = "by-scroll",
}

export type paginationType = paginationTypeKeys.byScroll | paginationTypeKeys.byButton;

export type sortedType = {
  orderBy?: string;
  order?: SortOrder;
}

export interface IListRegulationCards {
  regulations: NavigationMenuItemDto[];
  loadNext?: () => void;
  isDone?: boolean;
  isLoading?: boolean;
  paginationType?: paginationType;
  regulationContentType: regulationContentType;
  isShowFavorites?: boolean;
  onChangeSort?: (sort: RegulationFilter & PagingOptions) => void;
  sorted?: sortedType
}

export interface IListSectionCards {
  sections: SectionDto[];
  loadNext: () => void;
  isDone?: boolean;
  isLoading?: boolean;
  paginationType: paginationType;
}
export interface ICommonCardsList {
  regulationContentType: regulationContentType;
  regulations: NavigationMenuItemDto[];
  sections: SectionDto[];
  paginationType: paginationType;
  loadNextRegulations: () => void;
  loadNextSections: () => void;
  isLoading?: boolean;
  isDoneRegulation: boolean;
  isDoneSection: boolean;
}
