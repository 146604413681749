import { getColor } from "../../../../../../utils/hex2rgb";
import { Tag } from "../../../../../uiKit";
import React, { memo } from "react";
import { TagDto } from "../../../../../../api/models/TagDto";
import { useTagsColor } from "../../../../../../hooks/useTagColor";
import { ICardIssueTag } from "./CardIssueTag.interface";

export const CardIssueTag = memo((props: ICardIssueTag) => {
  const {
    borderColor,
    background,
    color,
  } = useTagsColor(props.tag.color ?? "gray");

  return (
    <Tag
      className="d-flex align-center justify-center"
      style={{
        height: "24px",
        borderColor,
        background,
        color,
      }}
      color={color}
      bordered={false}
      // onClose={handleRemoveTag}
    >
      {props.tag.name}
    </Tag>
  )
});
