import React, { memo, useMemo } from "react";
import { IIssueActivityTag } from "./IssueActivityTag.interface";
import { EntityAuditState } from "../../../../../../../../api/models/IssueActivityDto";
import { Tag } from "../../../../../../../uiKit";
import { getColor } from "../../../../../../../../utils/hex2rgb";

export const IssueActivityTag = memo((props: IIssueActivityTag) => {

  const isDeleted = props.state === EntityAuditState.Removed;
  return props.tag ? (
    <Tag
      style={{
        height: 24,
        lineHeight: "24px",
        borderColor: getColor(props.tag?.color, 0.4),
        background: getColor(props.tag?.color, 0.05),
        color: getColor(props.tag?.color),
        textDecoration: isDeleted ? "line-through" : "none",
      }}
      color={props.tag?.color}
      bordered={false}
    >
      {props.tag.name}
    </Tag>
  ) : null
});
