import React, { useEffect, useMemo, useState } from "react";
import LeftAppDrawerView from "./LeftAppDrawerView";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { removeTrailingSlash } from "../../../../helpers/pathname";
import { toJS } from "mobx";
import { AppMainMenuLinks, AppMenuLinkType } from "../../../../utils/appLinksAndTabs";

interface ILeftAppDrawer {
  pageKey?: string;
}


function LeftAppDrawer(props: ILeftAppDrawer) {
  const { authStore, appStore, helperStore } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const allLinks: AppMenuLinkType[] = AppMainMenuLinks;
  // TODO: Вынести в стор ключи с возможностью замены по key компонента Page в RouterRoute
  const pathnamesToCollapseDrawer: string[] = ["driver", "orgchart", "communication", "schedule"];
  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  useEffect(() => {
    const toCollapse = pathnamesToCollapseDrawer.some((path) =>
      removeTrailingSlash(window.location.pathname).includes(path)
    );
    setSelectedMenuItem(
      AppMainMenuLinks.filter((x) => x.link).find((l) => window.location.pathname.includes(l.link))?.key ?? ""
    );
    setTimeout(() => {
      if (appStore.getDrawerBehavior == "collapseAutomatically") {
        appStore.setLeftDrawerState(toCollapse ? "__collapsed" : "__expanded");
      }
    });
  }, [location]);

  useEffect(() => {
    if (appStore.getDrawerBehavior == "expandManually") {
      appStore.setLeftDrawerState("__collapsed");
    }
  }, [appStore.getDrawerBehavior]);

  const handleMenuItemClick = (
    key: string,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    isDisabled: boolean,
    isCollapsibleElementClicked: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const link = allLinks.find((l) => l.key == key)?.link;
    if (link == null || link.length == 0 || isDisabled || isCollapsibleElementClicked) return;
    if (e.button == 1 || e.buttons == 4) {
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };

  const menuLinks = useMemo(() => {
    return toJS(authStore.getInitialInfo?.menuItems)
      ?.slice()
      ?.sort((a, b) => (a.id! >= b.id! ? 1 : -1)) ?? [];
  }, [authStore.getInitialInfo?.menuItems]);

  return (
    <LeftAppDrawerView
      pageKey={props.pageKey}
      isCollapsed={appStore.getLeftDrawerState != "__expanded"}
      links={menuLinks}
      selectedMenuItem={selectedMenuItem}
      onMenuItemClick={handleMenuItemClick}
    />
  );
}

export default observer(LeftAppDrawer);
