export const carrotQuestLocalePl = {
  thanks: "Dziękuję",
  dialogs_history: "Historia czatów",
  powered_by_text_before_logo: "Działa&nbsp;na",
  powered_by_text_after_logo: "",
  enter_message: "Wpisz wiadomość…",
  enter_email: "Wpisz e-mail",
  enter_phone: "Wpisz numer telefonu",
  leave_email: "Możesz podać swój e-mail i kontynuować rozmowę przez pocztę:",
  page_title_new_message: "Nowa wiadomość",
  privacy_policy: "Polityka prywatności",
  errorFileType:
    "Nieprawidłowy format pliku. Możesz przesłać pliki: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Plik jest za duży<br/>Maksymalny rozmiar pliku to 10 MB.",
  errorManyRequests: "Za dużo plików<br/>Przesyłasz pliki zbyt często. Proszę chwilę poczekać.",
  errorReplyTooBig: "Wiadomość nie może przekraczać 20 000 znaków.",
  errorUserBan: "Zostałeś zablokowany<br/>Nie możesz już wysyłać nowych wiadomości na czacie.",
  error: "Coś poszło nie tak.<br/>Wystąpił błąd podczas przesyłania. Spróbuj ponownie.",
  close: "Zamknij",
  button_start_message: "Napisz",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Rozpocznij lub kontynuuj rozmowę na {{networkName}}",
  social_network_page_desc_vk: [
    "Otwórz VK za pomocą przycisku poniżej, aby przejść do czatu",
    "Zadaj nam pytanie",
  ],
  social_network_page_desc_viber: [
    "Otwórz Viber za pomocą przycisku poniżej, aby przejść do czatu",
    "Zadaj nam pytanie",
  ],
  social_network_page_desc_telegram: [
    "Otwórz Telegram za pomocą przycisku poniżej, aby przejść do czatu",
    "Naciśnij przycisk 'Start' w Telegramie",
    "Zadaj nam pytanie",
  ],
  social_network_page_desc_facebook: [
    "Otwórz Facebook za pomocą przycisku poniżej, aby przejść do czatu",
    "Naciśnij przycisk 'Rozpocznij' na Facebooku",
    "Zadaj nam pytanie",
  ],
  social_network_page_desc_instagram: [
    "Otwórz Instagram za pomocą poniższego przycisku, aby przejść do odpowiedniej strony",
    "Kliknij przycisk 'Wyślij wiadomość' na Instagramie",
    "Zadaj nam swoje pytanie",
  ],
  social_network_page_desc_whatsapp: [
    "Otwórz WhatsApp za pomocą poniższego przycisku, aby przejść do rozmowy",
    "Wyślij wstępnie wypełnioną wiadomość",
    "Zadaj nam swoje pytanie",
  ],
  social_network_page_open_button: "Otwórz {{networkName}}",
  social_network_qr_code_title: "Zeskanuj ten kod QR, aby otworzyć {{networkName}} na innym urządzeniu",
  bumperText:
    'Używamy plików cookies w celu poprawy wydajności strony, analizy ruchu oraz personalizacji treści. Korzystając z tej strony lub klikając "Zgadzam się", akceptujesz naszą politykę prywatności i cookies zgodnie z <a href="{{privacy-policy-link}}" target="_blank">Polityką prywatności</a>.',
  bumperButton: "Zgadzam się",
  confirmSubscriptionWithCheckbox:
    'Zgadzam się na <a href="{{subscription-policy}}" target="_blank">przetwarzanie moich danych</a>',
  confirmSubscriptionWithoutCheckbox:
    'Klikając przycisk, zgadzasz się na <a href="{{subscription-policy}}" target="_blank">przetwarzanie danych</a>',
  appeal: "Ty",
  vote_comment: "Co się stało?",
  vote_text: "Oceń jakość obsługi operatora",
  vote_thanks: "Dziękujemy za ocenę!",
  cart_button: "Więcej informacji",
  cart_no_name: "Bez nazwy",
  cart_zero: "Dane koszyka nie zostały załadowane",
  article_link: "Przeczytaj artykuł",
  to_knowledge_base: "Znajdź odpowiedź w bazie wiedzy",
  typing_status: "Pisze…",
  notification_enter_message: "Wpisz wiadomość…",
  notification_message_sending: "Wysyłanie wiadomości…",
  notification_message_sent: "Wiadomość wysłana",
  notification_file_sending: "Ładowanie pliku…",
  notification_file_sent: "Plik wysłany",
  notification_typing_status: "pisze",
  history_zero_data_title: "Nie mieliśmy jeszcze rozmowy",
  history_zero_data_desc: "Tutaj pojawi się historia twoich rozmów",
  history_group_collapse: "Zwiń",
  history_section_actual: "Ostatnie",
  history_section_archive: "Historia",
  back_to_chat: "Wróć do czatu",
  integration_zoom_button_text: "Dołącz",
  integration_zoom_tip: "☝️ Wskazówka: Możesz dołączyć do wideokonferencji bezpośrednio z przeglądarki",
  dateMonth: {
    "0": "stycznia",
    "1": "lutego",
    "2": "marca",
    "3": "kwietnia",
    "4": "maja",
    "5": "czerwca",
    "6": "lipca",
    "7": "sierpnia",
    "8": "września",
    "9": "października",
    "10": "listopada",
    "11": "grudnia",
  },
  fileSize: {
    "0": "B",
    "1": "KB",
    "2": "MB",
    "3": "GB",
  },
  at: "o",
  edited: "Edytowano",
  removed: "Usunięto",
  removedMessage: "Wiadomość usunięta",
  bot_answer_placeholder: "Wpisz odpowiedź",
  bot_buttons_placeholder: "Wybierz opcję odpowiedzi",
  connection_problem_toast: "<b>Brak połączenia z czatem.</b> Spróbujemy automatycznie ponownie połączyć.",
  input_error_email: "Wprowadź poprawny adres e-mail",
  input_error_phone: "Wprowadź poprawny numer telefonu",
  popup_close_all_button: "Ukryj wszystko",
  offline: "offline",
  online: "online",
  whatsapp_auth_text:
    "Wyślij tę wiadomość do nas, aby przenieść swój identyfikator czatu internetowego: {{userId}} do WhatsApp. Jeśli edytujesz lub usuniesz tę wiadomość, utworzysz nową rozmowę i skontaktujesz się z innym operatorem.",
  popup_desc: "Pop-up",
  image_desc: "Obraz",
  emoji_search_placeholder: "Szukaj",
  emoji_zero_data: "Nic nie znaleziono",
  emoji_category_people: "Emotikony i ludzie",
  emoji_category_nature: "Zwierzęta i natura",
  emoji_category_food: "Jedzenie i napoje",
  emoji_category_activity: "Aktywność",
  emoji_category_places: "Podróże i miejsca",
  emoji_category_objects: "Przedmioty",
  emoji_category_symbols: "Symbole",
  emoji_category_flags: "Flagi",
};

export const carrotAdditionalSettingsPl = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Jesteśmy tutaj i gotowi do pomocy",
  messenger_offline_message: "Operator połączy się specjalnie dla Ciebie.",
  messenger_welcome_message:
    "Dzień dobry! Skontaktowałeś się z pomocą techniczną Business Booster Platform. Proszę opisz swój problem, a odpowiemy najszybciej, jak to możliwe. Dziękujemy za kontakt!",
};
