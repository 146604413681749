import React, { memo, useState } from "react";
import { IPlanningPageMetric } from "./PlanningPageMetric.interface";
import { Collapse, CollapsePanel, Icon, Text } from "../../../../../uiKit";
import { LuTrendingUp } from "react-icons/lu";
import "./PlanningPageMetric.scss";
import { PlanningPageMetricContent } from "../planningPageMetricContent/PlanningPageMetricContent";
import { useTranslation } from "react-i18next";

export const PlanningPageMetric = memo((props: IPlanningPageMetric) => {
  const { t } = useTranslation();

  const [activeKey, setActiveKey] = useState<string[]>([]);

  const onChange = (keys:any ) => {
    setActiveKey(keys); // обновляем состояние при изменении активной панели
  };

  return (
      <Collapse
        className={"planning-page-metric"}
        expandIconPosition="end"
        activeKey={activeKey}
        isNewDesign
        key={1}
        onChange={onChange}
      >
        <CollapsePanel
          key={1}
          header={
            <div className={"planning-page-metric-header d-stack-row spacing-2"}>
              <Icon>
                <LuTrendingUp />
              </Icon>
              <Text>
                {activeKey.length > 0 ? t("parse:collapse_metrics") : t("parse:show_metrics")}
              </Text>
            </div>
          }
          className="collapse-panel"
        >
          <PlanningPageMetricContent
            userId={props.userId}
          />
        </CollapsePanel>
      </Collapse>
  )
});
