import React from "react";
import { useRootStore } from "../../../../../hooks";
import ruContent from "../../../../../utils/guides-lexical/permission/ru.json";
import enContent from "../../../../../utils/guides-lexical/permission/en.json";
import ukContent from "../../../../../utils/guides-lexical/permission/uk.json";
import zhContent from "../../../../../utils/guides-lexical/permission/zh.json";

import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";

export const getContent = (localeKey: string | null): any | null => {
  if (localeKey?.includes("uz")) {
    return ruContent;
  }

  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ukContent;
    case "zh":
      return zhContent;
    default:
      return enContent;
  }
};

export const PermissionsGuide = () => {
  const { hintStore } = useRootStore();
  console.log("PermissionsGuide");
  //const anchorId = hintStore.getAnchorId;

  // const test1 = window.getEl

  // const anchor1Ref = useRef(null);
  // const anchor2Ref = useRef(null);
  // const anchor3Ref = useRef(null);
  // const anchor4Ref = useRef(null);
  // const anchor5Ref = useRef(null);
  // const anchor6Ref = useRef(null);
  //
  // const anchorsNodes: IAnchorNodes[] = [
  //   {id: 1, anchor: anchor1Ref},
  //   {id: 2, anchor: anchor2Ref},
  //   {id: 3, anchor: anchor3Ref},
  //   {id: 4, anchor: anchor4Ref},
  //   {id: 5, anchor: anchor5Ref},
  //   {id: 6, anchor: anchor6Ref},
  // ];

  // useEffect(() => {
  //   console.log(anchorId);
  //   if (anchorId) guideParser.scrollTo(anchorId);
  // }, [anchorId]);
  //
  // const guideParser = new GuideParser(
  //     getGuideContent(appStore.getAppLocale,guideKeys.permissions),
  //     anchorsNodes
  // );

  return (
    // <TextEditorOld readOnly={true} id="guide-dasboard" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
    <TextEditorLexical
      id={"PermissionsGuide"}
      readOnly
      value={getContent(hintStore.getLang)}
      initialValue={getContent(hintStore.getLang)}
      withoutBackground
      // variant={"new-style"}
    />
  );
};
