import React, { memo, useCallback, useContext, useMemo } from "react";
import { Collapse, CollapsePanel } from "../../../../uiKit";
import { FunctionCardItem } from "../functionCardItem/FunctionCardItem";
import { useTranslation } from "react-i18next";
import "./FunctionCardCollapsePanel.scss";
import { AccessControlDialogContext } from "../AccessDialogContext";
import {
  NavigationMenuItemClaimGroupDto,
  NavigationMenuItemUserSelectionRuleClaimDto
} from "../../../../../api/models/NavigationMenuItemClaimGroupDto";
import { MenuItemClaimType } from "../../../../../api/models/NavigationMenuDto";
import { IFunctionCardCollapsePanelProps } from "./IFunctionCardCollapsePanel.interface";
import { AccessControlCollapse } from "../../../../../constants/AccessControlCollapse";

export const FunctionCardCollapsePanel = memo((props: IFunctionCardCollapsePanelProps) => {
  const { t } = useTranslation();
  const { claimGroup, handleDeleteClaim } = useContext(AccessControlDialogContext);

  const group = useMemo(() => {

    const obj: NavigationMenuItemClaimGroupDto = {};

    AccessControlCollapse.forEach((key) => {
      const selectedRolesList = claimGroup[key] ?? [];
      obj[key] = selectedRolesList.filter((item) =>
        item.role.name?.includes(props.search) &&
        item.orgchartId === props.orgchartId
      );
    });
    return obj;
  }, [claimGroup, props.search,  props.orgchartId]);


  return (
    <Collapse ghost className="d-stack-column spacing-2 regulation-collapse">
      {Object.entries(group).map(([claimType, selectedRolesList]) => {
        return (
          <CollapsePanel
            key={`collapse-panel-${claimType}`}
            header={`${t(`ui:title.regulation_access_dialog_collapse.${claimType}`)} (${selectedRolesList.length})`}
            className="regulation-collapse-panel"
          >
            {
              selectedRolesList.map((item) => (
                <FunctionCardItem
                  key={`${claimType}-${item.id ?? item.roleId}`}
                  item={item}
                  onDelete={handleDeleteClaim}
                />
            ))}
          </CollapsePanel>
        )
      })}
    </Collapse>
  )
});
