import React, { useEffect, useRef, useState } from "react";
import {
  MetricGraphDto,
  MetricGraphPeriodDto,
  MetricReadDto,
  MetricValueDto, RolePositionShortDto,
  RoleShortDto,
  UserDto, UserShortDto
} from "../../../../api";
import { useNotifier } from "../../../../hooks";
import { api } from "../../../../services";
import { useTranslation } from "react-i18next";
import { MetricDataDialogView } from "./MetricDataDialogView";
import { formatDateToDateString } from "../../../../helpers/formatFunctions";
import { removeDuplicatesFromArrayByKey } from "../../../../helpers/arrayFunctions";
import dayjs from "dayjs";

interface IMetricDataDialog {
  isOpen?: boolean;
  metric: MetricReadDto;
  metricSource2UserId?: number;
  // onSuccess: () => void;
  onOpen: (isOpen: boolean) => void;
  onSave: (data: MetricReadDto) => void;
}

export interface IMetricDataData {
  metricSource2UserId?: number;
  title?: string;
  tableData: ITableDataEl[];
}

export interface IMetricPrintData {
  metricSource2UserId?: number;
  metricFillerRole?: RoleShortDto;
  metricFillerRolePosition?: RolePositionShortDto;
  metricFillerUsers?: UserShortDto[];
  tableData: IMetricDataData[];
}


export interface ITableDataEl {
  date: string;
  dateISO: string;
  quota: number | null;
  value: number | null;
  filler: UserDto | null;
  fill_date: string;
}

export function MetricDataDialog(props: IMetricDataDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [data, setData] = useState<IMetricDataData[]>([]);
  const [changedData, setChangedData] = useState<IMetricDataData[]>([]);
  const [loadingState, setLoadingState] = useState<{
    isLoading: boolean;
    isDone: boolean;
  }>({
    isLoading: false,
    isDone: false,
  });
  const [currentMetricSource2UserId, setCurrentMetricSource2UserId] = useState<number | null>(
    props.metricSource2UserId != null
      ? props.metricSource2UserId
      : props.metric?.graphs?.[0]?.metricSource2UserId ?? null
  );

  const metric = useRef<MetricReadDto | null>(null);
  const lastMetricPeriodDate = useRef<string | null>(null);

  const handleDialogOpen = async () => {
    setCurrentMetricSource2UserId(
      props.metricSource2UserId != null
        ? props.metricSource2UserId
        : props.metric?.graphs?.[0]?.metricSource2UserId ?? null
    );
    await loadData(true);
  };

  const handleDialogClose = () => {
    setData([]);
    setChangedData([]);
    setCurrentMetricSource2UserId(null);
    lastMetricPeriodDate.current = null;
    metric.current = null;
  };

  const loadData = async (initLoad?: boolean) => {
    // props.metric?.renderType == 1 -- 2 graphs
    // else 1 graph
    // metricSource2UserId
    // props.metricSource2UserId
    setLoadingState({ ...loadingState, isLoading: true });
    const r = await api.metricRead.getById(props.metric.id!, {
      includeMetricSource2UserIds:
        (props.metricSource2UserId != null
          ? [props.metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.metricSourceId]
          : props.metric?.graphs?.map((g) => g.metricSource2UserId)) ?? null,
      graphPeriodsAfter: initLoad ? 2 : 0,
      graphPeriodsBefore: 20,
      graphDateStart: initLoad || lastMetricPeriodDate.current == null ? undefined : lastMetricPeriodDate.current,
    });

    if (r == null) {
      setLoadingState({ ...loadingState, isLoading: false });
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }

    const requiredGraphs: MetricGraphDto[] =
      props.metric.renderType == 1
        ? r.graphs ?? []
        : r.graphs?.filter((g) => g.metricSource2UserId == props.metricSource2UserId) ?? [];

    const requiredGraphsPeriodsArray: MetricGraphPeriodDto[] = removeDuplicatesFromArrayByKey(
      (requiredGraphs?.map((g) => g.periods)?.flat() ?? []) as MetricGraphPeriodDto[],
      "label"
    )
      .filter((g: MetricGraphPeriodDto) => g.date != null)
      .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
        return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
      });
    // .slice(1);

    if (requiredGraphsPeriodsArray.length == 0) {
      // setLoadingState({ ...loadingState, isLoading: false, isDone: true });
      return;
    } else {
      lastMetricPeriodDate.current = requiredGraphsPeriodsArray[0].date ?? null;
    }

    if (initLoad) {
      metric.current = { ...r, graphs: requiredGraphs };
      setChangedData(
        (requiredGraphs ?? []).map((g) => ({ metricSource2UserId: g.metricSource2UserId!, tableData: [] })) ?? []
      );
    } else {
      const graphsOld: MetricGraphDto[] = metric.current?.graphs ?? [];
      const graphsNew: MetricGraphDto[] = requiredGraphs ?? [];

      const graphsMerged = [...graphsOld];

      graphsNew.forEach((newGraph) => {
        const existingGraph = graphsMerged.find(
          (oldGraph) => oldGraph.metricSource2UserId == newGraph.metricSource2UserId
        );

        if (existingGraph) {
          existingGraph.periods = (existingGraph.periods || []).concat(newGraph.periods || []);
          existingGraph.periods.sort((a, b) => {
            return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
          });
        } else {
          graphsMerged.push(newGraph);
        }
      });

      metric.current = {
        ...metric.current,
        graphs: graphsMerged.map((g) => ({ ...g, periods: removeDuplicatesFromArrayByKey(g.periods ?? [], "label") })),
      };
    }

    let nD: IMetricDataData[] = [];
    metric.current?.graphs?.forEach((graph) => {
      if (props.metricSource2UserId != null && graph.metricSource2UserId != props.metricSource2UserId) return;
      const newData = generateTableData(graph.metricSource2UserId!);
      if (nD.some((n) => n.metricSource2UserId == graph.metricSource2UserId)) {
        nD = [...nD.map((oldData) => (oldData.metricSource2UserId == newData.metricSource2UserId ? newData : oldData))];
      } else {
        nD = [...nD, newData];
      }
      // nD =
      //   nD.length == 0 ? [newData] : [...nD.map((oldData) => (oldData.graphId == newData.graphId ? newData : oldData))];
      // setData((data) =>
      //   data.length == 0
      //     ? [newData]
      //     : [...data.map((oldData) => (oldData.graphId == newData.graphId ? newData : oldData))]
      // );
    });
    setData(nD);

    setLoadingState({ ...loadingState, isLoading: false });
  };

  const handleCurrentMetricSource2UserIdChange = (value: number | null) => {
    setCurrentMetricSource2UserId(value);
  };

  const handleLoadMore = async () => {
    await loadData();
  };

  const generateTableData = (metricSource2UserId: number): IMetricDataData => {
    const graphPeriodsArray: MetricGraphPeriodDto[] =
      metric.current?.graphs
        ?.find((g) => g.metricSource2UserId == metricSource2UserId)
        ?.periods?.filter((g: MetricGraphPeriodDto) => g.date != null)
        .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
          return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
        }) ?? [];

    const getFillDate = (period?: MetricGraphPeriodDto | null): string => {
      const _date = period?.value?.dateCreated ?? period?.quota?.dateCreated;
      return _date != null ? formatDateToDateString(new Date(_date)) : "";
    };

    const getFiller = (period?: MetricGraphPeriodDto | null): UserDto | null => {
      return period?.value?.user ?? period?.quota?.user ?? null;
    };

    return {
      tableData: graphPeriodsArray
        .map((gP) => ({
          key: gP.label ?? "",
          date: dayjs(gP.label).format("L").replaceAll(".", "/") ?? "",
          dateISO: gP.date ?? "",
          value:
            metric.current?.graphs
              ?.find((g) => g.metricSource2UserId == metricSource2UserId)
              ?.periods?.find((p) => p.label == gP.label)?.value?.value ?? null,
          quota:
            metric.current?.graphs
              ?.find((g) => g.metricSource2UserId == metricSource2UserId)
              ?.periods?.find((p) => p.label == gP.label)?.quota?.quota ?? null,
          fill_date: getFillDate(
            metric.current?.graphs
              ?.find((g) => g.metricSource2UserId == metricSource2UserId)
              ?.periods?.find((p) => p.label == gP.label)
          ),
          filler: getFiller(
            metric.current?.graphs
              ?.find((g) => g.metricSource2UserId == metricSource2UserId)
              ?.periods?.find((p) => p.label == gP.label)
          ),
        }))
        .sort(function (a: ITableDataEl, b: ITableDataEl) {
          return a.dateISO! < b.dateISO! ? 1 : a.dateISO! > b.dateISO! ? -1 : 0;
        }),
      // .reverse(),
      metricSource2UserId: metricSource2UserId,
    };
  };

  const handleDataChange = (
    record: ITableDataEl,
    value: string | number | null | undefined,
    type: "quota" | "value" | "reset"
  ) => {
    const valueToChange =
      changedData
        .find((d) => d.metricSource2UserId == currentMetricSource2UserId)
        ?.tableData?.find((tD) => tD.date == record.date) ??
      data
        .find((d) => d.metricSource2UserId == currentMetricSource2UserId)
        ?.tableData?.find((tD) => tD.date == record.date);
    if (valueToChange == null) return;
    if (type == "value") valueToChange.value = value == null ? null : Number(value);
    if (type == "quota") valueToChange.quota = value == null ? null : Number(value);
    if (type == "reset") {
      valueToChange.value = null;
      valueToChange.quota = null;
    }
    setChangedData((changedData) => {
      return changedData.map((cD) =>
        cD.metricSource2UserId == currentMetricSource2UserId
          ? {
              ...cD,
              tableData:
                cD.tableData.find((tD) => tD.date == valueToChange.date) == null
                  ? [...cD.tableData, valueToChange]
                  : cD.tableData.map((tD) => (tD.date == valueToChange.date ? { ...valueToChange } : tD)),
            }
          : cD
      );
    });
  };

  const handleSave = async () => {
    if (changedData.map((d) => d.tableData).flat(2).length == 0) {
      props.onOpen(false);
      return;
    }
    let arr2send: MetricValueDto[] = [];
    changedData.map((cD) => {
      return cD.tableData.map((tD) => {
        arr2send = [
          ...arr2send,
          {
            metricSource2UserId: cD.metricSource2UserId,
            date: tD.dateISO,
            value: tD.value,
            type: 1,
          },
          {
            metricSource2UserId: cD.metricSource2UserId,
            date: tD.dateISO,
            value: tD.quota,
            type: 2,
          },
        ];
      });
    });
    // let arr2send: MetricValueDto[] = changedData.map((cD) => cD.tableData.map((tD) => ({})));
    setLoadingState({ ...loadingState, isLoading: true });
    const r = await api.metric.updateValueOrQuota(arr2send);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      // TODO: Show error snackbar
      return;
    }
    setLoadingState({ ...loadingState, isLoading: false });
    props.onSave(metric.current as MetricReadDto);
  };

  useEffect(() => {
    props.isOpen && handleDialogOpen();
    !props.isOpen && handleDialogClose();
  }, [props.isOpen]);


  return (
    <MetricDataDialogView
      data={data}
      changedData={changedData}
      isOpen={props.isOpen}
      loadingState={loadingState}
      currentMetricSource2UserId={currentMetricSource2UserId}
      metric={metric.current}
      metricSource2UserId={props.metricSource2UserId}
      onOpen={props.onOpen}
      onCurrentMetricSource2UserIdChange={handleCurrentMetricSource2UserIdChange}
      onLoadMore={handleLoadMore}
      onDataChange={handleDataChange}
      onSave={handleSave}
    />
  );
}
