import React, { memo, useMemo } from "react";
import { IssueActivityItemProps } from "./IssueActivityItem.interface";
import { useTranslation } from "react-i18next";
import "./IssueActivityItem.scss";
import { IssueActivityUserRow } from "../issueActivityUserRow/issueActivityUserRow";
import { IssueActivityTextRow } from "../issueActivityTextRow/IssueActivityTextRow";
import { Text } from "../../../../../../../uiKit";
import { UserCard } from "../../../../../../../elements/userCard/UserCard";
import dayjs from "dayjs";
import { useDateHelpers, useRootStore } from "../../../../../../../../hooks";
import { IssueActivityBooleanRow } from "../issueActivityBooleanRow/issueActivityBooleanRow";
import { IssueActivityObserver } from "../issueActivityObserver/IssueActivityObserver";
import { IssueActivityTag } from "../issueActivityTag/IssueActivityTag";
import clsx from "clsx";
import { IssueActivityStatusRow } from "../issueActivityStatusRow/IssueActivityStatusRow";
import { IssueActivityComment } from "../issueActivityComment/issueActivityComment";
import { IssueActivityCreate } from "../issueActivityCreate/IssueActivityCreate";
import { IssueActivityActions } from "../issueActivityActions/IssueActivityActions";
import { IssueActivityPriorityRow } from "../issueActivityPriorityRow/IssueActivityPriorityRow";
import { IssueActivityFile } from "../issueActivityFile/issueActivityFile";

export const IssueActivityItem = memo((props: IssueActivityItemProps) => {

  const { formatDate } = useDateHelpers();
  const { authStore } = useRootStore();

  const user = useMemo(() => {
    return authStore.getInitialInfo?.identity ?? undefined
  }, [authStore.getInitialInfo?.identity]);

  const { t } = useTranslation();

  const dateFormat = useMemo(() => {
    const date = new Date(props.item.dateCreated);
    const currentYear = new Date().getFullYear();
    return currentYear === date.getFullYear() ? 'DD MMM HH:mm' : 'DD MMM YYYY HH:mm';
  }, [props.item.dateCreated]);

  const dateCreated = useMemo(() => {
    return dateFormat
      ? dayjs(props.item.dateCreated!)
        .tz(user?.timeZoneId ?? "local")
        .format(dateFormat)
      : formatDate(new Date(props.item.dateCreated!).toString(), {
        formatObject: { hour: undefined, minute: undefined },
      });
  }, [dateFormat, props.item.dateCreated]);

  const renderCommentContent = useMemo(() => {
    const { propertyName, dateCreated, previousValue, value, comment } = props.item;
    const createdDate = formatDate(dateCreated, {
      formatObject: { month: "short" },
    });
    const dateUpdated = comment?.dateUpdated ? formatDate(comment?.dateUpdated, {
      formatObject: { month: "short" },
    }) : undefined;
    if (propertyName || comment?.isDeleted) {
      if (propertyName === "IsDeleted"  || comment?.isDeleted) {
        const value = `${t("ui:text.deleted_comment")} ${createdDate}`
        return (
          <div className="issue-activity-item-content-text justify-end d-flex">
            <Text style={{
              fontSize: 12,
              color: "var(--color-text-second)"
            }}>
              { value }
            </Text>
          </div>
        )
      }
      return (
        <IssueActivityTextRow
          prevValue={previousValue}
          value={value}
        />
      )
    }
    return (
      <IssueActivityComment
        item={props.item}
        user={user}
        onEditComment={props.onEditComment}
        onIssueHistoryIdChange={props.onIssueHistoryIdChange}
        onDeleteComment={props.onDeleteComment}
        issueHistoryId={props.issueHistoryId}
        dateUpdated={dateUpdated}
      />
    )
  }, [props.issueHistoryId])

  const renderContent = useMemo(() => {
    console.log("props.item.propertyNam", props.item);
    switch (props.item.propertyName) {
      case "ExecutorUserId":
        return (
          <IssueActivityUserRow
            prevUser={props.item.previousExecutor}
            user={props.item.executor}
          />
        )
      case "InitiatorUserId":
        return (
          <IssueActivityUserRow
            prevUser={props.item.previousInitiator}
            user={props.item.initiator}
          />
        )
      case "Observers":
        return (
          <IssueActivityObserver
            user={props.item.observer}
            state={props.item.state!}
          />
        )
      case "Name":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
          />
        )
      case "Description":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
          />
        )
      case "ProofReqComment":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
          />
        )
      case "ProofReqCount":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
          />
        )
      case "ProofReqType":
        const { previousValue, value } = props.item;
        const prevValueText = previousValue ? t(`ui:label.proof_${previousValue.toLowerCase()}`) : undefined;
        const valueText = value ? t(`ui:label.proof_${value.toLowerCase()}`) : undefined;
        return (
          <IssueActivityTextRow
            prevValue={prevValueText}
            value={valueText}
            isInlineText
          />
        )
      case "TimePlan":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
            isTime
          />
        )
      case "TimeFact":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
            isTime
          />
        )
      case "TimePlanForApproval":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
            isTime
          />
        )
      case "TimeFactForApproval":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
            isTime
          />
        )
      case "AwaitedResult":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            isInlineText
          />
        )
      case "IsManagerApprovalRequired":
        return (
          <IssueActivityBooleanRow
            prevValue={props.item.previousValue}
            value={props.item.value}
          />
        )
      case "IsStrictDeadline":
        return (
          <IssueActivityBooleanRow
            prevValue={props.item.previousValue}
            value={props.item.value}
          />
        )
      case "DateDeadline":
        const prevDate = props.item.previousValue
          ? dayjs(props.item.previousValue).format("L LT")
          : undefined;
        const date = props.item.value
          ? dayjs(props.item.value).format("L LT")
          : undefined;
        return (
          <IssueActivityTextRow
            prevValue={prevDate}
            value={date}
            isInlineText
          />
        )
      case "DateWorkStart":
        const prevDateWorkStart = props.item.previousValue
          ? dayjs(props.item.previousValue).format("L LT")
          : undefined;
        const dateWorkStart = props.item.value
          ? dayjs(props.item.value).format("L LT")
          : undefined;
        return (
          <IssueActivityTextRow
            prevValue={prevDateWorkStart}
            value={dateWorkStart}
            isInlineText
          />
        )
      case "DateWorkStartForApproval":
        const prevDateWorkStartForApproval = props.item.previousValue
          ? dayjs(props.item.previousValue).format("L LT")
          : undefined;
        const dateWorkStartForApproval = props.item.value
          ? dayjs(props.item.value).format("L LT")
          : undefined;
        return (
          <IssueActivityTextRow
            prevValue={prevDateWorkStartForApproval}
            value={dateWorkStartForApproval}
            isInlineText
          />
        )
      case "Tags":
        return (
          <IssueActivityTag
            tag={props.item.tag}
            state={props.item.state!}
          />
        )
      case "StatusId":
        return (
          <IssueActivityStatusRow
            boardStatus={props.item.boardStatus!}
            previousBoardStatus={props.item.previousBoardStatus}
          />
        )
      case "RoleId":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousRole?.name ?? ""}
            value={props.item.role?.name ?? ""}
            isInlineText
          />
        )
      case "OrgchartId":
        return (
          <IssueActivityTextRow
            prevValue={props.item.previousOrgchart?.name ?? ""}
            value={props.item.orgchart?.name ?? ""}
            isInlineText
          />
        )
      case "Priority":
        return (
          <IssueActivityPriorityRow
            prevValue={props.item.previousValue}
            value={props.item.value}
            priority={props.item.priority}
            previousPriority={props.item.previousPriority}
          />
        );
      case "Files":
        return (
          <IssueActivityFile
            file={props.item.file!}
            state={props.item.state!}
          />
        );
      case "": {
        return (
          <IssueActivityCreate
            boardStatus={props.item.boardStatus!}
          />
        )
      }
    }
  }, []);

  const isShowTitle = useMemo(() => {
    if (props.item.entityType === "IssueComment") {
      // console.log('IssueComment', props.item);
    }
    return (props.item.entityType !== "IssueComment" || props.item.propertyName === "Text") && !!props.item.propertyName
  }, []);

  return (
    <div className="issue-activity-item">
      <div className="issue-activity-item__header">
        <UserCard
          userInfo={props.item.createdByUser}
          boldName
          avatarSize={32}
          nameSize={12}
          additionalInfo={dateCreated}
          isNewStyle
        />
         {!!props.item.comment && !props.item.comment.isDeleted && (
           <IssueActivityActions
             issueHistoryId={props.item.comment.issueHistoryId ?? 0}
             reactions={props.item.reactions ?? []}
             recipients={props.item.comment.recipients ?? []}
             userId={user?.id!}
           />
         )}
      </div>
      <div className="issue-activity-item-wrap">
        <div className={clsx("issue-activity-item-content", {
            "issue-activity-item-content_full": !isShowTitle
          })}>
          {isShowTitle && (
            <div className="issue-activity-item-content__title">
              <Text
                style={{
                  fontSize: 12,
                }}
              >
                {t(`ui:issue_activity.${props.item.propertyName}`)}
              </Text>

            </div>
          )}
          <div className="issue-activity-item-content__value">
            {props.item.entityType === "IssueComment" ? renderCommentContent : renderContent}

          </div>
        </div>
      </div>
    </div>
  )
});
