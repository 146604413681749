import React, { useEffect, useRef, useState, useMemo, useCallback, useContext } from "react";
import "./OrgchartFunctionCardHubbard.scss";
import OrgchartFunctionCardHubbard, { IOrgchartFunctionCardHubbard } from "./OrgchartFunctionCardHubbard";
import { useTranslation } from "react-i18next";
import { MenuItemBaseDto, OrgchartItemDto, RoleDto, RolePositionDto, User2RoleDto } from "../../../../../api";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { FiPlusSquare } from "@react-icons/all-files/fi/FiPlusSquare";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { FiShuffle } from "@react-icons/all-files/fi/FiShuffle";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { FiMove } from "@react-icons/all-files/fi/FiMove";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../../hooks";
import { hexToRgb } from "../../../../../helpers/colorFunctions";
import { Card, Dropdown, Icon, IconButton, Popover, Spin, Text } from "../../../../uiKit";
import { css } from "@emotion/css";
import { theme } from "antd";
import { LucideHelpCircle } from "lucide-react";
import { LinkItUrl } from "react-linkify-it";
import { PrintIcon } from "../../../../../constants/icon";
import { OrgchartFunctionCardHubbardContent } from "./OrgchartFunctionCardHubbardContent";
import { OrgchartFilterContext } from "../../../../../contexts/orgchartFilterContext";
import { ORGCHART_CLASSIC_TYPE } from "../../../../../constants/orgchartTypes";
import { orgchartHexToVariables } from "../../../../../utils/hexToVariables";

export interface IOrgchartFunctionCardHubbardView extends IOrgchartFunctionCardHubbard {
  cardMenu: MenuItemBaseDto[] | null;
  isCardMenuOpen: boolean;
  isCardMenuLoading: boolean;
  isBlockWide: boolean;
  zoom: number;
  isHiddenPrintBlock?: boolean;

  handleCardClick: () => void;
  handleMenuItemClick: (actionKey: string, id: number) => void;
  handleCardMenuOpen: (event: any) => void;
  handleCardMenuClose: () => void;
  handleCardMenuLoad: () => void;
  handleOrgchartBlockPrint?(id: number): void;
}

const getMenuItemIcon = (actionKey: string) => {
  switch (actionKey) {
    case "add_role":
      return <FiPlusSquare />;
    case "edit":
      return <FiEdit />;
    case "manage":
      return <FiSettings />;
    case "sort":
      return <FiShuffle />;
    case "transfer":
      return <FiMove />;
    case "delete":
      return <FiTrash2 />;
    case "print":
      return <Icon><PrintIcon size={12}/></Icon>;
  }
};

const getFunctionAdministrator = (users: User2RoleDto[], positions: RolePositionDto[]) => {
  const position = positions.find((pos) => pos.parentPositionId == null);
  if (position == null) return "";
  const user2return = users.find((u2r) => u2r.positionId == (position.id as number));
  if (user2return == null) return "";
  return (user2return.user?.name ?? "").trim().length > 0 ? user2return.user?.name : user2return.user?.nameFallback;
};

// Линия, выходящая вправо из корневого блока
const drawLineFromRootRight = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateX(100%);
        width: 24px;
        height: 1px;
        top: 24px;
        right: -4px;
      `}
    />
  );
};

// Линия, выходящая вниз из корневого блока
const drawLineFromRootBottom = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateY(100%);
        width: 1px;
        height: 20px;
        left: 50%;
        bottom: -4px;
      `}
    />
  );
};

// Линия, выходящая вверх из блока
const drawLineFromTop = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateY(-100%);
        width: 1px;
        height: 4px;
        left: 50%;
        top: -2px;
      `}
    />
  );
};

// Линия, выходящая влево из блока
const drawLineFromLeftToLeft = () => {
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateX(-100%);
        width: 4px;
        height: 1px;
        left: -4px;
        top: 24px;
      `}
    />
  );
};

// // Линия слева от блока в полную высоту
// const drawLineFromLeftFullHeight = (nestingLevel: string) => {
//   // nestingLevel: string ("5.w") - wide / tight
//   const [nestingLevelNumber, nestingLevelType] = nestingLevel.split(".");
//   if (typeof Number(nestingLevelNumber) !== "number" || nestingLevelType == null) return null;
//
//   return (
//     <div
//       className={css`
//         position: absolute;
//         pointer-events: none;
//         background-color: var(--color-layout-divider);
//         transform: translateX(-100%);
//         top: -6px;
//         width: 1px;
//         height: calc(100% + 10px);
//         left: ${"-" + (Number(nestingLevelNumber) * 16 + (nestingLevelType == "w" ? 8 : 0)) + "px"};
//       `}
//       // left: ${"-" + nestingLevel + "px"};
//     />
//   );
// };

// Линия слева от блока у последнего блока
const drawLineFromLeftLastChild = () => {
  // disabledNestingLevels: []
  return (
    <div
      className={css`
        position: absolute;
        pointer-events: none;
        background-color: var(--color-layout-divider);
        transform: translateX(-100%);
        top: -6px;
        left: -8px;
        width: 1px;
        height: 31px;
      `}
    />
    // left: ${"-" + (nestingLevel * 16 + 8) + "px"};
    // opacity: ${isLastChild && nestingLevel == 0 ? 0 : 1};
  );
};

function OrgchartFunctionCardHubbardView(props: IOrgchartFunctionCardHubbardView) {
  // const theme: Theme = useTheme();
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();
  // const wrapperRef = useRef(null);
  // useOutsideClick(wrapperRef, props.handleCardMenuClose);
  const { appStore } = useRootStore();
  const getRoleDataById = useCallback((roleId: number): RoleDto | null =>
    props.roles?.find((r) => r.id == roleId) ?? null,
    [props.roles]
  );
  const getSortedChildren = useCallback((childrenRoles: OrgchartItemDto[]): OrgchartItemDto[] => {
    return [
      ...childrenRoles.sort(
        (a, b) => (getRoleDataById(a.roleId as number)?.order ?? 0) - (getRoleDataById(b.roleId as number)?.order ?? 0)
      ),
    ];
  }, []);

  const getCardBackgroundColor = (hex: string | null | undefined): string => {
    if (hex == null || hex.trim().length == 0) return "var(--color-layout-fill-base)";
    if (appStore.getAppTheme == 1) {
      return "var(--color-srf-secondary)";
    } else {
      return orgchartHexToVariables(hex);
    }
  };

  const getCardBorder = (hex: string | null | undefined): string | undefined => {
    const color = !hex || hex.trim().length == 0
      ? "var(--color-layout-fill-base)"
      : orgchartHexToVariables(hex)
    return `4px solid ${color}`;
  };

  const getCardLines = () => {
    const p = props.params;
    const nestedLevelWidth = 32;

    if (p.isLastChild) {
      return null
    }

    return (
      <>
        {p.isRootRole && drawLineFromRootRight()}
        {p.isRootRole && drawLineFromRootBottom()}
        {!p.isVertical && p.hasParent && !p.isRootRoleChild && drawLineFromTop()}
        {p.isVertical && !p.isRootRole && !p.isRootRoleChild && drawLineFromLeftToLeft()}
        {/*{props.params.nestingLevels*/}
        {/*  .filter(function (item, pos) {*/}
        {/*    return props.params.nestingLevels.indexOf(item) == pos;*/}
        {/*  })*/}
        {/*  .filter((l) => l != "empty")*/}
        {/*  .map((l) => drawLineFromLeftFullHeight(l))}*/}
        {/*{Array.apply(null, Array(p.nestingLevel ?? 0))*/}
        {/*  .map((_, i) => i)*/}
        {/*  .map((i) => drawLineFromLeftFullHeight(i, p.isLastChild, p.nestingLevels))}*/}
        {/*{p.isVertical && p.isLastChild && drawLineFromLeftLastChild()}*/}
      </>
    );
  };


  const cardMenuContentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const wrapper = useRef<any>(null);

  function adjustLine(
    container: HTMLElement | null,
    lastChildContainer: HTMLElement | null,
    lastChildEl: HTMLElement | null,
    line: HTMLElement | null
  ) {
    if (container == null || line == null || lastChildEl === null) return;
    // console.log('container', container);
    // console.log(container.clientHeight);
    // console.log("container.getBoundingClientRect().height", container.getBoundingClientRect().height);
    const H = container.getBoundingClientRect().height
      - (lastChildContainer ?  lastChildContainer.getBoundingClientRect().height : 0)
      - lastChildEl.getBoundingClientRect().height;
    // const H = container.offsetHeight;
    line.style.height = H + 10 + "px";
  }

  const generateBlockId = (container = '') => {
    return `fc-sId:${props.blockData?.roleId}`;
  };

  const { orgchartFilters } = useContext(OrgchartFilterContext);

  useEffect(() => {

    if (props.params.isLastChild && props.params.isVertical) {
      setTimeout(() => {
        adjustLine(
          document.getElementById(`wrap-cId:${props.params.parentParams?.blockId}`),
          document.getElementById(`wrap-cId:${props.params.blockId}`),
          document.getElementById(`fc-sId:${props.params.blockId}`),
          document.getElementById(`ofl-cId:${props.params.parentParams?.blockId}`),
        );
      }, 10)

    }
  }, [props.params, props.roles, props.blockData, props.blockRole, props.isBlockWide, orgchartFilters]);




  const [width, setWidth] = useState<number | undefined>(undefined);
  const container = useRef(null)
  const [isReload, setIsReload] = useState<boolean>(true);


  useEffect(() => {
    if ((props.params.isRootRole || container.current) && isReload) {
      setTimeout(() => {
        if (!container.current ) {
          if (!props.blockData?.children?.length || props.params.isRootRole) {
            setIsShowText(true);
          }
          return;
        }
        // @ts-ignore
        const containerWidth = container.current.clientWidth;
        setTimeout(() => {
          if (container.current) {
            if (!width) {
              setWidth(containerWidth)
            }
          } else {
            if (!props.blockData?.children?.length) {
              setIsShowText(true);
            }
          }
        }, 100);
      }, 10)

      setIsReload(false);
    } else {
      if (!props.blockData?.children?.length) {
        setIsShowText(true);
      }
    }
  }, [container.current, isReload]);


  useEffect(() => {
    setWidth(undefined);
    setIsReload(true);
    // if (props?.setParentReload) {
    //   props.setParentReload(true);
    // }
  }, [props.blockData]);

  const [isShowText, setIsShowText] = useState(false);
  useEffect(() => {
    if (width) {
      setIsShowText(true);
    }
  }, [width]);

  const blockRole = useMemo(() => {
    const childrensList = getSortedChildren(props.blockData?.children ?? []);
    return  childrensList?.[0]?.roleId != null
      ? getRoleDataById(childrensList?.[0]?.roleId as number)
      : null
  }, [props.blockData?.children]);

  const params = useMemo(() => {
    return {
      isVertical: !props.blockData?.childrenAreHorizontal,
      isRootRole: false,
      isRootRoleChild: props.params.isRootRole,
      isLastChild: getSortedChildren(props.blockData?.children ?? []).length == 0,
      hasParent: true,
      blockId: props.blockData?.children?.[0]?.roleId,
      parentParams: props.params,
    }
  }, [props.blockData, props.params]);

  const params1 = useCallback((child: OrgchartItemDto, index: number) => {
    return {
      isRootRole: false,
      isVertical: !props.blockData?.childrenAreHorizontal,
      isRootRoleChild: props.params.isRootRole,
      isLastChild: index == getSortedChildren(props.blockData?.children ?? []).length - 1,
      hasParent: true,
      blockId: child?.roleId,
      parentParams: props.params,
    }
  }, [props.blockData, props.params]);


  const cardMenuList = useMemo(() => {
    if (
      props.orgchartDrawType === ORGCHART_CLASSIC_TYPE &&
      props.params.isRootRole && (props.blockRole?.childRoles ?? []).length > 1
    ) {
      return (props.cardMenu ?? []).filter((item) => item.key !== "add_role");
    }
    return props.cardMenu ?? [];
  }, [props.cardMenu, props.params.isRootRole, props.blockRole]);

  return (
    <div
      className={`orgchart-card__wrapper d-inline-flex flex-column ${props.params.isRootRole ? "mr-6" : ""}`}
      onClick={() => props.handleCardMenuClose()}
    >
        <Card
          id={generateBlockId()}
          isShadowed={false}
          clickable
          className={`orgchart-card ma-1 ${props.params.isRootRole ? "mb-6" : ""} ${
            props.isHiddenPrintBlock ? 'printHidden' :  ''
          }`}
          style={{
            backgroundColor: getCardBackgroundColor(props.blockRole?.colorHex),
            borderLeft: getCardBorder(props.blockRole?.colorHex),
            overflow: "visible",
          }}
          onClick={props.handleCardClick}
          onMouseLeave={() => props.handleCardMenuClose()}
          onContextMenu={(event) => props.handleCardMenuOpen(event)}
        >
          {/*{(props.blockData?.children ?? []).length > 0 && !props.blockData?.childrenAreHorizontal && (*/}
          {/*  <div*/}
          {/*    id={`ofl-cId:${props.params.blockId}`}*/}
          {/*    style={{ position: "absolute", backgroundColor: "var(--color-layout-divider)", width: "1px" }}*/}
          {/*  />*/}
          {/*)}*/}
          {getCardLines()}
          <div
            className={`orgchart-card__inner d-stack-column spacing-1 ${props.isBlockWide ? "__wide" : ""} ${
              props.zoom == 1 ? "__sticky" : ""
            }`}
          >
            <div className="d-flex align-start orgchart-card__header">
              <Text weight="bold" size="14px" children={props.blockRole?.name} />
              <Dropdown
                overlayClassName="orgchart-card__menu"
                overlayStyle={{ minWidth: "200px" }}
                open={props.isCardMenuOpen}
                trigger={["click"]}
                dropdownRender={(menu) =>
                  props.isCardMenuLoading ? (
                    <div style={cardMenuContentStyle}>
                      <Spin className="full-width my-2" size="default" spinning />
                    </div>
                  ) : (
                    React.cloneElement(menu as React.ReactElement, { style: undefined })
                  )
                }
                // trigger={["contextMenu"]}
                align={{ offset: [-12, 0] }}
                onOpenChange={(v) => !v && props.handleCardMenuClose()}
                items={cardMenuList.map((item) => ({
                  key: item.id as any,
                  icon: getMenuItemIcon(item.key as string),
                  text: t("common:misc.card_menu." + item.key),
                  onClick: (event) => {
                    props.handleMenuItemClick(item.key as string, props.blockRole?.id as number);
                    event.stopPropagation();
                  }
                }))}
              >

                {/*<Text weight="bold" size="14px" children={props.blockRole?.name} />*/}
                <div className="ml-3 d-stack align-center spacing-2 orgchart-card__actions__wrapper">
                  {(props.blockRole?.description ?? "").trim().length > 0 && (
                    <Popover
                      overlayInnerStyle={{
                        maxWidth: "360px"
                      }}
                      content={
                        <div className="d-stack-column spacing-2 no-select" onClick={(e) => e.stopPropagation()}>
                          <Text weight="bold" size="12px" children={`${t("parse:function_description")}:`} />
                          <LinkItUrl>
                            <Text
                              size="12px"
                              style={{ whiteSpace: "pre-line" }}
                              children={props.blockRole?.description?.trim() ?? ""}
                            />
                          </LinkItUrl>
                        </div>
                      }
                      // trigger={["click"]}
                      placement="bottom"
                      arrow={false}
                    >
                      <Icon
                        className="printHidden"
                        onClick={(e) => e.stopPropagation()}
                        component={() => <LucideHelpCircle color="var(--color-text-weaker)" size={14} />}
                      />
                    </Popover>
                  )}
                  <div>
                    <IconButton
                      size="small"
                      type="text"
                      style={{ height: "22px", width: "22px" }}
                      className="printHidden"
                      icon={<Icon component={() => <FiSettings />} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.isCardMenuOpen ? props.handleCardMenuClose() : props.handleCardMenuOpen(e);
                      }}
                    />
                  </div>
                </div>
              </Dropdown>
            </div>

            {/*{props.params.isVertical ? "s" : "f"}*/}
            { isShowText && (
              <OrgchartFunctionCardHubbardContent
                {...props}
              />
            )}
            {/*<Text children={" / isV: " + props.params.isVertical} />*/}
            {/*<Text children={" / nL: " + props.params.nestingLevel} />*/}
            {/*<Text children={" / cL: " + (getSortedChildren(props.blockData?.children ?? []).length - 1)} />*/}
            {/*<Text children={"PCL: " + props.params.parentChildrenLength} />*/}
            {/*<Text children={" / bIn: " + props.params.blockIndex} />*/}
            {/*<Text children={JSON.stringify(props.params.nestingLevels)} />*/}
          </div>
        </Card>
      {(props.blockData?.children ?? []).length > 0 && (
        <>
          {/*<div*/}
          {/*  style={{*/}
          {/*    position: "absolute",*/}
          {/*    backgroundColor: "var(--color-layout-divider)",*/}
          {/*    width: "1px",*/}
          {/*    height: "100%"*/}
          {/*}}*/}
          {/*/>*/}
          {props.params.isRootRole ? (
            <OrgchartFunctionCardHubbard
              zoom={props.zoom}
              isHiddenPrintBlock={props.isHiddenPrintBlock}
              printBlockId={props.printBlockId}
              handleOrgchartBlockPrint={props.handleOrgchartBlockPrint}
              key={getSortedChildren(props.blockData?.children ?? [])?.[0]?.roleId}
              roles={props.roles}
              blockData={props.blockData?.children?.[0] as any}
              blockRole={blockRole}
              params={params}
              // visibilityFilters={props.visibilityFilters}
              orgchartDrawType={props.orgchartDrawType}
            />
          ) : (
            <div
              id={`wrap-cId:${props.params.blockId}`}
              ref={container}
              style={{
                width: width ? width : 'auto',
                maxWidth: width ? width : 'none',
                // minWidth: '100%'
              }}
              className={`orgchart-card__children__wrapper d-inline-flex ${
                props.blockData?.childrenAreHorizontal ? "" : "flex-column ml-4"
              } ${
                props.isHiddenPrintBlock ? 'orgchart-card__children__wrapper_no-margin-print' : ''
              }`}
            >
              {getSortedChildren(props.blockData?.children ?? [])
                ?.sort(
                  (a: OrgchartItemDto, b: OrgchartItemDto) =>
                    (getRoleDataById(a.roleId as number)?.order ?? 0) - (getRoleDataById(b.roleId as number)?.order ?? 0)
                )
                .map((child: OrgchartItemDto, index: number) => (
                    <OrgchartFunctionCardHubbard
                      key={child.roleId}
                      zoom={props.zoom}
                      isHiddenPrintBlock={props.isHiddenPrintBlock}
                      printBlockId={props.printBlockId}
                      handleOrgchartBlockPrint={props.handleOrgchartBlockPrint}
                      roles={props.roles}
                      blockData={child}
                      blockRole={child.roleId != null ? getRoleDataById(child.roleId) : null}
                      params={params1(child, index)}
                      orgchartDrawType={props.orgchartDrawType}
                      // visibilityFilters={props.visibilityFilters}
                    />
                ))}
              {!props.blockData?.childrenAreHorizontal && (
                <div
                  id={`ofl-cId:${props.params.blockId}`}
                  className={`orgchart-card__line ${props.isHiddenPrintBlock ? 'printHidden' : ''}` }
                />

              )}
            </div>
          )}
        </>)}
    </div>
  );
}

export default observer(OrgchartFunctionCardHubbardView);
