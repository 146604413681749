import React, { memo, useCallback, useMemo } from "react";
import { Tag } from "../../../../../../uiKit";
import { ITagItem } from "./ITagItem";
import { useTagsColor } from "../../../../../../../hooks/useTagColor";

export const TagItem = memo((props: ITagItem) => {
  const handleSelectTags = useCallback(() => {
    props.onSelectTag(props.tag.id);
  }, []);

  const {
    borderColor,
    background,
    color,
  } = useTagsColor(props.tag.color ?? "gray", props.isSelected);

  return (
    <Tag
      key={props.tag.id}
      // closable
      className="d-flex align-center justify-center"
      style={{
        height: "24px",
        borderColor,
        background,
        color,
      }}
      color={color}
      bordered={false}
      onClick={handleSelectTags}
      // onClose={handleRemoveTag}
    >
      { props.tag.name }
    </Tag>
  )
})
