import React, { useCallback, useEffect, useMemo } from "react";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { UpdateNoteListContent } from "../../contents/UpdateNoteListContent/UpdateNoteListContent";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import { Alert, Button, Icon, Text } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { LucideSend } from "lucide-react";
import { useLocalStorage, useRootStore } from "../../../../../../hooks";
import { observer } from "mobx-react-lite";

export const UpdateNoteList = observer(() => {
  // const claims = useClaims();
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowAlert, setIsShowAlert] = useLocalStorage("isShowUpdateNoteAlert", true);

  //const permissionAddUpdateNote = claims.has(permissionKeys.recurringIssue.add);
  const isCurrentUserAdmin = useMemo(
    () => !!authStore.getInitialInfo?.identity?.admin,
    [authStore.getInitialInfo?.identity]
  );
  const updateNotes = usePagingWithController(api.updateNote, {}, { pageSize: -1 });

  const handleRedirectToCreate = useCallback(() => navigate("/update-note/create"), [navigate]);

  const handleRedirectToTelegramChannel = useCallback(() => {
    window.open("https://t.me/+y3t1iktSs29lNDEy", "_blank", "noreferrer");
  }, []);

  const handleClose = useCallback(() => {
    setIsShowAlert(false);
  }, [setIsShowAlert]);

  useEffect(() => {
    updateNotes.restart();
  }, []);

  return (
    <>
      <PageHeader title={t("ui:title.releases")}>
        <div className="flex-grow-1" />
        {!isShowAlert && (
          <Button
            className={isCurrentUserAdmin ? "mr-3" : ""}
            variant="default"
            children={
              <div className="d-stack-row align-center">
                <Text children={t("ui:button.join_to_tg_chat")} className="mr-1" />
                <Icon component={() => <LucideSend size={16} stroke="var(--color-text-weak)" />} />
              </div>
            }
            onClick={handleRedirectToTelegramChannel}
            style={{ borderColor: "var(--color-gray-strong)" }}
          />
        )}
        {isCurrentUserAdmin && <Button onClick={handleRedirectToCreate}>{t("ui:button.add")}</Button>}
      </PageHeader>
      {isShowAlert && (
        <Alert
          className="mt-4 mb-3"
          message={
            <div className="d-stack-row justify-space-between align-center">
              <Text children={t("ui:text.update_note_alert")} />
              <Button
                ghost
                className="mr-4"
                variant="default"
                children={
                  <div className="d-stack-row align-center">
                    <Text children={t("ui:button.join_to_tg_chat")} className="mr-1" />
                    <Icon component={() => <LucideSend size={16} stroke="var(--color-text-weak)" />} />
                  </div>
                }
                onClick={handleRedirectToTelegramChannel}
                style={{ borderColor: "var(--color-gray-strong)" }}
              />
            </div>
          }
          onClose={handleClose}
          closable
          type="success"
        />
      )}
      <PageContent isLoading={updateNotes.items.length === 0 && updateNotes.info.isLoading}>
        <UpdateNoteListContent
          /*permissionToAdd={permissionAddUpdateNote}*/
          isCurrentUserAdmin={isCurrentUserAdmin}
          isDone={updateNotes.info.isDone}
          isLoading={updateNotes.info.isLoading}
          loadNext={updateNotes.loadNext}
          updateNotes={updateNotes.items}
        />
      </PageContent>
    </>
  );
});
