export enum LocalesKeys {
  uk = "uk",
  en = "en",
  ru = "ru",
  zh = "zh",
  es = "es",
  // uz = "uz",
  uzLatn = "uz-latn",
  uzCyrl = "uz-cyrl",
  pl = "pl",
  de = "de",
}

