import React, { createRef, Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { RegulationDto, SectionDto } from "../../../../../api";
import "./EmployeeIndividualRegulationBlock.scss";
import { useDateHelpers } from "../../../../../hooks";
import {
  Button,
  Card,
  Divider,
  Empty,
  Icon,
  Input,
  List,
  ListItem,
  Spin,
  TabPane,
  Tabs,
  Text,
} from "../../../../uiKit";
import { LucideChevronLeft, LucideRefreshCw, LucideSearch } from "lucide-react";
import { RegulationProgressBar } from "../../regulation/components/RegulationProgressBar/RegulationProgressBar";
import { useTranslation } from "react-i18next";
import { If } from "../../../../service/conditionStatement";
import { api } from "../../../../../services";
import { NavigationMenuItemDto } from "../../../../../api/models/NavigationMenuDto";

export const openURL = (target: string) => {
  const win = window.open(target, "_blank");
  if (win != null) {
    win.focus();
  }
};

interface IEmployeeIndividualRegulationBlockViewProps {
  searchString: string;
  userId?: number;
  isLoading: {
    regulationStudied: boolean;
    regulationNotStudied: boolean;
    sectionStudied: boolean;
    sectionNotStudied: boolean;
  };
  isDone: {
    regulationStudied: boolean;
    regulationNotStudied: boolean;
    sectionStudied: boolean;
    sectionNotStudied: boolean;
  };
  items: {
    regulationStudied: RegulationDto[];
    regulationNotStudied: RegulationDto[];
    sectionStudied: SectionDto[];
    sectionNotStudied: SectionDto[];
  };
  setSearchString: (val: string) => void;
  onGoToRestudy: (itemId: number, type: "regulation" | "section") => void;
  onGoToStudy: (itemId: number) => void;
}

interface IEmployeeIndividualSectionBlockCardProps {
  // regulations: SectionDto[];
  title: string;
  isBackButtonAvailable?: boolean;
  children: ReactNode;

  onBackClick?: () => void;
}

interface IEmployeeIndividualRegulationBlockSectionsCardProps {
  type: "studied" | "notStudied";
  isLoading: boolean;
  userId?: number;
  sections: SectionDto[];
  onGoToRestudy: (itemId: number, type: "regulation" | "section") => void;
}

interface IEmployeeIndividualRegulationBlockRegulationsCardProps {
  type: "studied" | "notStudied";
  isLoading: boolean;
  userId?: number;
  regulations: RegulationDto[];
  onGoToStudy: (itemId: number) => void;
  onGoToRestudy: (itemId: number, type: "regulation" | "section") => void;
}

const EmployeeIndividualRegulationBlockCard = (props: IEmployeeIndividualSectionBlockCardProps) => {
  const { t } = useTranslation();

  return (
    <Card
      title={
        <div className="d-stack-row spacing-5 align-center">
          {props.isBackButtonAvailable && (
            <Button
              className="px-0"
              variant="link"
              size="small"
              style={{ fontSize: "12px", height: "18px" }}
              icon={<Icon component={() => <LucideChevronLeft size={22} />} />}
              children={t("parse:go_back")}
              onClick={props.onBackClick}
            />
          )}
          <Text size="12px" style={{ overflow: "hidden", textOverflow: "ellipsis" }} children={props.title} />
        </div>
      }
      headStyle={{ padding: "16px 12px", fontSize: "12px" }}
      bodyStyle={{ minHeight: "64px", padding: 0, overflow: "hidden", flexGrow: 1 }}
      className="flex-grow-1 employee-individual-block"
      children={props.children}
    />
  );
};

const TextObservedWidth = ({
  children,
  onWidthChange,
  type = "notStudied",
}: {
  children: ReactNode;
  onWidthChange: (width: number | undefined) => void;
  type?: "studied" | "notStudied";
}) => {
  const { t } = useTranslation();
  const itemRef = createRef<HTMLSpanElement>();

  useEffect(() => {
    onWidthChange(
      itemRef.current?.clientWidth != null ? Math.ceil(itemRef.current?.clientWidth) : itemRef.current?.clientWidth
    );
  }, [children]);

  return (
    <span ref={itemRef} className="d-flex flex-column flex-grow-0 flex-shrink-0">
      <Text
        size="12px"
        style={{ whiteSpace: "nowrap", lineHeight: "1.2em" }}
        type="secondary"
        children={type == "studied" ? t("parse:studied") : t("ui:subheader.issue_created")}
      />
      <Text size="12px" style={{ whiteSpace: "nowrap", lineHeight: "1.2em" }} type="secondary" children={children} />
    </span>
  );
};

const EmployeeIndividualRegulationBlockSectionsCard = (props: IEmployeeIndividualRegulationBlockSectionsCardProps) => {
  const { t } = useTranslation();

  const [activeSectionId, setActiveSectionId] = useState<number | null>(null);
  const [maxDateSectionWidth, setMaxDateSectionWidth] = useState<number | undefined>(undefined);
  const [maxDateRegulationWidth, setMaxDateRegulationWidth] = useState<number | undefined>(undefined);

  const { formatDate } = useDateHelpers();

  const getLastStudiedRegulationDate = (sectionId?: number): string | null => {
    let studiedDates: string[] = [];
    const currentSection = props.sections.find((section) => section.id == sectionId);
    currentSection?.regulations
      ?.filter((r) => r.regulation?.usersWhoStudied?.map((u) => u.userId)?.includes(props.userId))
      .forEach((reg) => {
        studiedDates = [
          ...studiedDates,
          reg.regulation?.usersWhoStudied?.find((u) => u.userId == props.userId)?.dateCreated ?? "",
        ];
      });
    studiedDates = studiedDates.filter((d) => d.trim().length > 0);
    return studiedDates.length > 0
      ? formatDate(
          new Date(
            Math.max.apply(
              null,
              studiedDates.map((d) => new Date(d).getTime())
            )
          ).toISOString(),
          {
            showYearWhenIsCurrent: true,
            formatObject: {
              hour: undefined,
              minute: undefined,
            }
          }
        )
      : null;
  };

  useEffect(() => {
    if (
      (props.sections.length == 0 || props.sections.find((section) => section.id == activeSectionId) == null) &&
      activeSectionId != null
    ) {
      setActiveSectionId(null);
    }
  }, [props.sections, activeSectionId]);

  const [regulations, setRegulations] = useState<NavigationMenuItemDto[]>([]);

  const fetchSection = useCallback( async (id: number) => {
    // setIsLoading(true);
    console.log("id", id);
    const res =   await api.navigationMenu.getItemById(id)
    console.log("res", res);
    if (res) {
      const regulationsList =
        (res.items ?? [])
          .filter((item) => item.regulation);
      setRegulations(regulationsList);
      console.log("regulationsList", regulationsList);
    }
    // if (res) {
    //   setSection(res);
    //   setTitleSection(isEditable ? res.name : "");
    //   if (regulationStore.getParentId !== res.id) {
    //     regulationStore.setParentId(res.id)
    //   }
    // }
    // setIsLoading(false);
  }, []);

  useEffect(() => {
    if (activeSectionId) {
      fetchSection(activeSectionId);
    } else {
      setRegulations([]);
    }
  }, [activeSectionId]);

  const handleGoToSection = (id: number) => {
    console.log("handleGoToSection");
    openURL(`/policy/topics/${id}`)
  };

  return (
    <EmployeeIndividualRegulationBlockCard
      title={props.sections.find((section) => section.id == activeSectionId)?.name || t("common:breadcrumbs.topics")}
      isBackButtonAvailable={activeSectionId != null}
      onBackClick={() => setActiveSectionId(null)}
    >
      <Spin spinning={props.isLoading} wrapperClassName={"full-height"}>
        <List
          className="employee-page-individual_regulation-block_list"
          style={{
            minHeight: "100%",
            height: "inherit",
          }}
          split={false}
        >
          <Tabs
            activeKey={activeSectionId == null ? "main" : "section"}
            tabBarStyle={{ display: "none" }}
            destroyInactiveTabPane={false}
          >
            <TabPane key={"main"}>
              {props.sections.length == 0 && !props.isLoading ? (
                <Empty className="py-6" isVerticallyCentered />
              ) : (
                props.sections.map((section) => (
                  <Fragment key={section.id}>
                    <ListItem className="pa-0">
                      <Card
                        clickable
                        isShadowed={false}
                        className="full-width br-none"
                        // onClick={() => setActiveSectionId(section.id as number)}
                        onClick={() => handleGoToSection(section.id!)}
                        bodyStyle={{
                          padding: "16px 12px",
                          minHeight: "64px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 0,
                        }}
                      >
                        <div className="d-stack-row spacing-10 align-center full-width">
                          <Text size="12px" className="flex-grow-1" children={section.name} />
                          {props.type == "studied" ? (
                            <If condition={!!section.isAllowedToResendRegulation}>
                              <Button
                                children={t("parse:send_to_restudy")}
                                style={{ fontSize: "12px", padding: "6px 8px" }}
                                size="middle"
                                theme="error"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.onGoToRestudy(section.id as number, "section");
                                }}
                              />
                            </If>
                          ) : (
                            <div
                              className="d-flex align-center flex-column"
                              // style={{ maxWidth: "200px", width: "100%" }}
                            >
                              <Button
                                children={t("parse:send_to_restudy")}
                                style={{
                                  fontSize: "12px",
                                  padding: "0 9px",
                                  border: "none",
                                  borderRadius: 0,
                                  height: 0,
                                  pointerEvents: "none",
                                  visibility: "hidden",
                                }}
                                size="middle"
                              />
                              <RegulationProgressBar
                                progress={{
                                  progressPercent: section.stats?.studiedPercent ?? 0,
                                }}
                              />
                            </div>
                          )}
                          {section.dateCreated != null && (
                            <div
                              className="d-flex align-center justify-start flex-shrink-0"
                              style={{ width: maxDateSectionWidth }}
                            >
                              <TextObservedWidth
                                type={props.type}
                                onWidthChange={(width) =>
                                  setMaxDateSectionWidth((prevWidth) =>
                                    width != null && width > (prevWidth ?? 0) ? width : prevWidth
                                  )
                                }
                                children={
                                  props.type == "studied"
                                    ? getLastStudiedRegulationDate(section.id)
                                    : section?.dateCreated != null
                                    ? formatDate(section.dateCreated, {
                                        showYearWhenIsCurrent: true,
                                      formatObject: {
                                        hour: undefined,
                                        minute: undefined,
                                      }
                                      })
                                    : ""
                                }
                              />
                            </div>
                          )}
                        </div>
                      </Card>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))
              )}
            </TabPane>
            <TabPane key={"section"}>
              <>
                {props.sections.find((section) => section.id == activeSectionId) == null ||
                regulations?.length == 0 ? (
                  <Empty className="py-6" isVerticallyCentered />
                ) : (
                  regulations?.sort((a, b) =>
                      a.regulation?.isStudied === b.regulation?.isStudied
                        ? 0
                        : a.regulation?.isStudied
                        ? 1
                        : -1
                    )
                    .map((regulation) => (
                      <Fragment key={regulation.regulationId}>
                        <ListItem className="pa-0">
                          <Card
                            clickable
                            isShadowed={false}
                            className="full-width br-none"
                            onClick={() => openURL(`/policy/all/${regulation.regulationId}`)}
                            bodyStyle={{
                              padding: "16px 12px",
                              minHeight: "64px",
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 0,
                            }}
                          >
                            <div className="d-stack-row spacing-10 align-center full-width">
                              <Text size="12px" className="flex-grow-1" children={regulation.regulation?.name} />
                              {/*props.type == "studied" &&*/}
                              {regulation.regulation?.isStudied && (
                                <If condition={!!regulation.regulation.isAllowedToResendRegulation || true}>
                                  <Button
                                    children={t("parse:send_to_restudy")}
                                    style={{ fontSize: "12px", padding: "6px 8px" }}
                                    size="middle"
                                    theme="error"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      props.onGoToRestudy(regulation.regulationId as number, "regulation");
                                    }}
                                  />
                                </If>
                              )}
                              {(props.type == "studied"
                                ? regulation.regulation?.usersWhoStudied?.find((u) => u.userId == props.userId)
                                    ?.dateCreated != null
                                : regulation.regulation?.dateCreated != null) && (
                                <div
                                  className="d-flex align-center justify-start flex-shrink-0"
                                  style={{ width: maxDateRegulationWidth }}
                                >
                                  <TextObservedWidth
                                    onWidthChange={(width) =>
                                      setMaxDateRegulationWidth((prevWidth) =>
                                        width != null && width > (prevWidth ?? 0) ? width : prevWidth
                                      )
                                    }
                                    type={
                                      regulation.regulation?.usersWhoStudied?.find((u) => u.userId == props.userId)
                                        ?.dateCreated != null
                                        ? "studied"
                                        : "notStudied"
                                    }
                                    children={
                                      props.type == "studied"
                                        ? regulation.regulation?.usersWhoStudied?.find((u) => u.userId == props.userId)
                                            ?.dateCreated != null
                                          ? formatDate(
                                              regulation.regulation?.usersWhoStudied?.find(
                                                (u) => u.userId == props.userId
                                              )?.dateCreated as string,
                                              {
                                                showYearWhenIsCurrent: true,
                                                formatObject: {
                                                  hour: undefined,
                                                  minute: undefined,
                                                },
                                              }
                                            )
                                          : ""
                                        : regulation.regulation?.dateCreated != null
                                        ? formatDate(regulation.regulation?.dateCreated, {
                                            showYearWhenIsCurrent: true,
                                            formatObject: {
                                              hour: undefined,
                                              minute: undefined,
                                            },
                                          })
                                        : ""
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))
                )}
              </>
            </TabPane>
          </Tabs>
        </List>
      </Spin>
    </EmployeeIndividualRegulationBlockCard>
  );
};

const EmployeeIndividualRegulationBlockRegulationsCard = (
  props: IEmployeeIndividualRegulationBlockRegulationsCardProps
) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();
  const [maxDateWidth, setMaxDateWidth] = useState<number | undefined>(undefined);

  return (
    <EmployeeIndividualRegulationBlockCard title={t("common:breadcrumbs.policies")}>
      <Spin spinning={props.isLoading} wrapperClassName={"full-height"}>
        <List
          className="employee-page-individual_regulation-block_list"
          style={{
            minHeight: "100%",
            height: "inherit",
          }}
          split={false}
        >
          {props.regulations.length == 0 && !props.isLoading ? (
            <Empty className="py-6" isVerticallyCentered />
          ) : (
            props.regulations.map((regulation) => (
              <Fragment key={regulation.id}>
                <ListItem className="pa-0">
                  <Card
                    clickable
                    isShadowed={false}
                    className="full-width br-none"
                    onClick={() => openURL(`/policy/all/${regulation.id}`)}
                    bodyStyle={{
                      padding: "16px 12px",
                      minHeight: "64px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 0,
                    }}
                  >
                    <div className="d-stack-row spacing-10 align-center full-width">
                      <Text size="12px" className="flex-grow-1" children={regulation.name} />
                      {false && props.type != "studied" && window.location.hostname == "localhost" && (
                        <Button
                          size="small"
                          theme="warning"
                          icon={<Icon component={() => <LucideRefreshCw size={16} />} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            props.onGoToStudy(regulation.id as number);
                          }}
                        />
                      )}
                      {props.type == "studied" && (
                        <If condition={!!regulation.isAllowedToResendRegulation}>
                          <Button
                            children={t("parse:send_to_restudy")}
                            style={{ fontSize: "12px", padding: "6px 8px" }}
                            size="middle"
                            theme="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onGoToRestudy(regulation.id as number, "regulation");
                            }}
                          />
                        </If>
                      )}
                      {(props.type == "studied"
                        ? regulation.usersWhoStudied?.find((u) => u.userId == props.userId)?.dateCreated != null
                        : regulation.dateCreated != null) && (
                        <div
                          className="d-flex align-center justify-start flex-shrink-0"
                          style={{ width: maxDateWidth }}
                        >
                          <TextObservedWidth
                            onWidthChange={(width) =>
                              setMaxDateWidth((prevWidth) =>
                                width != null && width > (prevWidth ?? 0) ? width : prevWidth
                              )
                            }
                            type={
                              regulation?.usersWhoStudied?.find((u) => u.userId == props.userId)?.dateCreated != null
                                ? "studied"
                                : "notStudied"
                            }
                            children={
                              props.type == "studied"
                                ? regulation.usersWhoStudied?.find((u) => u.userId == props.userId)?.dateCreated != null
                                  ? formatDate(
                                      regulation.usersWhoStudied?.find((u) => u.userId == props.userId)
                                        ?.dateCreated as string,
                                      {
                                        showYearWhenIsCurrent: true,
                                        formatObject: {
                                          hour: undefined,
                                          minute: undefined,
                                        },
                                      }
                                    )
                                  : ""
                                : regulation.dateCreated != null
                                ? formatDate(regulation.dateCreated, {
                                    showYearWhenIsCurrent: true,
                                    formatObject: {
                                      hour: undefined,
                                      minute: undefined,
                                    },
                                  })
                                : ""
                            }
                          />
                        </div>
                      )}
                    </div>
                  </Card>
                </ListItem>
                <Divider />
              </Fragment>
            ))
          )}
        </List>
      </Spin>
    </EmployeeIndividualRegulationBlockCard>
  );
};

export const EmployeeIndividualRegulationBlockView = (props: IEmployeeIndividualRegulationBlockViewProps) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  return (
    // <div className="d-stack-column spacing-3">
    <div className="employee-page-individual_regulation-block__wrapper">
      <Text className="align-self-end" weight="bold" size="14px" children={t("parse:studied")} />
      <Input
        prefix={<LucideSearch size={16} color="var(--color-gray-strong)" />}
        size="middle"
        value={props.searchString}
        onChange={(e) => props.setSearchString(e.target.value)}
        allowClear
        placeholder={t("ui:placeholder.search")}
      />
      <EmployeeIndividualRegulationBlockRegulationsCard
        type={"studied"}
        userId={props.userId}
        isLoading={props.isLoading.regulationStudied}
        regulations={props.items.regulationStudied}
        onGoToRestudy={props.onGoToRestudy}
        onGoToStudy={props.onGoToStudy}
      />
      <EmployeeIndividualRegulationBlockSectionsCard
        type={"studied"}
        userId={props.userId}
        isLoading={props.isLoading.sectionStudied}
        sections={props.items.sectionStudied}
        onGoToRestudy={props.onGoToRestudy}
      />
      <Text className="align-self-end" weight="bold" size="14px" children={t("parse:not_studied")} />
      <Input size="middle" style={{ visibility: "hidden", width: "10px" }} />
      <EmployeeIndividualRegulationBlockRegulationsCard
        type={"notStudied"}
        userId={props.userId}
        isLoading={props.isLoading.regulationNotStudied}
        regulations={props.items.regulationNotStudied}
        onGoToRestudy={props.onGoToRestudy}
        onGoToStudy={props.onGoToStudy}
      />
      <EmployeeIndividualRegulationBlockSectionsCard
        type={"notStudied"}
        userId={props.userId}
        isLoading={props.isLoading.sectionNotStudied}
        sections={props.items.sectionNotStudied}
        onGoToRestudy={props.onGoToRestudy}
      />
      {/*<div className="d-flex justify-end mt-n1">*/}
      {/*  <Button*/}
      {/*    variant="link"*/}
      {/*    loading={props.isLoading.regulationNotStudied || props.isLoading.sectionNotStudied}*/}
      {/*    children={"dev:Показать ещё"}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};
