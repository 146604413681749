import React from "react";
import { Button, Empty, Spin, Text } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { RegulationDto, SectionDto } from "../../../../../../api";
import { PagingInfo } from "../../../../../../hooks/usePaging";
import { CardSection } from "../../../regulation/components/CardSection/CardSection";
import { CardRegulation } from "../../../regulation/components/CardRegulation/CardRegulation";
import { regulationContentTypeKeys } from "../../../regulation/misc/headerTools";

interface IOrgchartDrawerTabRegulationsViewProps {
  section: {
    items: SectionDto[];
    info: PagingInfo;
  };
  regulation: {
    items: RegulationDto[];
    info: PagingInfo;
  };
  onLoadNextRegulation: () => void;
  onLoadNextSection: () => void;
}

export const OrgchartDrawerTabRegulationsView = (props: IOrgchartDrawerTabRegulationsViewProps) => {
  const { t } = useTranslation();

  return (
    <Spin
      spinning={
        (props.section.info.isLoading && props.section.items.length == 0) ||
        (props.regulation.info.isLoading && props.regulation.items.length == 0)
      }
      className="orgchart-function-drawer__tabs__loader"
    >
      <div>
        <div
          className="mt-n3 pb-3 d-flex flex-nowrap justify-space-between"
          style={{
            position: "sticky",
            top: "-12px",
            backgroundColor: "var(--color-srf-primary)",
            zIndex: 2,
          }}
        >
          <Text size="16px" weight="bold" children={t("ui:title.topics")} />
        </div>
        <div>
          {props.section.info.isDone && props.section.items.length == 0 && <Empty className="my-4" />}
          {props.section.items.map((section) => (
            <CardSection key={section.id} section={section} />
          ))}
          {!props.section.info.isDone && (
            <Button
              className="mt-2"
              variant="outlined"
              size="middle"
              loading={props.section.info.isLoading}
              children={t("parse:more")}
              onClick={props.onLoadNextSection}
            />
          )}
        </div>
      </div>
      <div className="mt-12">
        <div
          className="mt-n3 pb-3 d-flex flex-nowrap justify-space-between"
          style={{
            position: "sticky",
            top: "-12px",
            backgroundColor: "var(--color-srf-primary)",
            zIndex: 2,
          }}
        >
          <Text size="16px" weight="bold" children={t("ui:title.policies")} />
        </div>
        <div>
          {props.regulation.info.isDone && props.regulation.items.length == 0 && <Empty className="my-4" />}
          {props.regulation.items.map((regulation) => (
            <CardRegulation
              key={regulation.id}
              regulation={regulation}
              regulationContentType={regulationContentTypeKeys.approved}
            />
          ))}
          {!props.regulation.info.isDone && (
            <Button
              className="mt-2"
              variant="outlined"
              size="middle"
              loading={props.regulation.info.isLoading}
              children={t("parse:more")}
              onClick={props.onLoadNextRegulation}
            />
          )}
        </div>
      </div>
    </Spin>
  );
};
