import { hexToRgb } from "../helpers/colorFunctions";

type rgbType = {
  r: number;
  g: number;
  b: number;
}

const defaultColors: string[] = [
  "#252525",
  "#eb2f96",
  "#52c41a",
  "#f5222d",
  "#fa541c",
  "#9e5a40",
  "#fa8c16",
  "#866d2d",
  "#f8b01f",
  "#faad14",
  "#a0d911",
  "#13c2c2",
  "#1890ff",
  "#2f54eb",
  "#722ed1",
];

export const colorDistance = (color1:rgbType, color2:rgbType) => {
  return Math.sqrt(
    Math.pow(color1.r - color2.r, 2) +
    Math.pow(color1.g - color2.g, 2) +
    Math.pow(color1.b - color2.b, 2)
  );
}

function findClosestColor(targetColor: string, colorArray: string[]): string {
  const targetRgb = hexToRgb(targetColor) as rgbType;

  let closestColor: string = '';
  let smallestDistance: number = Infinity;

  for (const color of colorArray) {
    const currentRgb = hexToRgb(color) as rgbType;
    const distance = colorDistance(targetRgb, currentRgb);
    if (distance < smallestDistance) {
      smallestDistance = distance;
      closestColor = color;
    }
  }

  return closestColor;
}


export const orgchartHexToVariables = (hex: string) => {
  const color = hex.toLowerCase()
  switch (color) {
    case "#dce2f0":
      return "var(--color-orgst-gray)"
    case "#ffc6c6":
      return "var(--color-orgst-red)"
    case "#ffe2c6":
      return "var(--color-orgst-peach)"
    case "#fff7c6":
      return "var(--color-orgst-yellow)"
    case "#ceffc6":
      return "var(--color-orgst-lightgreen)"
    case "#d0f4f1":
      return "var(--color-orgst-teal)"
    case "#c6d7ff":
      return "var(--color-orgst-blue)"
    case "#dec6ff":
      return "var(--color-orgst-purple)"
    case "#ffc6df":
      return "var(--color-orgst-pink)"
    case "#b140cc":
      return "var(--color-orgst-pink)";
    case "#d971f6":
      return "var(--color-orgst-pink)";
    default:
      return hex
  }
}

export const orgchartVariablesToHex = (valiable: string) => {
  const color = valiable.toLowerCase()
  switch (color) {
    case "var(--color-orgst-gray)":
      return "#dce2f0"
    case "var(--color-orgst-red)":
      return "#ffc6c6"
    case "var(--color-orgst-peach)":
      return "#ffe2c6"
    case "var(--color-orgst-yellow)":
      return "#fff7c6"
    case "var(--color-orgst-lightgreen)":
      return "#ceffc6"
    case "var(--color-orgst-teal)":
      return "#d0f4f1"
    case "var(--color-orgst-blue)":
      return "#c6d7ff"
    case "var(--color-orgst-purple)":
      return "#dec6ff"
    case "var(--color-orgst-pink)":
      return "#ffc6df"
    default:
      return color
  }
}

export const tagHexToVariables = (hex: string) => {
  const hexLowerCase = hex.toLowerCase()
  console.log("hex", hex);

  const color = hexLowerCase.includes("#")
    ? findClosestColor(hexLowerCase, defaultColors)
    : hexLowerCase;
  console.log("color", color);
  switch (color) {
    case "#252525":
      return "gray";
    case "#eb2f96":
      return "pink";
    case "#52c41a":
      return "green";
    case "#f5222d":
      return "red";
    case "#fa541c":
      return "red";
    case "#9e5a40":
      return "orange";
    case "#fa8c16":
      return "orange";
    case "#866d2d":
      return "orange";
    case "#f8b01f":
      return "orange";
    case "#faad14":
      return "orange";
    case "#a0d911":
      return "green";
    case "#13c2c2":
      return "turquoise";
    case "#1890ff":
      return "blue";
    case "#2f54eb":
      return "blue";
    case "#722ed1":
      return "purple";
    default:
      return color.includes("#") ? "gray" : color;
  }
}
