import { createContext } from "react";
import { IApiControllerGet } from "../api/interfaces/iApiControllerGet";

export interface IAIAssistantContext<T, TFilter> {
  controller: IApiControllerGet<T, TFilter>,
  historyQueryURI: string;
  deleteHistoryMethod?: string;
  itemId: number;
  reviewMethod?: string;
  requestPlaceholder: string;
  handleCloseAIAssistant: () => void;
  onSaveContent?: (notCloseEditing?: boolean) => void;
}

const defaultContextValue: IAIAssistantContext<any, any> = {
  controller: {} as IApiControllerGet<any, any>,
  historyQueryURI: '',
  itemId: -1,
  requestPlaceholder: '',
  handleCloseAIAssistant: () => {},
};

export const AIAssistantContext = createContext<IAIAssistantContext<unknown, unknown>>(defaultContextValue);
