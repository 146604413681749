import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { Select, SelectOption } from "../../../../uiKit";
import { IssueTypeKeys } from "../../../../../constants/issueTypeKeys";
import { api } from "../../../../../services";
import { IssueCustomFieldDto } from "../../../../../api";
import { ISelect } from "../../../../uiKit/select/Select";

export interface ILanguageSwitcher {
  miniVariant?: boolean;
  selectProps?: ISelect;
}

function LanguageSwitcher(props: ILanguageSwitcher) {
  const { t, i18n } = useTranslation();
  const { authStore, appStore, helperStore } = useRootStore();
  const appLanguagesArray = [
    { id: 0, name: "English", short: "en" },
    { id: 1, name: "Русский", short: "ru" },
    { id: 2, name: "Українська", short: "uk" },
    { id: 3, name: "繁體中文", short: "zh" },
    { id: 4, name: "Oʻzbek", short: "uz-latn" },
    { id: 5, name: "Ўзбек", short: "uz-cyrl" },
    { id: 6, name: "Español", short: "es" },
    { id: 7, name: "Deutsch", short: "de" },
    { id: 8, name: "Polski", short: "pl" },
  ];

  const substrLength = i18n.language.includes("uz-") ? 7 : 2

  const currentLanguageId = i18n.language
    ? appLanguagesArray.find((lng) => lng.short == i18n.language.substr(0, substrLength))?.id ?? 0
    : 0;
  const handleLoadCustomFields = () => {
    const customFieldsTypes = [IssueTypeKeys.task];
    const promisesArr = customFieldsTypes.map((item) => api.helper.customField(item));
    Promise.all(promisesArr).then((res) => {
      customFieldsTypes.forEach(
        (fieldsType, index) =>
          res[index] &&
          helperStore.setIssueCustomField({
            items: res[index] as IssueCustomFieldDto[],
            type: fieldsType,
          })
      );
    });
  };

  const handleLanguageChange = async (lngId: number) => {
    const newLanguage: string = appLanguagesArray.find((lng) => lng.id == lngId)?.short ?? "en";
    await i18n.changeLanguage(newLanguage);
    appStore.setAppLocale(newLanguage);
    document.body.setAttribute("lang", newLanguage);
    await authStore.authorizeWithRefreshToken();
    handleLoadCustomFields();
  };

  useEffect(() => {
    if (appStore.getAppLocale == null) {
      handleLanguageChange(currentLanguageId);
    }
  }, []);

  return (
    <>
      {!props.miniVariant && <SubheaderText text={t("ui:subheader.app_locale")} />}
      <Select
        size={props.miniVariant ? "middle" : "large"}
        style={{ minWidth: props.miniVariant ? "150px" : undefined }}
        value={currentLanguageId}
        fullWidth
        onChange={(value) => handleLanguageChange(Number(value))}
        {...props.selectProps}
      >
        {appLanguagesArray.map((lng) => (
          <SelectOption key={lng.id} value={lng.id}>
            {lng.name}
          </SelectOption>
        ))}
      </Select>
    </>
  );
}

export default observer(LanguageSwitcher);
