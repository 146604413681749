import React from "react";
import { ILocaleOption, ILocaleSwitcher } from "./LocaleSwitcher.interface";
import { LocalesKeys } from "../../../../../../constants/localesKeys";
import { Select, SelectOption } from "../../../../../uiKit";

export const LocaleSwitcher = (props: ILocaleSwitcher) => {
  const allLocales: ILocaleOption[] = [
    { id: 1, name: "English", key: LocalesKeys.en },
    { id: 2, name: "Русский", key: LocalesKeys.ru },
    { id: 3, name: "Українська", key: LocalesKeys.uk },
    { id: 4, name: "繁體中文", key: LocalesKeys.zh },
    // { id: 5, name: "Oʻzbek", key: LocalesKeys.uz },
    // { id: 6, name: "Español", key: LocalesKeys.es },
  ];

  return (
    <Select
      size="middle"
      disabled={props.disabled}
      value={props.currentLocale}
      style={{ width: 145 }}
      onChange={(locale) => props.onChangeLocale(locale)}
    >
      {allLocales.map((locale) => (
        <SelectOption key={locale.id} value={locale.key}>
          {locale.name}
        </SelectOption>
      ))}
    </Select>
  );
};
