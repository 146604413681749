import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getObjectFromTimeSpan } from "../../../../../../../helpers/dateFunctions";
import { removeLeadingZerosFromString } from "../../../../../../../helpers/stringFunctions";
import { useColorSchemeStyles } from "../../../../../../../hooks/useColorSchemeStyles";
import { colorSchemeType } from "../../../../../../../utils/colorScheme";
import { UserCard } from "../../../../../../elements/userCard/UserCard";
import { Card, Text } from "../../../../../../uiKit";
import { IPlanningEmployeeCard } from "./PlanningEmployeeCard.interface";
import "./PlanningEmployeeCard.scss";
import { EmployeeRolesList } from "../../../../employee/employeeRolesList/EmployeeRolesList";
import { formatDateToDateString } from "../../../../../../../helpers/formatFunctions";
import { Label } from "../../../../../../uiKit/label/Label";
import { Progress } from "../../../../../../uiKit/progress/Progress";

export const PlanningEmployeeCard = memo((props: IPlanningEmployeeCard) => {
  const { t } = useTranslation();
  const url = useLocation();
  const { findColorByKey } = useColorSchemeStyles();
  const colorScheme = findColorByKey(props.plan?.statusColorScheme as colorSchemeType);

  const calculateTimePlan = () => {
    return props.plan?.timePlanOverall != null
      ? Math.ceil((props.plan.timePlanOverallTotalMinutes as number) / 60)
      : 0;
  };

  const calculateTimeFact = () => {
    return (
      props.plan?.timeFactOverall != null &&
      removeLeadingZerosFromString(getObjectFromTimeSpan(props.plan?.timeFactOverall).totalHours!)
    );
  };

  // percent={(props.plan?.issuesDone! / props.plan?.issuesOverall!) * 100 ?? 0}
  // format={() => `${props.plan.issuesDone}/${props.plan.issuesOverall}`}
  // className="mt-2"
  // status={props.plan?.issuesDone! > props.plan?.issuesOverall! ? "exception" : "normal"}

  const issuePercent = useMemo(() => {
    return (props.plan?.issuesDone! / props.plan?.issuesOverall!) * 100 ?? 0;
  }, [props.plan?.issuesDone, props.plan?.issuesOverall]);

  const issueFormat = useCallback(() => {
    return `${props.plan.issuesDone}/${props.plan.issuesOverall}`;
  }, [props.plan?.issuesDone, props.plan?.issuesOverall]);

  const issueStatus = useMemo(() => {
    return props.plan?.issuesDone! > props.plan?.issuesOverall! ? "exception" : "normal"
  }, [props.plan?.issuesDone, props.plan?.issuesOverall]);

  const timePercent = useMemo(() => {
    return (Number(calculateTimeFact()) / Number(calculateTimePlan())) * 100 ?? 0
  }, [props.plan?.timeFactOverall, props.plan?.timePlanOverall, props.plan?.timePlanOverallTotalMinutes]);

  const timeFormat = useCallback(() => {
    return `${calculateTimeFact()}/${calculateTimePlan()}`;
  }, [props.plan?.timeFactOverall, props.plan?.timePlanOverall, props.plan?.timePlanOverallTotalMinutes]);

  const timeStatus = useMemo(() => {
    return calculateTimeFact() > calculateTimePlan() ? "exception" : "normal"
  }, [props.plan?.timeFactOverall, props.plan?.timePlanOverall, props.plan?.timePlanOverallTotalMinutes]);


  return (
    <Card
      // isShadowed={false}
      className={"planning-employee-card"}
      clickable
      onClick={() => props.onRedirectToPlan(props.plan.id!)}
    >
      <div className="d-stack align-center planning-subordinates-card__container">
        <div className="flex-grow-1 flex-shrink-1">
          <UserCard
            userInfo={props.plan.user}
            avatarSize={56}
            nameSize={16}
            lineHeightName={1.4}
            boldName
            fullWidth
            additionalInfo={
              <div className="mt-1">
                <EmployeeRolesList roles={props.plan.user?.roles ?? []} maxOrgchartsCount={4} />
                {url.pathname == "/communication/require-approval" ? (
                  <span style={{ color: "#8E939D" }}>{`${formatDateToDateString(
                    new Date(props.plan.dateFrom as string)
                  )} - ${formatDateToDateString(new Date(props.plan.dateTo as string))}`}</span>
                ) : null}
              </div>
            }
          />
        </div>
        <div className="flex-grow-0 flex-shrink-0" style={{ flexBasis: "20%" }}>
          <div className="d-stack-column">
            <Text>{t("ui:subheader.issues")}</Text>
            <Progress
              percent={issuePercent}
              format={issueFormat}
              status={issueStatus}
            />
          </div>
        </div>
        <div className="flex-grow-0 flex-shrink-0" style={{ flexBasis: "20%" }}>
          <div className="d-stack-column">
            <Text>{t("ui:subheader.time")}</Text>
            <Progress
              percent={timePercent}
              format={timeFormat}
              status={timeStatus}
            />
          </div>
        </div>
        <div className={"flex-grow-0 flex-shrink-0"}>
          <Label
            type={props.plan?.statusColorScheme as colorSchemeType}
            text={t(`ui:plan_status.type${props.plan?.status}`) ?? ""}
          />
        </div>
      </div>
    </Card>
  );
});
