export const carrotQuestLocaleZh = {
  thanks: "謝謝",
  dialogs_history: "聊天記錄",
  powered_by_text_before_logo: "技術支持&nbsp;來自",
  powered_by_text_after_logo: "",
  enter_message: "輸入訊息…",
  enter_email: "輸入電子郵件",
  enter_phone: "輸入電話號碼",
  leave_email: "您可以留下您的電子郵件，並通過郵件繼續對話：",
  page_title_new_message: "新訊息",
  privacy_policy: "隱私政策",
  errorFileType:
    "無效的文件格式。您可以上傳以下類型的文件：jpg、jpeg、png、gif、txt、pdf、rar、zip、html、csv、docx、doc、xlsx、xls、sig、mp4、rtf、mov、tiff、tif、m4v、mp3、mpeg。",
  errorFileSize: "文件過大<br/>最大文件大小為 10 MB。",
  errorManyRequests: "文件過多<br/>您上傳文件的頻率過高。請稍等片刻。",
  errorReplyTooBig: "訊息不能超過 20,000 個字元。",
  errorUserBan: "您已被封鎖<br/>您無法再發送新的聊天訊息。",
  error: "發生錯誤。<br/>上傳時出現問題，請重試。",
  close: "關閉",
  button_start_message: "撰寫訊息",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "開始或繼續在 {{networkName}} 上對話",
  social_network_page_desc_vk: [
    "點擊下方按鈕打開 VK 進入對話",
    "請向我們發送您的問題",
  ],
  social_network_page_desc_viber: [
    "點擊下方按鈕打開 Viber 進入對話",
    "請向我們發送您的問題",
  ],
  social_network_page_desc_telegram: [
    "點擊下方按鈕打開 Telegram 進入對話",
    "請點擊 Telegram 中的「Start」按鈕",
    "請向我們發送您的問題",
  ],
  social_network_page_desc_facebook: [
    "點擊下方按鈕打開 Facebook 進入對話",
    "請點擊 Facebook 中的「開始」按鈕",
    "請向我們發送您的問題",
  ],
  social_network_page_desc_instagram: [
    "使用下方按鈕開啟 Instagram 以進入指定頁面",
    "在 Instagram 點擊「發送訊息」按鈕",
    "請向我們提問",
  ],
  social_network_page_desc_whatsapp: [
    "使用下方按鈕開啟 WhatsApp 以進入對話",
    "發送預填寫的訊息",
    "請向我們提問",
  ],
  social_network_page_open_button: "開啟 {{networkName}}",
  social_network_qr_code_title: "掃描此 QR 碼，以從其他設備開啟 {{networkName}}",
  bumperText:
    '我們使用 cookies 來提升網站性能、分析流量和個性化內容。使用此網站或點擊「我同意」，即表示您同意我們的個資與 cookies 政策，詳情請參閱 <a href="{{privacy-policy-link}}" target="_blank">隱私政策</a>。',
  bumperButton: "我同意",
  confirmSubscriptionWithCheckbox:
    '我同意 <a href="{{subscription-policy}}" target="_blank">資料處理條款</a>',
  confirmSubscriptionWithoutCheckbox:
    '點擊按鈕即表示您同意 <a href="{{subscription-policy}}" target="_blank">資料處理條款</a>',
  appeal: "您",
  vote_comment: "發生了什麼？",
  vote_text: "請為客服人員的服務評分",
  vote_thanks: "感謝您的評價！",
  cart_button: "了解更多",
  cart_no_name: "無名稱",
  cart_zero: "購物車數據無法載入",
  article_link: "閱讀文章",
  to_knowledge_base: "在知識庫中尋找答案",
  typing_status: "正在輸入…",
  notification_enter_message: "輸入訊息…",
  notification_message_sending: "訊息發送中…",
  notification_message_sent: "訊息已發送",
  notification_file_sending: "檔案上傳中…",
  notification_file_sent: "檔案已發送",
  notification_typing_status: "正在輸入",
  history_zero_data_title: "我們還沒有對話記錄",
  history_zero_data_desc: "這裡將顯示您的對話歷史",
  history_group_collapse: "摺疊",
  history_section_actual: "最近",
  history_section_archive: "歷史",
  back_to_chat: "返回對話",
  integration_zoom_button_text: "加入",
  integration_zoom_tip: "☝️ 提示：您可以直接透過瀏覽器加入視訊會議",
  dateMonth: {
    "0": "一月",
    "1": "二月",
    "2": "三月",
    "3": "四月",
    "4": "五月",
    "5": "六月",
    "6": "七月",
    "7": "八月",
    "8": "九月",
    "9": "十月",
    "10": "十一月",
    "11": "十二月",
  },
  fileSize: {
    "0": "B",
    "1": "KB",
    "2": "MB",
    "3": "GB",
  },
  at: "於",
  edited: "已編輯",
  removed: "已刪除",
  removedMessage: "訊息已刪除",
  bot_answer_placeholder: "輸入回覆",
  bot_buttons_placeholder: "選擇回覆選項",
  connection_problem_toast: "<b>與聊天連線中斷。</b> 我們將自動嘗試重新連線。",
  input_error_email: "請輸入有效的電子郵件",
  input_error_phone: "請輸入有效的電話號碼",
  popup_close_all_button: "隱藏所有",
  offline: "離線",
  online: "在線",
  whatsapp_auth_text:
    "請發送此訊息給我們，以便將您的網頁聊天 ID：{{userId}} 移至 WhatsApp。如果您編輯或刪除此訊息，將會創建新的對話並與其他客服人員聯繫。",
  popup_desc: "彈出窗口",
  image_desc: "圖片",
  emoji_search_placeholder: "搜尋",
  emoji_zero_data: "未找到結果",
  emoji_category_people: "表情符號與人物",
  emoji_category_nature: "動物與自然",
  emoji_category_food: "食物與飲品",
  emoji_category_activity: "活動",
  emoji_category_places: "旅行與地點",
  emoji_category_objects: "物品",
  emoji_category_symbols: "符號",
  emoji_category_flags: "旗幟",
};

export const carrotAdditionalSettingsZh = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "我們在線上，隨時為您提供幫助",
  messenger_offline_message: "客服人員將專門為您連線。",
  messenger_welcome_message:
    "您好！您已聯繫 Business Booster Platform 技術支持。請描述您的問題，我們將盡快回覆您。感謝您的詢問！",
};
