import React, { MouseEvent, useMemo } from "react";
import "./EmployeeMainCard.scss";
import { UserDto } from "../../../../../api";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../hooks";
import { Avatar, Card, Chip, Dropdown, Text } from "../../../../uiKit";
import { DropdownMenu } from "../../../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../../../service/dropdownMenu/dropdownMenu.interface";
import { EmployeeRolesList } from "../employeeRolesList/EmployeeRolesList";
import { useUserNameFormat } from "../../../../../hooks/useUserNameFormat";

export interface IEmployeeCardMainView {
  isCardLoading?: boolean;
  employeeData: UserDto;
  handleCardClick: () => void;
  menuItems: PopupMenuItemTypes[];
  onChangeAccessType: (userId: number, type: number) => void;
  isDisabledChangeAccessType: boolean;
}

function EmployeeMainCardView(props: IEmployeeCardMainView) {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const { getFullName, getAvatarText } = useUserNameFormat();


  const handleChangeAccessType = (type: number) => {
    props.onChangeAccessType(props.employeeData.id!, type);
  }

  const items = useMemo(() => {
    return [
      {
        key: 0,
        text: t("parse:employee_type.full"),
        disabled: props.employeeData.currentAccessType === 0,
        onClick: (e: MouseEvent<HTMLSpanElement>) => {
          console.log('onClick', e);
          e.stopPropagation();
          handleChangeAccessType(0)
        },
      },
      {
        key: 1,
        text: t("parse:employee_type.limited"),
        disabled: props.employeeData.currentAccessType === 1,
        onClick: (e: MouseEvent<HTMLSpanElement>) => {
          e.stopPropagation();
          handleChangeAccessType(1)
        },
      },
    ]
  }, [props.employeeData.currentAccessType]);

  return (
    <Card
      onClick={props.handleCardClick}
      clickable
      variant="default"
      bodyStyle={{ padding: "8px" }}
      className={"employee-card"}
    >
      <div className="d-flex align-center">
        <Avatar
          // shape="square"
          text={getAvatarText(props.employeeData?.lastName ?? "", props.employeeData?.firstName ?? "")}
          src={props.employeeData?.avatar?.image?.url}
          color={props.employeeData.color}
          size={40}
          isOutlined={props.employeeData.id == authStore.getInitialInfo?.identity?.id}
        />
        <div className="ml-3 flex-grow-1">
          <div className="d-stack spacing-3 align-center">
            <Text style={{ fontSize: "14px" }}>

              {getFullName(
                props.employeeData?.lastName ?? "",
                props.employeeData?.firstName ?? "",
                props.employeeData?.middleName ?? "",
              ) ?? t("ui:placeholder.new_employee", {id: props.employeeData?.id ?? 0}) ?? ""}
            </Text>
            <span onClick={(e: MouseEvent<HTMLSpanElement>) => {
              !props.isDisabledChangeAccessType &&  e.stopPropagation()
            }}>
              <Dropdown
                items={items}
                trigger={["click"]}
                disabled={props.isDisabledChangeAccessType}
              >
                <span>
                  <Chip
                    type={props.employeeData.currentAccessType == 0 ? "default" : "warning"}
                    label={
                      props.employeeData.currentAccessType == 0
                        ? t("parse:employee_type.full")
                        : t("parse:employee_type.limited")
                    }
                  />
                </span>
              </Dropdown>
            </span>
          </div>
          {props.employeeData?.roles && props.employeeData?.roles?.length > 0 ? (
            <EmployeeRolesList roles={props.employeeData.roles}/>
          ) : (
            <div className="employee-card_roles-list d-flex">
              <Text style={{ fontSize: "12px" }}>{props.employeeData?.email}</Text>
            </div>
          )}
        </div>
        <DropdownMenu items={props.menuItems} />
      </div>
    </Card>
  );
}

export default EmployeeMainCardView;
