import React, { memo, useEffect, useMemo, useState } from "react";
import { Reactions } from "../../../../../../../elements/emojiPicker/Reactions";
import { ReactionsStrategy } from "../../../../../../../elements/emojiPicker/strategies/ReactionsStrategy";
import { HistoryDto, HistoryReactionDto } from "../../../../../../../../api";
import { api } from "../../../../../../../../services";
import "./IssueActivityActions.scss";
import { IIssueActivityActions } from "./IssueActivityActions.interface";
import { Icon, IconButton, Tooltip } from "../../../../../../../uiKit";
import { customEvent } from "../../../../../../../../helpers/eventBus";
import { useNotifier } from "../../../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { LucideCheck, LucideCheckCheck } from "lucide-react";

export const IssueActivityActions  = memo((props: IIssueActivityActions) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const reactionsStrategy = new ReactionsStrategy<HistoryDto>(
    props.issueHistoryId,
    api.issueHistory,
    (x: HistoryReactionDto) => x.value as string,
    (x: HistoryReactionDto) => x?.user?.name ?? x?.user?.nameFallback ?? ""
  );

  const isRecipients = useMemo(() => {
    return props.recipients.findIndex(({ userId }) => userId === props.userId) !== -1;
  }, [props.recipients]);

  const [isRead, setIsRead] = useState<boolean>(false);

  useEffect(() => {
    const { isRead } = props.recipients.find(({ userId }) => userId === props.userId) ?? {};
    setIsRead(!!isRead);
  }, []);


  const [isReadButtonLoading, setIsReadButtonLoading] = useState<boolean>(false);
  const handleUpdateIsReadStatus = async () => {
    setIsReadButtonLoading(true);
    const r = await api.issueHistory.setIsReadStatus(props.issueHistoryId!, { value: !isRead });
    if (r == null) {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
      return;
    }
    setIsRead(!!r.isRead);
    customEvent.dispatch("reloadIssueStatuses");
    setIsReadButtonLoading(false);
  };

  return (
    <div className="issue-activity-actions">
      <Reactions
        historyId={props.issueHistoryId ?? 0}
        reactions={props.reactions ?? []}
        strategy={reactionsStrategy}
        isShowFullEmoji
        isNewStyle
        iconSize={24}
      />
      {isRecipients && (
        <Tooltip
          open={isReadButtonLoading ? false : undefined}
          trigger={["hover"]}
          placement="topRight"
          title={isRead ? t("parse:mark_as_unread") : t("parse:mark_as_read")}
        >
          <IconButton
            type="text"
            loading={isReadButtonLoading}
            icon={
              <Icon
                component={() =>
                  isRead ? (
                    <LucideCheckCheck stroke="var(--color-primary-base)" />
                  ) : (
                    <LucideCheck stroke="var(--color-text-weaker)" />
                  )
                }
              />
            }
            onClick={handleUpdateIsReadStatus}
          />
        </Tooltip>
      )}
    </div>
  )
});
