import React from "react";
import "../../../../../components/modules/pages/orgchart/orgchartDrawerTabs/orgchartDrawerTabMetrics/OrgchartDrawerTabMetrics.scss";
import { Card, Icon, IconButton, Text } from "../../../../uiKit";
import { FiEdit2 } from "@react-icons/all-files/fi/FiEdit2";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { useTranslation } from "react-i18next";

interface IMetricProps {
  name: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
  isMain?: boolean;
}

export const Metric = (props: IMetricProps) => {
  const { t } = useTranslation();
  return (
    <Card variant="secondary" isShadowed={false}>
      <div className="d-stack-row align-center justify-space-between orgchart-drawer-tab_metrics-group_card">
        <div className="">
          <Text
            className="no-select"
            weight="bold"
            children={`${props.isMain ? `${t("ui:title.metric_prefix")} ` : ""}${props.name}`}
          />
        </div>
        <div className="d-stack-row">
          <IconButton
            style={{ background: "none" }}
            type="default"
            icon={<Icon component={() => <FiEdit2 />} />}
            onClick={props.onEditClick}
          />
          <IconButton
            style={{ background: "none" }}
            type="default"
            icon={<Icon component={() => <AiOutlineDelete />} />}
            onClick={props.onDeleteClick}
          />
        </div>
      </div>
    </Card>
  );
};
