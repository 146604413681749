import React from "react";
import { useRootStore } from "../../../../../hooks";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";
import ruContent from "../../../../../utils/guides-lexical/orgchart/ru.json";
import enContent from "../../../../../utils/guides-lexical/orgchart/en.json";
import ukContent from "../../../../../utils/guides-lexical/orgchart/uk.json";
import zhContent from "../../../../../utils/guides-lexical/orgchart/zh.json";

export const getContent = (localeKey: string | null): any | null => {
  if (localeKey?.includes("uz")) {
    return ruContent;
  }

  switch (localeKey) {
    case "ru":
      return ruContent;
    case "en":
      return enContent;
    case "uk":
      return ukContent;
    case "zh":
      return zhContent;
    default:
      return enContent;
  }
};

export const OrgchartGuide = () => {
  const { hintStore } = useRootStore();

  return (
    // <TextEditorOld readOnly={true} id="guide-dasboard" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
    <TextEditorLexical readOnly value={getContent(hintStore.getLang)} withoutBackground />
  );
};
