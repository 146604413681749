import React, { memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Text } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import "./AIAssistantContent.scss";
import {  useNotifier } from "../../../../../../hooks";
import { AIAssistantMessageItem } from "./AIAssistantMessageItem/AIAssistantMessageItem";
import { AIAssistantHeaderActions } from "./AIAssistantHeaderActions/AIAssistantHeaderActions";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { AIAssistantFooter } from "./AIAssistantFooter/AIAssistantFooter";
import type { RegulationAiHistoryDto } from "../../../../../../api";
import { usePagingReverseWithController } from "../../../../../../hooks/usePaging";
import { AIAssistantContext } from "../../../../../../contexts/AIAssistantContext";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";

export const AIAssistantContent = memo(() => {
  const notifier = useNotifier();

  const {
    controller,
    historyQueryURI,
    deleteHistoryMethod,
    itemId,
    reviewMethod,
    requestPlaceholder,
    handleCloseAIAssistant,
    onSaveContent,
  } = useContext(AIAssistantContext);

  const contentWrapper = useRef<HTMLDivElement>(null);

  const prevScrollHeightRef = useRef<number | null>(null);
  const prevScrollTopRef = useRef<number>(0);

  const [aiAssistantHistoryList, setAIAssistantHistoryList] = useState<RegulationAiHistoryDto[]>([]);
  const lastItemId = useMemo(() => {
    return aiAssistantHistoryList[0]?.id;
  }, [aiAssistantHistoryList]);


  const [isReviewLoading, setIsReviewLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isReviewLoading) {
      scrollContainer();
    }
  }, [isReviewLoading]);


  const [requestReview, setRequestReview] = useState<string>("");

  const handleReview = async (request: string) => {
    if (reviewMethod) {
      setIsReviewLoading(true);
      setRequestReview(request);
      if (onSaveContent) {
        await onSaveContent(true);
      }
      const resp = await (controller as any)[reviewMethod]({
        itemId,
        request,
      });
      if (resp) {
        setAIAssistantHistoryList((prev) => ([
          ...prev,
          resp,
        ]));
      }
      setRequestReview("");
      setIsReviewLoading(false);
    }
  };

  const handleLoadNext = () => {
    if (contentWrapper.current) {
      prevScrollHeightRef.current = contentWrapper.current.scrollHeight;
      prevScrollTopRef.current = contentWrapper.current.scrollTop;
    } else {
      prevScrollHeightRef.current = 0;
      prevScrollTopRef.current = 0;
    }
    aiAssistantHistory.loadNext();
  };

  const aiAssistantHistory = usePagingReverseWithController(
    controller,
    undefined,
    {
      pageSize: 10,
      lastItemId: lastItemId,
    },
    undefined,
    undefined,
    undefined,
    undefined,
    historyQueryURI,
  );

  const scrollContainer = (top?: number) => {
    if (contentWrapper.current) {
      contentWrapper.current.scrollTo({
        top: top ?? contentWrapper.current.scrollHeight,
      });
    }
  }

  const loadData = async () => {
    await aiAssistantHistory.restart();
    scrollContainer();
  }

  useEffect(() => {
    loadData();
  }, [historyQueryURI]);

  const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);
  const [isShowRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState<boolean>(false);
  const handleClickRemoveHistory = useCallback(async() => {
    setShowRemoveConfirmDialog(true);
  }, []);
  const handleCloseRemoveConfirmDialog = () => {
    setShowRemoveConfirmDialog(false);
  }

  const handleRemoveHistory = async () => {
    if (deleteHistoryMethod) {
      setIsRemoveLoading(true);
      const resp = await (controller as any)[deleteHistoryMethod](itemId);
      if (resp !== null) {
        setAIAssistantHistoryList([]);
        handleCloseRemoveConfirmDialog();
        notifier.show({ message: t("notifier:success.remove_ai_assistant_history"), theme: "success" });
      } else {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

      }
      setIsRemoveLoading(false);
    }
  }

  const { t } = useTranslation();

  const [isUpdateScrollPosition, setIsUpdateScrollPosition] = useState<boolean>(false);

  useEffect(() => {
    setAIAssistantHistoryList(aiAssistantHistory.items as RegulationAiHistoryDto[] ?? []);
    setIsUpdateScrollPosition(true);
  }, [aiAssistantHistory.items]);

  useEffect(() => {
    if (isUpdateScrollPosition && contentWrapper.current && prevScrollHeightRef.current) {
      const currentScrollHeight = contentWrapper.current.scrollHeight;
      const scrollHeightDifference = currentScrollHeight - prevScrollHeightRef.current;
      scrollContainer(scrollHeightDifference);
      setIsUpdateScrollPosition(false);
    }
  }, [isUpdateScrollPosition, contentWrapper.current, prevScrollHeightRef.current]);

  const aiAssistantContent = useRef<HTMLDivElement>(null);

  const updateHeight = (event?: Event) => {
    if (aiAssistantContent.current) {
      const target = event?.target as HTMLElement;
      const parentElement = target ?? document.getElementById("pageContent");
      const containerElement = document.getElementById("ai-assistant-container");
      const { top: containerTop = 0 } = containerElement?.getBoundingClientRect() ?? {};

      const { scrollTop } = parentElement;
      const height = parentElement?.clientHeight + (scrollTop > 48 ? 12 : 0);
      const { top: parentTop } = parentElement.getBoundingClientRect();
      const offset = scrollTop > 48 ? 48 : (containerTop - parentTop);
      aiAssistantContent.current.style.height = height ? `${height - offset + 8}px` : "auto";
      // aiAssistantContent.current.style.position = scrollTop > 48 ? "fixed" : "sticky";
      aiAssistantContent.current.style.top = scrollTop > 48 ? `${parentTop + 48}px` : `${containerTop}px`;
    }
  };

  useLayoutEffect(() => {
    updateHeight();
    const handleResize = () => updateHeight();
    window.addEventListener('resize', handleResize);

    const parentElement = document.getElementById("pageContent");
    if (parentElement) {
      parentElement.addEventListener('scroll', updateHeight);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (parentElement) {
        parentElement.removeEventListener("scroll", updateHeight);
      }
    };
  }, []);


  return (
    <>
      <CustomConfirmDialog
        open={isShowRemoveConfirmDialog}
        title={t("ui:regulation_actions.ai_assistant.remove_confirm_text")}
        buttonText={{
          confirm: t("ui:regulation_actions.ai_assistant.remove_confirm_button"),
          confirmTheme: "error",
        }}
        loading={isRemoveLoading}
        onConfirm={handleRemoveHistory}
        onClose={handleCloseRemoveConfirmDialog}
        isNewDesign
      />
      <div
        className="ai-assistant-content"
        ref={aiAssistantContent}
      >
        <div className="ai-assistant-content-header">
          <div className="ai-assistant-content-header__title">
            <Text
              size={"16px"}
              weight={"bold"}
            >
              {t("ui:regulation_actions.ai_assistant.button")}
            </Text>
          </div>
          <AIAssistantHeaderActions
            onRemoveHistory={handleClickRemoveHistory}
            onCloseAIAssistant={handleCloseAIAssistant}
          />

        </div>
        <div
          className="ai-assistant-content-wrapper"
          ref={contentWrapper}
        >
          <ScrollTrigger
            hidden={aiAssistantHistory.info.isDone}
            onIntersection={handleLoadNext}
          />
          <div className="ai-assistant-content-content-history-list">
            {aiAssistantHistoryList.map((item) => (
              <AIAssistantMessageItem
                key={item.id}
                item={item}
                requestPlaceholder={requestPlaceholder}
              />
            ))}
            {isReviewLoading && (
              <AIAssistantMessageItem
                item={{
                  request: requestReview,
                }}
                isReviewLoading={true}
                requestPlaceholder={requestPlaceholder}
              />
            )}
          </div>
        </div>
        <div className="ai-assistant-content-footer">
          <AIAssistantFooter
            onReview={handleReview}
            buttonText={requestPlaceholder}
            isLoading={isReviewLoading}
          />
        </div>
      </div>
    </>
  )
});
