import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Pages.scss";
import "./RegulationHeader.scss";
import "./RegulationRoter.scss";
import { IRegulationRouter } from "./RegulationRouter.interface";
import { breadcrumbKeys } from "../misc/breadcrumbsKeys";
import {
  CreateRegulation,
  CreateSection,
  MainRegulation,
  Regulation,
  RegulationUserStats,
  Section,
} from "../containers";
import Error404Page from "../../../../pages/error404/Error404Page";
import { RegulationSidebar } from "../components/regulationSidebar/RegulationSidebar";
import { GlobalAuthorizedContext } from "../../../../../contexts/globalAuthorizedContext";
import { useLocalStorage } from "../../../../../hooks";
import { CreateRegulationWrapper } from "../containers/CreateRegulationWrapper/CreateRegulationWrapper";
import { CreateSectionWrapper } from "../containers/CreateSectionWrapper/CreateSectionWrapper";

export const RegulationRouter: React.FC<IRegulationRouter> = (props) => {
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage<boolean>("isRegulationSidebarOpen", true);
  const [isSidebarResizing, setIsSidebarResizing] = useState<boolean>(false);
  const [sidebarSize, setSidebarSize] = useLocalStorage("regulationSidebarSize", {
    width: 300,
  });

  const toggleSidebarOpen = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  const handleGetPageByBreadcrumbKey = (): JSX.Element => {
    switch (props.routeToken!.key) {
      case breadcrumbKeys.createRegulation:
      return <CreateRegulationWrapper/>;
      case breadcrumbKeys.createSection:
        return <CreateSectionWrapper />;
      case breadcrumbKeys.sectionView:
        return <CreateSectionWrapper isView={true}/>;
        // <Section />;
      case breadcrumbKeys.regulationView:
        return <CreateRegulationWrapper isView={true}/>;
      case breadcrumbKeys.regulationTest:
        return <Regulation isTestPass />;
      case breadcrumbKeys.statsOverall:
        return <RegulationUserStats />;
      case breadcrumbKeys.statsUser:
        return <RegulationUserStats userStats />;
      default:
        return <Error404Page />;
    }
  };

  useEffect(() => {
    globalAuthorizedContext?.setState({
      ...globalAuthorizedContext,
      regulation: {
        ...globalAuthorizedContext?.regulation,
        isSidebarOpen: isSidebarOpen,
        // isSidebarOpen: isSidebarOpen,
        // onSidebarOpenChange: () =>
        //   globalAuthorizedContext?.setState({
        //     ...globalAuthorizedContext,
        //     regulation: {
        //       ...globalAuthorizedContext?.regulation,
        //       isSidebarOpen: !globalAuthorizedContext?.regulation?.isSidebarOpen,
        //     },
        //   }),
        onSidebarOpenChange: () => setIsSidebarOpen((v) => !v),
      },
    });
  }, []);

  useEffect(() => {
    globalAuthorizedContext?.setState({
      ...globalAuthorizedContext,
      regulation: {
        ...globalAuthorizedContext?.regulation,
        isSidebarOpen: isSidebarOpen,
        onSidebarOpenChange: () => setIsSidebarOpen((v) => !v),
      },
    });
  }, [isSidebarOpen]);

  return (
    <div
      className="d-stack-row ml-n2 my-n2 full-height"
      style={{ overflow: "hidden", width: "calc(100% + 8px)", height: "calc(100% + 16px)" }}
    >
      <div
        className={isSidebarOpen ? "regulation-sidebar_visible" : "regulation-sidebar_hidden"}
        style={{
          width: isSidebarResizing ? "auto" : isSidebarOpen ? (sidebarSize.width ?? 300) + "px" : 0,
          transition: isSidebarResizing ? "none" : "width 0.15s",
        }}
      >
        <RegulationSidebar
          isSidebarOpen={true}
          sidebarSize={sidebarSize}
          onSidebarOpenChange={toggleSidebarOpen}
          onSidebarSizeChange={setSidebarSize}
          onSidebarResizing={setIsSidebarResizing}
        />
      </div>
      <div className="flex-grow-1 d-flex flex-column pb-2 regulation-router" style={{ overflow: "hidden" }}>
        {props.routeToken && !props.routeToken.isMainPage
          ? handleGetPageByBreadcrumbKey()
          : !props.noTabs && (
              <MainRegulation
                tabs={props.tabs}
                isSidebarOpen={isSidebarOpen}
                onSidebarOpenChange={() => setIsSidebarOpen((v) => !v)}
              />
            )}
      </div>
    </div>
  );
};
