import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./ListCards.scss";
import { CardRegulation } from "../CardRegulation/CardRegulation";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { IListRegulationCards, paginationTypeKeys, sortedType } from "./ListCards.interface";
import { useTranslation } from "react-i18next";
import { Button, Table } from "../../../../../uiKit";
import dayjs from "dayjs";
import { DocIcon, FolderOpenIcon, StarIcon, StarIconWithoutBackground } from "../../../../../../constants/icon";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";
import { ActionButton } from "../ButtonIcons/ActionButton";
import { useSectionHooks } from "../../hooks/useSectionHooks";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "../../../../../../hooks";
import type { ColumnsType } from "antd/es/table";
import { ISortTableDataType } from "../../../../../elements/SortTable/SortTable.interface";
import { FAVORITE_TABLE_COLUMNS, REGULATION_TABLE_COLUMNS } from "../../../../../../constants/regulationTableColumns";
import { defineAllow } from "../../containers/regulation/helpers";
import { allowsRegulationKeys } from "../../misc/consts";
import { SelectApproverDialog } from "../../../../dialogs/selectApproverDialog/SelectApproverDialog";
import "./ListRegulations.scss";
import clsx from "clsx";
import { NoAccessResource } from "../../../../dialogs/noAccessResource/NoAccessResource";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { TablePaginationConfig } from "antd/lib";

export const ListRegulations = ({
  regulations,
  isDone,
  loadNext,
  paginationType,
  isLoading,
  isShowFavorites,
  onChangeSort,
  sorted,
}: IListRegulationCards) => {
  const navigate = useNavigate();
  const { authStore } = useRootStore();

  const { t } = useTranslation();
  const {
    handleSubmitForPublish,
    handleSubmitForReject,
    getStatusCell,
    getLink,
    onAppointApprover,
    handleClickCopyLink,
    handleToggleFavorites,
  } = useSectionHooks();

  const { regulationStore } = useRootStore();

  const handleGoToItem = useCallback((id: number, isRegulation: boolean) => {
    const link = getLink(id, isRegulation);
    navigate(link);
  }, []);


  const onSubmitForPublish = useCallback(async (id: number, resetTestResults?: boolean) => {
    const status = await handleSubmitForPublish(id, resetTestResults);
    // if (status !== null) {
    //   await fetchSection(section.id);
    // }
  }, []);

  const onSubmitForReject = useCallback(async (id: number) => {
    const status = await handleSubmitForReject(id);
    // if (status !== null) {
    //   await fetchSection(section.id);
    // }
  }, []);

  const [itemId, setItemId] = useState<number | null>(null);
  const [isShowPublishWithoutApprovalCheckbox, setIsShowPublishWithoutApprovalCheckbox] = useState<boolean>(false);
  const [isShowApproverDialog, setIsShowApproverDialog] = useState<boolean>(false);


  const handleSubmitForApproval = useCallback((id: number, isWithoutApproval: boolean) => {
    setItemId(id);
    setIsShowPublishWithoutApprovalCheckbox(isWithoutApproval);
    setIsShowApproverDialog(true);
  }, []);

  const handleCloseApproverDialog = useCallback(() => {
    setIsShowApproverDialog(false);
    setItemId(null);
    setIsShowPublishWithoutApprovalCheckbox(false);
  }, []);

  const handleAppointApprover = useCallback(async (approverId, resetTestResults, isPublishWithoutApproval, urgency) => {
    console.log("isPublishWithoutApproval", isPublishWithoutApproval);
    if (itemId) {
      if (isPublishWithoutApproval) {
        await onSubmitForPublish(itemId, resetTestResults)
      } else {
        await onAppointApprover(itemId, approverId, resetTestResults, urgency);
      }
      regulationStore.initialFetchRegulations(true);
      handleCloseApproverDialog();
      // handleCloseApproverDialog();
    }
  }, [itemId]);

  const sectionChilds = useMemo(() => {
    // favorites
    return  (regulations ?? [])
      // .sort((a, b) => a.order - b.order)
      .map((item) => {
        const {
          regulationId,
          stats,
          createdByUser,
          dateCreated,
          id,
          isFavorite,
          regulation,
        } = item;
        const { studiedPercent } = stats ?? {};
        const {
          createdByUser: createdByUserRegulation,
          dateCreated: dateCreatedRegulation,
          datePublish,
          state,
          actions = [],
        } = regulation ?? {};

        const isWithoutApproval = defineAllow(allowsRegulationKeys.publish, actions);

        const date = regulationId ? datePublish ?? dateCreatedRegulation : dateCreated;
        const publicationDate = dayjs(date)
          .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
          .format("LL");
        return {
          ...item,
          favorites: (
            <div
              className={clsx(
                "section-content-cell-favorites",
                {
                  "section-content-cell-favorites_active": isFavorite,
                }
              )}
              onClick={() => handleToggleFavorites(regulationId ? regulationId : item.id, !!regulationId, isFavorite)}
            >
              {
                isFavorite
                  ? (<StarIcon size={16}/>)
                  : (<StarIconWithoutBackground />)
              }
            </div>
          ),
          statusCell: getStatusCell(regulationId ? state : studiedPercent, !!regulationId),
          name: (
            <div
              className="section-content-cell-title"
              onClick={() => handleGoToItem(regulationId ?? id, !!regulationId)}
            >
              <div className="section-content-cell-title__icon">
                {regulationId ? (<DocIcon />) : (<FolderOpenIcon size={20} />)}
              </div>
              { item.name }
            </div>
          ),
          user: (
            <UserInfo
              nameStyle={{ fontWeight: "bold" }}
              avatarSize={25}
              user={regulationId ? createdByUserRegulation : createdByUser }
            />
          ),
          publicationDate,
          action: (
            <ActionButton
              isRegulation={true}
              isSubmitForApproval={defineAllow(allowsRegulationKeys.requestPublication, actions)}
              isCopyLink={true}
              isSubmitForPublish={defineAllow(allowsRegulationKeys.publish, actions)}
              isSubmitForReject={defineAllow(allowsRegulationKeys.reject, actions)}
              onSubmitPublish={() => onSubmitForPublish(regulationId ?? item.id)}
              onSubmitReject={() => onSubmitForReject(regulationId ?? item.id)}
              onSubmitForApproval={() => handleSubmitForApproval(regulationId ?? item.id, isWithoutApproval)}
              onCopyLink={() =>
                handleClickCopyLink(regulationId ?? id, true)
              }
              />
          ),

        };
      });
  }, [regulations, regulations.length]);

  const sorter = useCallback((a: any, b: any) => {
    return 0;
  }, []);

  const columns: ColumnsType<ISortTableDataType> = useMemo(() => {
    const columnsList = isShowFavorites
      ? [FAVORITE_TABLE_COLUMNS, ...REGULATION_TABLE_COLUMNS]
      : REGULATION_TABLE_COLUMNS;


    return columnsList.map((item) => {
      return {
        ...item,
        title: t(item.title),
        // @ts-ignore
        sorter: item.sortedKey ? sorter : undefined,
        // @ts-ignore
        sortOrder: item.sortedKey === sorted?.orderBy ? sorted?.order : undefined,
        // title: item.icon ?? t(item.title),
      }
    });
  }, [isShowFavorites, sorted]);
  const noAccessData = useMemo(() => {
    return regulationStore.getNoAccessData;
  }, [regulationStore.getNoAccessData]);

  const [isShowNoAccessResource, setIsShowNoAccessResource] = useState<boolean>(false);

  const handleCloseNoAccessResource = useCallback(() => {
    setIsShowNoAccessResource(false)
    regulationStore.setNoAccessData(null);
  }, []);

  useEffect(() => {
    if (noAccessData) {
      setIsShowNoAccessResource(true);
    }
  }, []);


  const handleChangeSort = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[]) => {
    const { column, order } = sorter as SorterResult<any>;
    // @ts-ignore
    const orderBy = column?.sortedKey as string;
    onChangeSort?.({orderBy, order});
  }

  return (
    <>
      {isShowApproverDialog && (
        <SelectApproverDialog
          withCheckbox
          // withUrgency
          isShowPublishWithoutApprovalCheckbox={isShowPublishWithoutApprovalCheckbox}
          open={isShowApproverDialog}
          onClose={handleCloseApproverDialog}
          onSuccess={handleAppointApprover}
        />
      )}
      { isShowNoAccessResource && (
        <NoAccessResource
          item={noAccessData!}
          isOpen={isShowNoAccessResource}
          onClose={handleCloseNoAccessResource}
        />
      )}
      <div className="list-regulations-wrapper">
        <Table
          pagination={false}
          dataSource={sectionChilds}
          columns={columns}
          isNewStyle={true}
          rowKey={(item) => {
            return item.regulationId ? `${item.id}-${item.regulationId}` : `${item.id}`
          }
          }
          onChange={handleChangeSort}
        />

        {regulations.length !== 0 && (
          <>
            {paginationType === paginationTypeKeys.byScroll && (
              <ScrollTrigger
                fullWidth
                disabled={isDone}
                onIntersection={loadNext ?? function() {
                }}
                marginTop={regulations.length > 0}
                hidden={isDone}
              />
            )}
            {paginationType === paginationTypeKeys.byButton && !isDone && (
              <Button
                variant="text"
                style={{ marginTop: "16px", marginBottom: "8px" }}
                size="large"
                loading={isLoading}
                onClick={loadNext}
              >
                {t("parse:more")}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};
