import "./TextEditor.scss";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import TableOfContentsPlugin from "@lexical/react/LexicalTableOfContents";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import * as React from "react";
import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSharedHistoryContext } from "./context/SharedHistoryContext";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin/AutoLinkPlugin";
import ClickableLinkPlugin from "./plugins/ClickableLinkPlugin/ClickableLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin/CodeHighlightPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin/ImagesPlugin";
import LinkPlugin from "./plugins/LinkPlugin/LinkPlugin";
import MarkdownShortcutPlugin from "./plugins/MarkdownShortcutPlugin/MarkdownShortcutPlugin";
import { NewMentionsPlugin } from "./plugins/MentionsPlugin/MentionsPlugin";
import TabFocusPlugin from "./plugins/TabFocusPlugin/TabFocusPlugin";
import TableCellActionMenuPlugin from "./plugins/TablePlugin/TableActionMenuPlugin/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TablePlugin/TableCellResizer/TableCellResizer";
import YouTubePlugin from "./plugins/YouTubePlugin/YouTubePlugin";
import ContentEditable from "./ui/ContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { Spin, Text, TextArea } from "../../uiKit";
import { ToolbarPlugin } from "./plugins/ToolbarPlugin/ToolbarPlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin/DraggableBlockPlugin";
import AnchorPlugin from "./plugins/AnchorPlugin/AnchorPlugin";
import ActionsPlugin from "./plugins/ActionPlugin/ActionPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { $getSelection, $isRangeSelection, EditorState, LexicalEditor } from "lexical";
import { ToolbarType } from "./types/types";
import EmojisPlugin from "./plugins/EmojisPlugin/EmojiPlugin";
import MentionToolbarPlugin from "./plugins/MentionsPlugin/MentionsToolbarPlugin";
import VideoPlugin from "./plugins/VideoPlugin/VideoPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import useEditable from "./hooks/useEditable";
import { useElementSize, useOutsideClick } from "../../../hooks";
import DragDropPaste from "./plugins/DragDropPastePlugin/DragDropPastePlugin";
import { ITextEditor } from "./TextEditor";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import clsx from "clsx";
import { AIAssistantContent } from "../pages/regulation/components/AIAssistantContent/AIAssistantContent";
// import GlobalEventsPlugin from "./plugins/GlobalEventsPlugin/GlobalEventsPlugin";
import { CSSTransition } from 'react-transition-group';


export interface ITextEditorView extends ITextEditor {
  editor: LexicalEditor;
  isLoading: boolean;
  isLoadingChange: (isLoading: boolean) => void;
  isVisibleToolbar: boolean;
  setIsVisibleToolbar: (value: boolean) => void;
  isActivated: boolean;
  setIsActivated: (value: boolean) => void;
  isShowEditorTitle?: boolean;
  titlePlaceholder?: string;
  titleValue?: string;
  titleError?: boolean;
  onChangeTitle?: (value: string) => void;
  toolbar?: ReactNode;
  isShowBorder?: boolean;
  isTransparent?: boolean;
  isShowAIContent?: boolean;
}

export const TextEditorView = memo((props: ITextEditorView) => {
  const { historyState } = useSharedHistoryContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [toolbarWrapperRef, { height }] = useElementSize();
  const clickRef = useRef<any>();

  const editMode = useEditable();

  useEffect(() => {
    props.editor.setEditable(!props.readOnly);
  }, [props.editor, props.readOnly]);

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  // const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);

  const onRef = useCallback((_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  }, []);

  const handleValueChange = useCallback(
    (value: EditorState) => {
      props.onChange?.(JSON.stringify(value));
    },
    [props]
  );

  // useEffect(() => {
  //   const updateViewPortWidth = () => {
  //     const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;
  //
  //     if (isNextSmallWidthViewport !== isSmallWidthViewport) {
  //       setIsSmallWidthViewport(isNextSmallWidthViewport);
  //     }
  //   };
  //
  //   window.addEventListener("resize", updateViewPortWidth);
  //
  //   return () => {
  //     window.removeEventListener("resize", updateViewPortWidth);
  //   };
  // }, [isSmallWidthViewport]);

  const handleBlur = useCallback(() => {
    props.isHidingByClick && props.setIsVisibleToolbar(false);
  }, [props]);

  useOutsideClick(clickRef, handleBlur);

  const handleOnBlur = useCallback(() => {
    if (props.onBlur) {
      props.onBlur()
    }
    // setIsActivated(false);
    // props.setIsVisibleToolbar(false);
  }, [props.onBlur])

  const handleClick = useCallback(() => {
    if (props.disabled) return;
    props.setIsVisibleToolbar(true);
    props.setIsActivated(true);
  }, [props]);

  const placeholder = (
    <Text className="text-editor-placeholder">{props.placeholder || t("ui:placeholder.text_editor_placeholder")}</Text>
  );

  const contentEditable = (
    <Spin spinning={props.isLoading}>
      <div className="editor-scroller">
        <div className="editor" ref={onRef}>
          <ContentEditable height={props.height} editMode={editMode} disabled={props.disabled} />
        </div>
      </div>
    </Spin>
  );

  const getToolbarByType = () => {
    switch (props.toolbarType) {
      case ToolbarType.Dynamic:
        return (
          <ToolbarPlugin
            isSimplifiedToolbar={props.isSimplifiedToolbar}
            isLoadingChange={props.isLoadingChange}
            isNewStyle={props.variant === 'new-style'}
            toolbar={props.toolbar}
            issueId={props.issueId}
            // className="dynamicToolbar"
          />
        );
      case ToolbarType.Hidden:
        return null;
      default:
        return (
          <ToolbarPlugin
            isSimplifiedToolbar={props.isSimplifiedToolbar}
            isLoadingChange={props.isLoadingChange}
            isNewStyle={props.variant === 'new-style'}
            toolbar={props.toolbar}
            issueId={props.issueId}
          />
        );
    }
  };

  const handleRenderToolbar = () => {
    if (editMode) {
      return getToolbarByType();
    }
    return null;
  };

  // useEffect(() => {
  //   props.editor.registerCommand(
  //     BLUR_COMMAND,
  //     () => {
  //       console.log("blur");
  //       props.onBlur?.();
  //       return true;
  //     },
  //     COMMAND_PRIORITY_EDITOR
  //   );
  // }, [props]);

  const [editor] = useLexicalComposerContext();
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      editor.focus();
    }
  }

  const marginTop = useMemo(() => {
    if (props.toolbarType !== ToolbarType.Dynamic) {
      return 0;
    }
    const margin = props.isShowBorder ? -6 : 0;
    return !props.isVisibleToolbar ? -height : margin;
  }, [props.toolbarType, props.isVisibleToolbar, height, props.isShowBorder]);

  return (
    <div
      ref={clickRef}
      // style={props.isSimplifiedToolbar ? { overflow: "hidden" } : { overflow: undefined }}
      style={props.isActivated ? { overflow: "visible" } : { overflow: "hidden" }}
      // className={`editor-shell ${editMode ? "" : "readonly-mode"}`}
      className={clsx(`editor-shell d-flex flex-column ${"variant__" + props.variant ?? ""}`, {
        "readonly-mode": !editMode,
        "editor-shell_not-active": !props.isActivated,
        "editor-shell_transparent": props.isTransparent,
        "editor-shell_show-border": props.isShowBorder,
      })}
      onClick={handleClick}
      onBlur={handleOnBlur}
    >
      <div
        ref={toolbarWrapperRef}
        style={{
          position: props.toolbarType == ToolbarType.Dynamic && !props.isVisibleToolbar ? "static" : "sticky",
          top: pathname === "/edit-content" ? -8 : 0,
          zIndex: 1,
          marginTop: marginTop,
          opacity: props.toolbarType == ToolbarType.Dynamic && !props.isVisibleToolbar ? 0 : 1,
          transition: !props.isActivated ? undefined : "all 0.2s",
        }}
      >
        {handleRenderToolbar()}
      </div>
      <div
        className="d-flex"
      >
        <div
          className={clsx("editor-container", {
            "plain-text": !editMode,
            "container-background": !props.withoutBackground,
          })}
          // style={editMode ? undefined : { cursor: "default" }}
          style={editMode ? {overflow: "auto"} : { cursor: "default" }}
        >
          {props.isShowEditorTitle && (
            <TextArea
              className={"editor-title"}
              placeholder={props.titlePlaceholder}
              isHiddenBorder={true}
              value={props.titleValue}
              status={props.titleError ? "error" : ""}
              onKeyDown={handleKeyDown}
              onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => {
                props.onChangeTitle?.(e.currentTarget.value)
              }}
            />
          )}
          <div className="editor-content">
            <OnChangePlugin onChange={handleValueChange} />
            {/*<EditorBlurPlugin onBlur={props.onBlur} editor={props.editor} />*/}
            {/*<AutoFocusPlugin />*/}
            <AutoLinkPlugin />
            {editMode ? (
              <>
                {/*<TableOfContentsPlugin>*/}
                {/*  {(tableOfContentsArray, ...arg) => {*/}
                {/*    console.log("arg", arg);*/}
                {/*    console.log("MyCustomTableOfContetsPlugin", tableOfContentsArray);*/}
                {/*    return <div>123</div>;*/}
                {/*  }}*/}
                {/*</TableOfContentsPlugin>*/}
                <HistoryPlugin externalHistoryState={historyState} />
                <RichTextPlugin
                  contentEditable={contentEditable}
                  placeholder={placeholder}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <MarkdownShortcutPlugin />
                <CodeHighlightPlugin />
                <ListPlugin />
                <CheckListPlugin />
                <TablePlugin />
                <DragDropPaste isLoadingChange={props.isLoadingChange} />
                <ClearEditorPlugin />
                <TableCellResizer />
                <NewMentionsPlugin
                  issueId={props.issueId}
                />
                <MentionToolbarPlugin />
                <ImagesPlugin />
                <EmojisPlugin />
                <LinkPlugin />
                <YouTubePlugin />
                <AnchorPlugin />
                <VideoPlugin />
                <ClickableLinkPlugin />
                <HorizontalRulePlugin />
                <TabFocusPlugin />
                <TabIndentationPlugin />
                {/*{ props.onSave ? (*/}
                {/*  <GlobalEventsPlugin*/}
                {/*    onSave={props.onSave}*/}
                {/*  />*/}
                {/*) : null }*/}
                {floatingAnchorElem && !props.disabled && (
                  <>
                    <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                    <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
                    {/*{!props.isSimplifiedToolbar && <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />}*/}
                  </>
                )}
                {pathname === "/edit-content" && <ActionsPlugin />}
              </>
            ) : (
              <>
                <PlainTextPlugin
                  contentEditable={<ContentEditable />}
                  placeholder={placeholder}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <HistoryPlugin externalHistoryState={historyState} />
              </>
            )}
          </div>
        </div>
        <div
          className="editor-ai-container"
          id="ai-assistant-container"
          style={{
            width: props.isShowAIContent ? 557: 0,
            minWidth: props.isShowAIContent ? 557: 0,
          }}
        >
          <CSSTransition
            in={props.isShowAIContent}
            timeout={300}
            classNames="slide"
            unmountOnExit
          >
            <AIAssistantContent/>
          </CSSTransition>
        </div>

      </div>
    </div>
  );
});
