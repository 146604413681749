import React from "react";
import { ButtonView } from "./ButtonView";
import { ButtonProps } from "antd/lib/button";

export type ButtonVariant = "filled" | "outlined" | "text" | "link" |  "default";

export interface IButton extends ButtonProps {
  theme?: "error" | "success" | "warning" | "info";
  variant?: ButtonVariant;
  allowTextWrap?: boolean;
  isActiveFilter?: boolean;
}

export function Button(props: IButton) {
  return <ButtonView {...props} />;
}
