import React, { useMemo } from "react";
import { Dropdown, Icon, IconButton, Text } from "../../../../../../uiKit";
import { IoEllipsisHorizontalOutline, IoCloseOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import "./AIAssistantHeaderActions.scss";
import { AIAssistantHeaderActionsProps } from "./AIAssistantHeaderActions.interface";

export const AIAssistantHeaderActions = (props: AIAssistantHeaderActionsProps) => {
  const { t } = useTranslation();

  const actionItems = useMemo(() => {
    return [
      {
        id: 1,
        text: t("ui:regulation_actions.ai_assistant.remove_history"),
        onClick: props.onRemoveHistory,
      },
    ]

  }, []);

  return (
    <div className="ai-assistant-header-actions">
      <Dropdown
        placement={"bottomRight"}
        rootClassName={"ai-assistant-header-action-drop-down"}
        trigger={["click"]}
        items={actionItems}
      >
        <IconButton
          icon={<Icon component={() => (<IoEllipsisHorizontalOutline size={22}/>)} />}
          isShowBorder={false}
          size={"small"}
        />
      </Dropdown>
      <IconButton
        size={"small"}
        icon={<Icon component={() => (<IoCloseOutline size={22}/>)} />}
        onClick={props.onCloseAIAssistant}
      />
    </div>
  )
}
