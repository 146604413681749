import React, { createRef, memo, useEffect, useRef, useState } from "react";
import FileUploaderView from "./FileUploaderView";
import { api } from "../../../services";
import { useNotifier, useRootStore } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { StaticFileDto } from "../../../api";
import { ImgsViewerImageType } from "react-images-viewer";
import { FilesViewerFileType } from "../fileViewer/FileViewer";
import { getFileExtensionByName } from "../../../helpers/fileFunctions";
import { observer } from "mobx-react-lite";
import axios from "axios";

interface IFileUploader {
  value?: StaticFileDto[];
  requiredNumber?: number;
  hideInfo?: boolean;
  hideButton?: boolean;
  containerClass?: string;
  uploadFilesInputRef?: any;
  accept?: string;
  additionallyCountImg?: number;
  filesContainerMaxHeight?: string;
  relatedInputId?: string;
  noDragAndDrop?: boolean;
  maxSizeMb?: number;
  readonly?: boolean;
  smallSize?: boolean;
  noDelete?: boolean;
  onUploadStatusChange?: (isLoading: boolean) => void;
  onUpload?: (value: StaticFileDto[]) => void;
  onChange?: (value: StaticFileDto[]) => void;
  onValueItemDelete?: (fileId: number) => void;
  onAnyItemDelete?: (fileId: number) => void;
}

export type TFileUploader = {
  fId: number;
  file?: File;
  // order
  // isImage: boolean;
  fileFromValue?: boolean;
  progress: number;
  uploadedFile: StaticFileDto;
};

export type TFileLoadingInfo = {
  fId: number;
  progress: number;
};

// TODO: Добавить readonly-файлы, отображающиеся списком
function FileUploader(props: IFileUploader) {
  const uploadFilesInputRef = props.uploadFilesInputRef != null ? props.uploadFilesInputRef : createRef();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  // DO NOT use setFiles
  const [files, setFiles] = useState<TFileUploader[]>([]);
  const filesCopy = useRef<TFileUploader[]>([]);
  // let filesCopy: TFileUploader[] = [];

  // USE setFilesSync
  const setFilesSync = (v: TFileUploader[], emitOnUpload?: boolean) => {
    setFiles(v);
    filesCopy.current = v;
    emitOnUpload &&
      props.onUpload != null &&
      props.onUpload(
        v
          .filter((ff) => !ff.fileFromValue)
          ?.filter((x) => (x.uploadedFile.url?.length ?? "") > 0)
          ?.map((f) => f.uploadedFile)
      );
    props.onChange && props.onChange(v?.map((f) => f.uploadedFile));
    props.onUploadStatusChange != null && props.onUploadStatusChange(v.some((f) => f.progress != 100));
  };

  function arraysOfIdsAreEqual(a: number[], b: number[]) {
    if (a.length !== b.length) return false;
    if (a.length == 0 && b.length == 0) return true;
    if (a.sort().join(",") === b.sort().join(",")) {
      return true;
    }
  }

  useEffect(() => {
    if (props.value?.some((f) => f == null)) {
      return;
    }
    if (props.value != null && props.value.length == 0 && filesCopy.current.length != 0) {
      setFilesSync([]);
    }
    if (
      props.value != null &&
      !arraysOfIdsAreEqual(
        props.value.map((p) => p.id as number),
        filesCopy.current
          // Вызывает ошибку (Остаётся неудалённый файл)
          .filter((ff) => !ff.fileFromValue)
          ?.filter((x) => (x.uploadedFile.url?.length ?? "") > 0)
          ?.map((f) => f.uploadedFile)
          .map((z) => z.id as number)
        // filesCopy.current.map((file) => file.uploadedFile?.id as number)
      )
    ) {
      setFilesSync([
        ...props.value?.map((f, i) => {
          return {
            // Mb return i
            id: f.id,
            fileFromValue: true,
            // fId: filesCopy.current[filesCopy.current.length - 1]?.fId ?? i,
            fId: (filesCopy.current[filesCopy.current.length - 1]?.fId ?? 0) + 1 + i,
            progress: 100,
            uploadedFile: f,
          };
        }),
      ]);
    }
  }, [props.value]);

  // useEffect(() => {
  //   console.log(filesCopy.current.length, filesCopy.current);
  // }, [filesCopy.current]);

  const onFileInputChanged = async (event: any) => {
    if (event.target.files.length == 0) {
      return;
    }

    const newFiles = [...(event.target.files ?? [])]
      .map((file: File, idx) => {
        if (props.maxSizeMb != null && Number(((file.size ?? 0) / (1024 * 1024)).toFixed(2)) > props.maxSizeMb) {
          notifier.show({
            message: `Размер файла ${
              file.name.length > 25 ? file.name.substring(0, 10) + "..." + file.name.slice(-10) : file.name
            } превышает ${props.maxSizeMb} МБ`,
            theme: "error",
          });
          return null;
        }

        return {
          fId: (filesCopy.current[filesCopy.current.length - 1]?.fId ?? 0) + 1 + idx,
          file: file,
          uploadedFile: {
            id: 0,
            fileName: file.name,
          },
          progress: 0,
        } as TFileUploader;
      })
      .filter((x) => x != null) as TFileUploader[];
    setFilesSync([...filesCopy.current, ...newFiles]);
    await uploadFiles(newFiles);
    event.target.value = "";
  };

  const uploadFiles = async (f2u: TFileUploader[]) => {
    if (f2u.length == 0) {
      return [];
    }

    for await (const x of f2u) {
      let formData = new FormData();
      formData.append("files", x.file as any, x.file?.name as any);
      console.log("x.file", x.file);
      console.log("x.file?.type", x.file?.type);
      const r = await api.staticFile.upload(
        formData,
        getFileTypeByName(x.file?.name ?? "", x.file?.type ?? ""),
        (e) => {
          setProgressById(x.fId, Math.round((e.loaded * 100) / e.total));
      });
      if (r == null) {
        setFilesSync([...filesCopy.current.filter((f) => f.fId != x.fId)]);
        return;
      }
      setUploadedById(x.fId, r?.[0] ?? null);
    }
  };

  const getFileTypeByName = (name: string, type: string): "image" | "file" => {
    let type2return: "image" | "file" = "file";
    if (name.match(/.(jpg|jpeg|png|gif|heic)$/i) || type.includes("image/")) {
      type2return = "image";
    }

    return type2return;
  };

  function setProgressById(fId: number, progress: number) {
    setFilesSync([
      ...filesCopy.current.map((f) => {
        if (f.fId == fId) f.progress = progress;
        return f;
      }),
    ]);
  }

  function setUploadedById(fId: number, newFile: StaticFileDto | null) {
    if (newFile == null) {
      notifier.show({ message: t("notifier:error.file_upload"), theme: "error" });
      return;
    }

    setFilesSync(
      [
        ...filesCopy.current.map((f) => {
          if (f.fId == fId) f.uploadedFile = newFile;
          return f;
        }),
      ],
      true
    );
  }

  function handleDeleteItem(fId: number, fromValue: boolean) {
    const idToRemove = filesCopy.current.find((f) => f.fId == fId)?.uploadedFile?.id;
    if (idToRemove == null) return;
    if (fromValue) {
      props.onValueItemDelete && props.onValueItemDelete(idToRemove);
      return;
    }
    props.onAnyItemDelete && props.onAnyItemDelete(idToRemove);
    setFilesSync([...filesCopy.current.filter((f) => f.fId != fId)]);
  }

  function handleDownloadItem(fId: number) {
    downloadViaAxios(fId);
    return;
    // const file = filesCopy.current.find((f) => f.fId == fId);
    // if (fId == null || file == null || file.uploadedFile?.url == null) {
    //   return;
    // }
    // fetch(file.uploadedFile?.url as string, {
    //   mode: "no-cors",
    //   method: "get",
    // })
    //   .then((resp) => resp.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.style.display = "none";
    //     a.href = url;
    //     a.download = file.uploadedFile?.fileName ?? "File #" + file.uploadedFile?.id;
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //   });
  }

  function download(fId: number) {
    const file = filesCopy.current.find((f) => f.fId == fId);
    if (fId == null || file == null || file.uploadedFile?.url == null) {
      return;
    }
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.addEventListener("progress", function (event) {
      if (event.lengthComputable) {
        // if (downloadcontrol == true) {
        //   downloadcontrol = false;
        //   xhr.abort();
        // }
      }
    });
    xhr.onload = (event) => {
      let blob = xhr.response;
      let downloaded = document.createElement("a");
      let downloadedurl = window.URL.createObjectURL(blob);
      downloaded.href = downloadedurl;
      downloaded.download = file.uploadedFile?.fileName ?? "File #" + file.uploadedFile?.id;
      document.body.append(downloaded);
      downloaded.click();
      downloaded.remove();
      window.URL.revokeObjectURL(downloadedurl);
    };
    xhr.open("GET", file.uploadedFile.url);
    xhr.send();
  }

  const downloadViaAxios = (fId: number) => {
    const file = filesCopy.current.find((f) => f.fId == fId);
    if (fId == null || file == null || file.uploadedFile?.url == null) {
      return;
    }
    const link = document.createElement("a");
    link.href = file.uploadedFile?.url;
    link.setAttribute("download", file.uploadedFile?.fileName ?? "File #" + file.uploadedFile?.id);
    document.body.appendChild(link);
    link.click();
    // axios({
    //   url: file.uploadedFile?.url,
    //   method: "GET",
    //   // responseType: "blob",
    //   // withCredentials: false,
    //   // timeout: 1000 * 60,
    // })
    //   .then((response) => {
    //     console.log(response);
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", file.uploadedFile?.fileName ?? "File #" + file.uploadedFile?.id);
    //     document.body.appendChild(link);
    //     link.click();
    //   })
    //   .catch((err) => {
    //     notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    //   });
  };

  useEffect(() => {
    document.addEventListener("paste", (event) => handlePasteFromClipboard(event));

    // document.onpaste = function (event) {
    //   handlePasteFromClipboard(event);
    // };

    return () => {
      // document.onpaste = () => null;
      document.removeEventListener("paste", (event) => handlePasteFromClipboard(event));
    };
  }, []);

  const handlePasteFromClipboard = async (event: ClipboardEvent) => {
    if ((props.relatedInputId ?? "").trim().length == 0) return;
    // const dummyEl = document.getElementById(props.relatedInputId ?? "");
    // if (dummyEl == null) return;
    // if (document.activeElement == dummyEl) {
    if (document.activeElement?.id === props.relatedInputId) {
      // event.preventDefault();
      //@ts-ignore
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      await handleFilesUpload(items);
    }
  };

  const handleFilesUpload = async (items: any) => {
    console.log(items);
    let blob = null;
    let filesArray: File[] = [];
    for (let i = 0; i < items.length; i++) {
      // if (items[i].type.indexOf("image") === 0) {
      // }
      blob = items[i].getAsFile();
      if (blob == null) return;
      filesArray = [...filesArray, blob];
    }

    const newFiles = [...(filesArray ?? [])].map((file: File, idx) => {
      return {
        fId: (filesCopy.current[filesCopy.current.length - 1]?.fId ?? 0) + 1 + idx,
        file: file,
        uploadedFile: {
          id: 0,
          fileName: file.name,
        },
        progress: 0,
      } as TFileUploader;
    });
    setFilesSync([...filesCopy.current, ...newFiles]);
    await uploadFiles(newFiles);
  };

  const onFileDropped = async (event: any) => {
    // event.stopPropagation();
    event.preventDefault();
    // const items = (event.dataTransfer || event.originalEvent.clipboardData).items;

    const newFiles = [...(event.dataTransfer.files ?? [])].map((file: File, idx) => {
      return {
        fId: (filesCopy.current[filesCopy.current.length - 1]?.fId ?? 0) + 1 + idx,
        file: file,
        uploadedFile: {
          id: 0,
          fileName: file.name,
        },
        progress: 0,
      } as TFileUploader;
    });
    setFilesSync([...filesCopy.current, ...newFiles]);
    await uploadFiles(newFiles);
  };

  function getFileType(name: string): "file" | "image" | "text" | "video" {
    let fileType: "file" | "image" | "text" | "video" = "file";
    const cleanUrl = new URL(name).pathname;
    if (cleanUrl.match(/.(jpg|jpeg|png|gif|heic)$/i)) {
      fileType = "image";
    }
    if (cleanUrl.match(/.(doc|txt)$/i)) {
      fileType = "text";
    }
    if (cleanUrl.match(/.(mp4|webm|avi|mov|wmv)$/i)) {
      fileType = "video";
    }
    return fileType;
  }

  const handleFileOpen = (fId: number) => {
    const file = files.find((f) => f.fId == fId);
    if ((file?.uploadedFile?.url ?? "").trim().length == 0) return;
    if (getFileType((file?.uploadedFile?.url ?? "").trim()) == "image") {
      let imagesToViewArray: ImgsViewerImageType[] = [];
      imagesToViewArray = [
        ...imagesToViewArray,
        { src: file?.uploadedFile?.url ?? "", caption: file?.uploadedFile?.fileName ?? "" },
        ...(files ?? [])
          .filter((oL) => oL.uploadedFile.url != file?.uploadedFile?.url)
          .map((x) => ({ src: x.uploadedFile?.url ?? "", caption: x.uploadedFile.fileName ?? "" })),
      ].filter((i2v) => i2v.src.length > 0);
      appStore.setImagesToViewLinks(imagesToViewArray.filter((i) => getFileType(i.src) == "image"));
    }
    if ((file?.uploadedFile?.url ?? "").match(/.(pdf|doc|docx|ppt|pptx|xls|xlsx|txt|ods)$/i)) {
      let filesToViewArray: FilesViewerFileType[] = [];
      filesToViewArray = [
        ...filesToViewArray,
        { src: file?.uploadedFile?.url ?? "", name: file?.uploadedFile?.fileName ?? "" },
        ...(files ?? [])
          .map((of) => ({ src: of.uploadedFile.url ?? "", name: of.uploadedFile.fileName ?? "" }))
          .filter((oL) => oL.src != file?.uploadedFile?.url),
      ].filter((f2v) => f2v.src.length > 0);
      appStore.setFilesToViewLinks(
        filesToViewArray.filter((f) =>
          ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "ods"].includes(
            getFileExtensionByName(f.src ?? "") ?? ""
          )
        )
      );
      // appStore.setFilesToViewLinks([{ src: props.value.uploadedFile?.url ?? "", name: props.value.file?.name ?? "" }]);
    }
  };


  return (
    <FileUploaderView
      uploadFilesInputRef={uploadFilesInputRef}
      requiredNumber={props.requiredNumber}
      files={filesCopy.current}
      containerClass={props.containerClass}
      // value={props.value}
      hideInfo={props.hideInfo}
      hideButton={props.hideButton}
      smallSize={props.smallSize}
      onFileInputChanged={onFileInputChanged}
      onDeleteItemClick={handleDeleteItem}
      onDownloadItemClick={handleDownloadItem}
      accept={props.accept}
      additionallyCountImg={props.additionallyCountImg}
      filesContainerMaxHeight={props.filesContainerMaxHeight}
      noDragAndDrop={props.noDragAndDrop}
      readonly={props.readonly}
      noDelete={props.noDelete}
      onFileDropped={onFileDropped}
      onFileItemClick={handleFileOpen}
    />
  );
}

export default memo(observer(FileUploader));
