import { LocalesKeys } from "../constants/localesKeys";

export enum routineIssueTooltipKeys {
  name = "name",
  description = "description",
  result = "result",
  proof = "proof",
}

export const getRoutineIssueTooltip =
  (localeKey: LocalesKeys) =>
  (tooltipKey: routineIssueTooltipKeys): string => {
    switch (localeKey) {
      case LocalesKeys.ru: {
        switch (tooltipKey) {
          case routineIssueTooltipKeys.name:
            return "Задачу необходимо формулировать с глагола, то есть, в названии задачи должен быть ответ на вопрос “Что сделать?”. Именно это поможет нам четко поставить задачу и получить нужный результат";
          case routineIssueTooltipKeys.description:
            return "Здесь необходимо как можно подробнее объяснить, что именно и как нужно сделать, добавить все ссылки на ресурсы, инструкции, чек-листы. При формулировки описания нужно опираться на идеальную картину: сотрудник, который работает первый день в компании, получив задачу, выполняет ее и не задает ни одного вопроса";
          case routineIssueTooltipKeys.result:
            return "Здесь важно сформировать сотруднику, какой именно результат от выполнения этой задачи вы ожидаете. Кроме того, если результат сформулирован, то при закрытии задачи система спросит у сотрудника “Результат получен?”";
          case routineIssueTooltipKeys.proof:
            return "По умолчанию стоит “Не требуется”, но вы также можете выбрать: “Текст” или “Изображение (например,фото или скриншот)”. Это значит, что при закрытии этой задачи, если сотрудник не приложит доказательство выполненной им работы и полученного результата, он сможет ее закрыть только с отметкой “Результат не произведен”";
          default:
            return "";
        }
      }
      case LocalesKeys.en: {
        switch (tooltipKey) {
          case routineIssueTooltipKeys.name:
            return "The task must be formulated with a verb, that is, in the name of the task there must be an answer to the question “What to do?”. This is what will help us to clearly set the task and get the desired result";
          case routineIssueTooltipKeys.description:
            return "Here it is necessary to explain in as much detail as possible what exactly and how to do it, add all links to resources, instructions, checklists. When formulating a description, you need to rely on an ideal picture: an employee who works for the first day in the company, having received a task, performs it and does not ask a single question";
          case routineIssueTooltipKeys.result:
            return "Here it is important to inform the employee what kind of result you expect from completing this task. In addition, if the result is formulated, then when the task is closed, the system will ask the employee “Has the result been received?”";
          case routineIssueTooltipKeys.proof:
            return 'By default, it is “Not required”, but you can also choose: “Text“ or "Image (for example, photo or screenshot)”. This means that when closing this task, if the employee does not attach proof of the work he has done and the result obtained, he will be able to close it only with the mark “Result not produced”.';
          default:
            return "";
        }
      }
      case LocalesKeys.uk: {
        switch (tooltipKey) {
          case routineIssueTooltipKeys.name:
            return 'Завдання необхідно формулювати з дієслова, тобто, в назві завдання повинен бути відповідь на питання " що зробити?”. Саме це допоможе нам чітко поставити завдання і отримати потрібний результат.';
          case routineIssueTooltipKeys.description:
            return "Тут необхідно якомога докладніше пояснити, що саме і як потрібно зробити, додати всі посилання на ресурси, інструкції, чек-листи. При формулювання опису потрібно спиратися на ідеальну картину: співробітник, який працює перший день в компанії, отримавши завдання, виконує її і не задає жодного питання";
          case routineIssueTooltipKeys.result:
            return 'Тут важливо сформувати співробітнику, який саме результат від виконання цього завдання ви очікуєте. Крім того, якщо результат сформульований, то при закритті завдання система запитає у співробітника " Результат отриманий?”';
          case routineIssueTooltipKeys.proof:
            return 'За замовчуванням стоїть "Не потрібно", але ви також можете вибрати: "Текст" або "зображення (наприклад, фото або скріншот)". Це означає, що при закритті цього завдання, якщо співробітник не докладе доказ виконаної ним роботи і отриманого результату, він зможе її закрити тільки з відміткою "Результат не проведений".';
          default:
            return "";
        }
      }
      case LocalesKeys.zh: {
        switch (tooltipKey) {
          case routineIssueTooltipKeys.name:
            return "任務的名稱必須包含一個動詞，也就是說，在任務的名稱中必須回答到「要做什麼？」的問題。這將有助於我們明確設定任務並獲得所期望的結果。";
          case routineIssueTooltipKeys.description:
            return "在這裡，需要盡可能詳細地解釋要做什麼，以及如何做，並添加所有資源、指示和檢查清單的連結。在撰寫描述時，需要依據一個理想的情景 。例如一位在公司第一天工作的員工收到任務後能夠執行並不問任何問題。";
          case routineIssueTooltipKeys.result:
            return "在這裡，重要的是告知員工您對完成此任務期望的結果是什麼。此外，如果結果已經明確表述，那麼在任務關閉時，系統將會問員工「是否已收到結果？」。";
          case routineIssueTooltipKeys.proof:
            return "默認情況下，選項是「不需要」，但您也可以選擇：「文字」或「圖像（例如，照片或截圖）」。這意味著當關閉此任務時，如果員工未附上所完成工作和獲得結果的證明，他只能使用「未產生結果」的標記來關閉任務。";
          default:
            return "";
        }
      }
      default: {
        switch (tooltipKey) {
          case routineIssueTooltipKeys.name:
            return "The task must be formulated with a verb, that is, in the name of the task there must be an answer to the question “What to do?”. This is what will help us to clearly set the task and get the desired result";
          case routineIssueTooltipKeys.description:
            return "Here it is necessary to explain in as much detail as possible what exactly and how to do it, add all links to resources, instructions, checklists. When formulating a description, you need to rely on an ideal picture: an employee who works for the first day in the company, having received a task, performs it and does not ask a single question";
          case routineIssueTooltipKeys.result:
            return "Here it is important to inform the employee what kind of result you expect from completing this task. In addition, if the result is formulated, then when the task is closed, the system will ask the employee “Has the result been received?”";
          case routineIssueTooltipKeys.proof:
            return 'By default, it is “Not required”, but you can also choose: “Text“ or "Image (for example, photo or screenshot)”. This means that when closing this task, if the employee does not attach proof of the work he has done and the result obtained, he will be able to close it only with the mark “Result not produced”.';
          default:
            return "";
        }
      }
    }
  };

export const getPermissionsTooltips = (localeKey: LocalesKeys) => {
  console.log("localeKey", localeKey);
  switch (localeKey) {
    case LocalesKeys.ru:
      return ruPermissionTooltips;
    case LocalesKeys.en:
      return enPermissionTooltips;
    case LocalesKeys.uk:
      return uaPermissionTooltips;
    case LocalesKeys.es:
      return esPermissionTooltips;
    case LocalesKeys.de:
      return dePermissionTooltips;
    case LocalesKeys.pl:
      return plPermissionTooltips;
    case LocalesKeys.uzLatn:
      return uzLatanPermissionTooltips;
    case LocalesKeys.uzCyrl:
      return uzCyrlPermissionTooltips;
    case LocalesKeys.zh:
      return zhPermissionTooltips;
    default:
      return [];
  }
};

export const ruPermissionTooltips = [
  {
    key: "o.a",
    text: "Позволяет создавать новую оргструктуру",
  },
  {
    key: "o.d",
    text: "Позволяет удалять оргструктуру",
  },
  {
    key: "o.e",
    text: "Позволяет вносить изменения в оргструктуру",
  },
  {
    key: "o.s",
    text: "Позволяет изменять порядок в выпадающем списке оргструктур",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Позволяет создавать новые функции в оргструктуре",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Позволяет редактировать существующие функции",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Позволяет изменять порядок подфункций",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Позволяет менять родительскую функцию",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Позволяет удалять функции из оргструктуры",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Позволяет прикреплять сотрудников к функции и отменять прикрепление",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Позволяет редактировать набор разрешений для всех функций",
  },
  {
    key: "issue.view_all_issues",
    text: "Позволяет просматривать задачи всех сотрудников компании"
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Позволяет одобрять недельный план самому себе"
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Позволяет создавать повторяющиеся задачи",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Позволяет вносить изменения в повторяющиеся задачи",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Позволяет удалять повторяющиеся задачи из системы ",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Позволяет приостанавливать и возобновлять повторяющиеся задачи",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Позволяет просматривать историю действий по каждой повторяющейся задаче",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "Делает видимыми контактные номера сотрудников в модуле «Сотрудники»",
  },
  {
    id: 14,
    key: "user.edit",
    text: "Позволяет редактировать профили сотрудников в модуле «Сотрудники»”",
  },
  {
    id: 15,
    key: "user.delete",
    text: "Позволяет удалять профили сотрудников в модуле «Сотрудники»",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "Позволяет добавлять сотрудников в систему в модуле «Сотрудники»",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Позволяет удалять ссылки для приглашения сотрудников из системы",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Позволяет вносить изменения в название и описание компании",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Позволяет изменять часовой пояс компании",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Позволяет изменять день начала недели и график работы компании",
  },
  {
    key: "company.edit.mi",
    text: "Позволяет вносить изменения, удалять и создавать новые интервалы метрик"
  },
  {
    id: 21,
    key: "r.cm",
    text: "Позволяет получить доступ к модулю “Отчёты по задачам”",
  },
  {
    id: 22,
    key: "r.dr",
    text: "Доступ для модуля “Отчеты: по драйверам”. В данном отчете отображаются данные по работе с драйвером в компании",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "Позволяет повторно назначить на изучение регламент в модуле “Сотрудники”"
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Позволяет включать и выключать уведомления о нарушениях в задачах"
  },
  {
    key: "s.v.a",
    text: "Позволяет просматривать график работы всех функций",
  },
  {
    key: "s.v.c",
    text: "Позволяет просматривать график работы всех функций, которые ПОД вами на оргструктуре",
  },
  {
    key: "s.v.p",
    text: "Позволяет просматривать график работы всех функций, которые находятся НАД вами на оргструктуре",
  },
  {
    key: "s.u.a",
    text: "Позволяет редактировать график работы всех функций",
  },
  {
    key: "s.u.o",
    text: "Позволяет редактировать график работы только ваших  функций",
  },
  {
    key: "s.u.c",
    text: "Позволяет редактировать график работы всех функций, которые находятся под вами на оргструктуре",
  },
  {
    key: "full_access",
    text: "Позволяет получить права на просмотр, редактирование и отправку на изучение всех разделов и документов",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Дает возможность самостоятельной публикации документов, без необходимости получения одобрения от руководителя",
  },
];
export const enPermissionTooltips = [
  {
    key: "o.a",
    text: "Allows the creation of a new Org chart",
  },
  {
    key: "o.e",
    text: "Allows changes to be made to the Org chart",
  },
  {
    key: "o.d",
    text: "Allows the Org chart to be deleted",
  },
  {
    key: "o.s",
    text: "Allows the order in the dropdown list of Org charts to be changed",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Allows the creation of new functions within the Org chart",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Allows existing functions to be edited",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Allows the order of sub-functions to be changed",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Allows changing the parent function",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Allows functions to be removed from the Org chart",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Allows attaching and detaching employees to a function",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Allows you to edit permissions on functions",
  },
  {
    key: "issue.view_all_issues",
    text: "Allows viewing tasks of all company employees"
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Allows approving the weekly plan for oneself"
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Allows the creation of Routines",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Allows changes to be made to Routines",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Allows Routines to be removed from the system",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Allows pausing and resuming Routines",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Allows viewing the history of actions for each Routine",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "Makes employees' contact numbers visible in the \"Employees\" module",
  },
  {
    id: 14,
    key: "user.edit",
    text: "Allows editing of employee profiles in the \"Employees\" module",
  },
  {
    id: 15,
    key: "user.delete",
    text: "Allows deletion of employee profiles in the \"Employees\" module",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "Allows adding employees to the system in the \"Employees\" module",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Allows removing invitation links for employees from the system",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Allows changes to be made to the company's name and description",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Allows the company’s time zone to be changed",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Allows changes to the start day of the week and the company's working hours",
  },
  {
    key: "company.edit.mi",
    text: "Allows changes, deletions, and the creation of new metric intervals"
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "Allows reassigning regulations for review in the \"Employees\" module"
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Allows turning violation notifications on and off for tasks"
  },
  {
    id: 21,
    key: "r.cm",
    text: "Allows access to the \"Task Reports\" module",
  },
  {
    key: "s.v.a",
    text: "Allows viewing the Schedule of all functions",
  },
  {
    key: "s.v.c",
    text: "Allows viewing the Schedule of all functions that are BELOW you in the Org chart",
  },
  {
    key: "s.v.p",
    text: "Allows viewing the Schedule of all functions that are ABOVE you in the Org chart",
  },
  {
    key: "s.u.a",
    text: "Allows editing the Schedule of all functions",
  },
  {
    key: "s.u.o",
    text: "Allows editing the Schedule of only your functions",
  },
  {
    key: "s.u.c",
    text: "Allows editing the Schedule of all functions that are below you in the Org chart",
  },
  {
    key: "full_access",
    text: "Allows permissions for viewing, editing, and submitting all sections and documents for review",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Grants the ability to independently publish documents without the need for approval from a supervisor",
  },
];
export const uaPermissionTooltips = [
  {
    key: "o.a",
    text: "Дозволяє створювати нову оргструктуру",
  },
  {
    key: "o.e",
    text: "Дозволяє вносити зміни в оргструктуру",
  },
  {
    key: "o.d",
    text: "Дозволяє видаляти оргструктуру",
  },
  {
    key: "o.s",
    text: "Дозволяє змінювати порядок у випадаючому списку оргструктур",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Дозволяє створювати нові функції в оргструктурі",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Дозволяє редагувати існуючі функції",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Дозволяє змінювати порядок підфункцій",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Дозволяє змінювати батьківську функцію",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Дозволяє видаляти функції з оргструктури",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Дозволяє прикріпляти співробітників до функції та скасовувати прикріплення",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Дозволяє редагувати набір дозволів для всіх функцій",
  },
  {
    key: "issue.view_all_issues",
    text: "Дозволяє переглядати задачі всіх співробітників компанії",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Дозволяє затверджувати тижневий план самому собі",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Дозволяє створювати повторювальні задачі",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Дозволяє вносити зміни в повторювальні задачі",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Дозволяє видаляти повторювальні задачі з системи",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Дозволяє призупиняти та відновлювати повторювальні задачі",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Дозволяє переглядати історію дій по кожній повторювальній завданню",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "Робить контактні номери співробітників видимими в модулі «Співробітники»",
  },
  {
    id: 14,
    key: "user.edit",
    text: "Дозволяє редагувати профілі співробітників в модулі «Співробітники»",
  },
  {
    id: 15,
    key: "user.delete",
    text: "Дозволяє видаляти профілі співробітників в модулі «Співробітники»",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "Дозволяє додавати співробітників до системи в модулі «Співробітники»",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Дозволяє видаляти посилання для запрошення співробітників з системи",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Дозволяє вносити зміни в назву та опис компанії",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Дозволяє змінювати часовий пояс компанії",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Дозволяє змінювати день початку тижня та графік роботи компанії",
  },
  {
    key: "company.edit.mi",
    text: "Дозволяє вносити зміни, видаляти та створювати нові інтервали метрик",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "Дозволяє повторно призначити на вивчення регламент в модулі «Спів співробітники»",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Дозволяє вмикати та вимикати повідомлення про порушення в задачах",
  },
  {
    id: 21,
    key: "r.cm",
    text: "Дозволяє отримати доступ до модуля «Звіти по задачах»",
  },
  {
    key: "s.v.a",
    text: "Дозволяє переглядати графік роботи всіх функцій",
  },
  {
    key: "s.v.c",
    text: "Дозволяє переглядати графік роботи всіх функцій, які знаходяться під вами в оргструктурі",
  },
  {
    key: "s.v.p",
    text: "Дозволяє переглядати графік роботи всіх функцій, які знаходяться над вами в оргструктурі",
  },
  {
    key: "s.u.a",
    text: "Дозволяє редагувати графік роботи всіх функцій",
  },
  {
    key: "s.u.o",
    text: "Дозволяє редагувати графік роботи тільки ваших функцій",
  },
  {
    key: "s.u.c",
    text: "Дозволяє редагувати графік роботи всіх функцій, які знаходяться під вами в оргструктурі",
  },
  {
    key: "full_access",
    text: "Дозволяє отримати права на перегляд, редагування та відправку на вивчення всіх розділів і документів",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Дозволяє самостійну публікацію документів без необхідності отримання затвердження від керівника",
  },
];
export const esPermissionTooltips = [
  {
    key: "o.a",
    text: "Permite crear una nueva estructura organizativa",
  },
  {
    key: "o.e",
    text: "Permite realizar cambios en la estructura organizativa",
  },
  {
    key: "o.d",
    text: "Permite eliminar la estructura organizativa",
  },
  {
    key: "o.s",
    text: "Permite cambiar el orden en la lista desplegable de estructuras organizativas",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Permite crear nuevas funciones en la estructura organizativa",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Permite editar funciones existentes",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Permite cambiar el orden de las subfunciones",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Permite cambiar la función principal",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Permite eliminar funciones de la estructura organizativa",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Permite asignar y desasignar empleados a una función",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Permite editar el conjunto de permisos para todas las funciones",
  },
  {
    key: "issue.view_all_issues",
    text: "Permite ver las tareas de todos los empleados de la empresa",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Permite aprobar el plan semanal por cuenta propia",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Permite crear tareas recurrentes",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Permite modificar tareas recurrentes",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Permite eliminar tareas recurrentes del sistema",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Permite pausar y reanudar tareas recurrentes",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Permite ver la historia de acciones para cada tarea recurrente",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "Permite ver los números de contacto de los empleados en el módulo \"Empleados\"",
  },
  {
    id: 14,
    key: "user.edit",
    text: "Permite editar los perfiles de los empleados en el módulo \"Empleados\"",
  },
  {
    id: 15,
    key: "user.delete",
    text: "Permite eliminar perfiles de empleados en el módulo \"Empleados\"",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "Permite agregar empleados al sistema en el módulo \"Empleados\"",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Permite eliminar enlaces de invitación de empleados del sistema",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Permite modificar el nombre y la descripción de la empresa",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Permite cambiar la zona horaria de la empresa",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Permite modificar el día de inicio de la semana y el horario laboral de la empresa",
  },
  {
    key: "company.edit.mi",
    text: "Permite modificar, eliminar y crear nuevos intervalos métricos",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "Permite reasignar el reglamento en el módulo \"Empleados\"",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Permite activar y desactivar notificaciones sobre infracciones en las tareas",
  },
  {
    id: 21,
    key: "r.cm",
    text: "Permite acceder al módulo \"Informes sobre tareas\"",
  },
  {
    key: "s.v.a",
    text: "Permite ver el horario laboral de todas las funciones",
  },
  {
    key: "s.v.c",
    text: "Permite ver el horario laboral de todas las funciones que están debajo en la estructura organizativa",
  },
  {
    key: "s.v.p",
    text: "Permite ver el horario laboral de todas las funciones que están por encima en la estructura organizativa",
  },
  {
    key: "s.u.a",
    text: "Permite editar el horario laboral de todas las funciones",
  },
  {
    key: "s.u.o",
    text: "Permite editar solo el horario laboral de las funciones propias",
  },
  {
    key: "s.u.c",
    text: "Permite editar el horario laboral de todas las funciones que están debajo en la estructura organizativa",
  },
  {
    key: "full_access",
    text: "Permite ver, editar y asignar para estudio todas las secciones y documentos",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Permite la publicación independiente de documentos sin la aprobación del supervisor",
  },
];

export const dePermissionTooltips = [
  {
    key: "o.a",
    text: "Ermöglicht das Erstellen einer neuen Organisationsstruktur",
  },
  {
    key: "o.e",
    text: "Ermöglicht Änderungen an der Organisationsstruktur vorzunehmen",
  },
  {
    key: "o.d",
    text: "Ermöglicht das Löschen der Organisationsstruktur",
  },
  {
    key: "o.s",
    text: "Ermöglicht das Ändern der Reihenfolge im Dropdown-Menü der Organisationsstrukturen",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Ermöglicht das Erstellen neuer Funktionen in der Organisationsstruktur",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Ermöglicht das Bearbeiten bestehender Funktionen",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Ermöglicht das Ändern der Reihenfolge von Unterfunktionen",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Ermöglicht das Ändern der übergeordneten Funktion",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Ermöglicht das Löschen von Funktionen aus der Organisationsstruktur",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Ermöglicht das Zuweisen und Entfernen von Mitarbeitern aus Funktionen",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Ermöglicht das Bearbeiten des Berechtigungssatzes für alle Funktionen",
  },
  {
    key: "issue.view_all_issues",
    text: "Ermöglicht das Anzeigen der Aufgaben aller Mitarbeiter im Unternehmen",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Ermöglicht die Selbstgenehmigung des eigenen Wochenplans",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Ermöglicht das Erstellen von wiederkehrenden Aufgaben",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Ermöglicht Änderungen an wiederkehrenden Aufgaben vorzunehmen",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Ermöglicht das Löschen von wiederkehrenden Aufgaben aus dem System",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Ermöglicht das Pausieren und Fortsetzen wiederkehrender Aufgaben",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Ermöglicht das Anzeigen der Historie der Aktivitäten für jede wiederkehrende Aufgabe",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "Macht die Kontakttelefonnummern der Mitarbeiter im Modul „Mitarbeiter“ sichtbar",
  },
  {
    id: 14,
    key: "user.edit",
    text: "Ermöglicht das Bearbeiten von Mitarbeiterprofilen im Modul „Mitarbeiter“",
  },
  {
    id: 15,
    key: "user.delete",
    text: "Ermöglicht das Löschen von Mitarbeiterprofilen im Modul „Mitarbeiter“",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "Ermöglicht das Hinzufügen von Mitarbeitern ins System im Modul „Mitarbeiter“",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Ermöglicht das Löschen von Einladungscodes im System",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Ermöglicht das Bearbeiten des Namens und der Beschreibung des Unternehmens",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Ermöglicht das Ändern der Zeitzone des Unternehmens",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Ermöglicht das Ändern des Wochenbeginns und des Arbeitsplans des Unternehmens",
  },
  {
    key: "company.edit.mi",
    text: "Ermöglicht das Bearbeiten, Löschen und Erstellen von Metrik-Intervallen",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "Ermöglicht die erneute Zuweisung von Vorschriften zur Durchsicht im Modul „Mitarbeiter“",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Ermöglicht das Ein- oder Ausschalten von Benachrichtigungen bei Verstößen in Aufgaben",
  },
  {
    id: 21,
    key: "r.cm",
    text: "Ermöglicht den Zugriff auf das Modul „Berichte über Aufgaben“",
  },
  {
    key: "s.v.a",
    text: "Ermöglicht das Anzeigen des Arbeitsplans aller Funktionen",
  },
  {
    key: "s.v.c",
    text: "Ermöglicht das Anzeigen des Arbeitsplans aller Funktionen unterhalb der eigenen Position in der Organisationsstruktur",
  },
  {
    key: "s.v.p",
    text: "Ermöglicht das Anzeigen des Arbeitsplans aller Funktionen über der eigenen Position in der Organisationsstruktur",
  },
  {
    key: "s.u.a",
    text: "Ermöglicht das Bearbeiten des Arbeitsplans aller Funktionen",
  },
  {
    key: "s.u.o",
    text: "Ermöglicht das Bearbeiten des Arbeitsplans nur für eigene Funktionen",
  },
  {
    key: "s.u.c",
    text: "Ermöglicht das Bearbeiten des Arbeitsplans aller Funktionen unterhalb der eigenen Position in der Organisationsstruktur",
  },
  {
    key: "full_access",
    text: "Ermöglicht den Zugriff auf das Ansehen, Bearbeiten und Senden zur Prüfung aller Abschnitte und Dokumente",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Ermöglicht das eigenständige Veröffentlichen von Dokumenten ohne Genehmigung des Vorgesetzten",
  },
];
export const plPermissionTooltips = [
  {
    key: "o.a",
    text: "Pozwala tworzyć nową strukturę organizacyjną",
  },
  {
    key: "o.e",
    text: "Pozwala wprowadzać zmiany w strukturze organizacyjnej",
  },
  {
    key: "o.d",
    text: "Pozwala usuwać strukturę organizacyjną",
  },
  {
    key: "o.s",
    text: "Pozwala zmieniać kolejność w rozwijanym menu struktur organizacyjnych",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Pozwala tworzyć nowe funkcje w strukturze organizacyjnej",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Pozwala edytować istniejące funkcje",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Pozwala zmieniać kolejność podfunkcji",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Pozwala zmieniać funkcję nadrzędną",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Pozwala usuwać funkcje ze struktury organizacyjnej",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Pozwala przypisywać i usuwać pracowników z funkcji",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Pozwala edytować zestaw uprawnień dla wszystkich funkcji",
  },
  {
    key: "issue.view_all_issues",
    text: "Pozwala przeglądać zadania wszystkich pracowników firmy",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Pozwala samodzielnie zatwierdzać własny plan tygodniowy",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Pozwala tworzyć zadania cykliczne",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Pozwala wprowadzać zmiany w zadaniach cyklicznych",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Pozwala usuwać zadania cykliczne z systemu",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Pozwala wstrzymywać i wznawiać zadania cykliczne",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Pozwala przeglądać historię działań związanych z każdym zadaniem cyklicznym",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "Pozwala wyświetlać numery kontaktowe pracowników w module „Pracownicy”",
  },
  {
    id: 14,
    key: "user.edit",
    text: "Pozwala edytować profile pracowników w module „Pracownicy”",
  },
  {
    id: 15,
    key: "user.delete",
    text: "Pozwala usuwać profile pracowników w module „Pracownicy”",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "Pozwala dodawać pracowników do systemu w module „Pracownicy”",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Pozwala usuwać linki zapraszające pracowników z systemu",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Pozwala edytować nazwę i opis firmy",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Pozwala zmieniać strefę czasową firmy",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Pozwala zmieniać początek tygodnia i grafik pracy firmy",
  },
  {
    key: "company.edit.mi",
    text: "Pozwala edytować, usuwać i tworzyć nowe interwały metryk",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "Pozwala ponownie przypisać regulamin do przyswojenia w module „Pracownicy”",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Pozwala włączać i wyłączać powiadomienia o naruszeniach w zadaniach",
  },
  {
    id: 21,
    key: "r.cm",
    text: "Pozwala uzyskać dostęp do modułu „Raporty zadań”",
  },

  {
    key: "s.v.a",
    text: "Pozwala przeglądać grafik pracy wszystkich funkcji",
  },
  {
    key: "s.v.c",
    text: "Pozwala przeglądać grafik pracy funkcji podległych w strukturze organizacyjnej",
  },
  {
    key: "s.v.p",
    text: "Pozwala przeglądać grafik pracy funkcji nadrzędnych w strukturze organizacyjnej",
  },
  {
    key: "s.u.a",
    text: "Pozwala edytować grafik pracy wszystkich funkcji",
  },
  {
    key: "s.u.o",
    text: "Pozwala edytować grafik pracy tylko własnych funkcji",
  },
  {
    key: "s.u.c",
    text: "Pozwala edytować grafik pracy funkcji podległych w strukturze organizacyjnej",
  },
  {
    key: "full_access",
    text: "Pozwala uzyskać dostęp do przeglądania, edytowania i wysyłania do przyswojenia wszystkich sekcji i dokumentów",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Pozwala na samodzielne publikowanie dokumentów bez potrzeby uzyskiwania zgody od przełożonego",
  },
];
export const zhPermissionTooltips = [
  {
    key: "o.a",
    text: "允許創建新的組織結構",
  },
  {
    key: "o.e",
    text: "允許對組織結構進行更改",
  },
  {
    key: "o.d",
    text: "允許刪除組織結構",
  },
  {
    key: "o.s",
    text: "允許在組織結構下拉列表中更改順序",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "允許在組織結構中創建新功能",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "允許編輯現有功能",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "允許更改子功能的順序",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "允許更改父功能",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "允許從組織結構中刪除功能",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "允許將員工附加到功能並取消附加",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "允許編輯所有功能的權限集",
  },
  {
    key: "issue.view_all_issues",
    text: "允許查看公司所有員工的任務",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "允許自行批准每週計劃",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "允許創建重複任務",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "允許對重複任務進行更改",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "允許從系統中刪除重複任務",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "允許暫停和恢復重複任務",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "允許查看每個重複任務的操作歷史",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "使員工的聯繫電話在「員工」模塊中可見",
  },
  {
    id: 14,
    key: "user.edit",
    text: "允許編輯員工在「員工」模塊中的資料",
  },
  {
    id: 15,
    key: "user.delete",
    text: "允許刪除員工在「員工」模塊中的資料",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "允許在「員工」模塊中將員工添加到系統中",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "允許刪除系統中邀請員工的鏈接",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "允許對公司的名稱和描述進行更改",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "允許更改公司的時區",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "允許更改一周的開始日和公司的工作時間",
  },
  {
    key: "company.edit.mi",
    text: "允許進行更改、刪除和創建新的指標區間",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "允許在“員工”模塊中重新指定學習規範",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "允許開啟和關閉任務中的違規通知",
  },
  {
    id: 21,
    key: "r.cm",
    text: "允許訪問“任務報告”模塊",
  },
  {
    key: "s.v.a",
    text: "允許查看所有功能的工作時間表",
  },
  {
    key: "s.v.c",
    text: "允許查看所有在您下方的功能的工作時間表",
  },
  {
    key: "s.v.p",
    text: "允許查看所有在您上方的功能的工作時間表",
  },
  {
    key: "s.u.a",
    text: "允許編輯所有功能的工作時間表",
  },
  {
    key: "s.u.o",
    text: "允許僅編輯您自己的功能的工作時間表",
  },
  {
    key: "s.u.c",
    text: "允許編輯所有在您下方的功能的工作時間表",
  },
  {
    key: "full_access",
    text: "允許獲得查看、編輯和提交所有部分及文件的權限",
  },
  {
    key: "regulation.autonomous.approval",
    text: "允許獨立發布文件，而無需獲得管理者的批准",
  },
];
export const uzCyrlPermissionTooltips = [
  {
    key: "o.a",
    text: "Янги ташкилот тузилмасини яратишга имкон беради",
  },
  {
    key: "o.e",
    text: "Ташкилот тузилмасига ўзгартишлар киритишга имкон беради",
  },
  {
    key: "o.d",
    text: "Ташкилот тузилмасини ўчиришга имкон беради",
  },
  {
    key: "o.s",
    text: "Ташкилот тузилмалари рўйхатидаги тартибни ўзгартиришга имкон беради",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Ташкилот тузилмасида янги функцияларни яратишга имкон беради",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Мавжуд функцияларни таҳрирлашга имкон беради",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Қўшимча функциялар тартибини ўзгартиришга имкон беради",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Ота-она функциясини ўзгартиришга имкон беради",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Ташкилот тузилмасидан функцияларни ўчиришга имкон беради",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Ходимларни функцияга бириктириш ва уларни ажратишга имкон беради",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Барча функциялар учун рухсатлар тўпламини таҳрирлашга имкон беради",
  },
  {
    key: "issue.view_all_issues",
    text: "Компаниядаги барча ходимларнинг вазифаларини кўриш имконини беради",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Ҳафталик режани ўз-ўзига тасдиқлаш имконини берадии",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Такрорланувчи вазифаларни яратишга имкон беради",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Такрорланувчи вазифаларни таҳрирлаш имконини беради",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Такрорланувчи вазифаларни тизимдан ўчиришга имкон беради",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Такрорланувчи вазифаларни тўхтатиш ва қайта ишга туширишга имкон беради",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Ҳар бир такрорланувчи вазифа бўйича амалга оширилган ҳаракатлар тарихини кўриш имконини беради",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "\"Ходимлар\" модулида ходимларнинг контакт рақамларини кўришга имкон беради",
  },
  {
    id: 14,
    key: "user.edit",
    text: "\"Ходимлар\" модулида ходимлар профилларини таҳрирлаш имконини беради",
  },
  {
    id: 15,
    key: "user.delete",
    text: "\"Ходимлар\" модулида ходимлар профилларини ўчириш имконини беради",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "\"Ходимлар\" модули орқали ходимларни тизимга қўшиш имконини беради",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Ходимларни таклиф қилиш учун юборилган ҳаволаларни ўчиришга имкон беради",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Компания номи ва тавсифига ўзгартиш киритишга имкон беради",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Компаниянинг вақт минтақасини ўзгартиришга имкон беради",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Ҳафтанинг бошланиш куни ва компаниянинг иш графигини ўзгартиришга имкон беради",
  },
  {
    key: "company.edit.mi",
    text: "Метрикалар интервалларини яратиш, таҳрирлаш ва ўчириш имконини беради",
  },
  {
    id: 21,
    key: "r.cm",
    text: "\"Вазифалар бўйича ҳисоботлар\" модулига кириш имконини беради",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "\"Ходимлар\" модулида регламентни қайта ўрганишга топшириш имконини беради",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Вазифалардаги қоидабузарликлар хакидаги хабарламарини ёқиш ва ўчириш имконини беради",
  },
  {
    key: "s.v.a",
    text: "Барча функцияларнинг иш графигини кўришга имкон беради",
  },
  {
    key: "s.v.c",
    text: "Ташкилот тузилмасида сиздан қуйида жойлашган барча функцияларнинг иш графигини кўришга имкон беради",
  },
  {
    key: "s.v.p",
    text: "Ташкилот тузилмасида сиздан юқори жойлашган барча функцияларнинг иш графигини кўришга имкон беради",
  },
  {
    key: "s.u.a",
    text: "Барча функцияларнинг иш графигини таҳрирлашга имкон беради",
  },
  {
    key: "s.u.o",
    text: "Фақат ўз функцияларингизнинг иш графигини таҳрирлашга имкон беради",
  },
  {
    key: "s.u.c",
    text: "Ташкилот тузилмасида сиздан қуйида жойлашган барча функцияларнинг иш графигини таҳрирлашга имкон беради",
  },
  {
    key: "full_access",
    text: "Барча бўлим ва ҳужжатларни кўриш, таҳрирлаш ва ўрганишга юбориш ҳуқуқларини беради",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Раҳбарнинг тасдиғи талаб қилинмасдан ҳужжатларни мустақил нашр қилиш имконини беради",
  },
];
export const uzLatanPermissionTooltips = [
  {
    key: "o.a",
    text: "Yangi tashkilot tuzilmasini yaratishga imkon beradi",
  },
  {
    key: "o.e",
    text: "Tashkilot tuzilmasiga o‘zgartirishlar kiritishga imkon beradi",
  },
  {
    key: "o.d",
    text: "Tashkilot tuzilmasini o‘chirishga imkon beradi",
  },
  {
    key: "o.s",
    text: "Tashkilot tuzilmalari ro‘yxatidagi tartibni o‘zgartirishga imkon beradi",
  },
  {
    id: 1,
    key: "orgchart.role.add",
    text: "Tashkilot tuzilmasida yangi funksiyalarni yaratishga imkon beradi",
  },
  {
    id: 2,
    key: "orgchart.role.edit",
    text: "Mavjud funksiyalarni tahrirlashga imkon beradi",
  },
  {
    id: 3,
    key: "orgchart.role.sort",
    text: "Qo‘shimcha funksiyalar tartibini o‘zgartirishga imkon beradi",
  },
  {
    id: 4,
    key: "orgchart.role.move",
    text: "Asosiy funksiyani o‘zgartirishga imkon beradi",
  },
  {
    id: 5,
    key: "orgchart.role.delete",
    text: "Funksiyalarni tashkilot tuzilmasidan o‘chirishga imkon beradi",
  },
  {
    id: 6,
    key: "orgchart.role.users_edit",
    text: "Xodimlarni funksiyaga biriktirish va ajratishga imkon beradi",
  },
  {
    id: 7,
    key: "orgchart.role.permissions_edit",
    text: "Barcha funksiyalar uchun ruxsatnomalar to‘plamini tahrirlashga imkon beradi",
  },
  {
    key: "issue.view_all_issues",
    text: "Kompaniyaning barcha xodimlarining vazifalarini ko‘rish imkonini beradi",
  },
  {
    key: "issue.self_appoval_of_plan",
    text: "Haftalik rejani o‘z-o‘ziga tasdiqlash imkonini beradi",
  },
  {
    id: 8,
    key: "recurring_issue.add",
    text: "Takrorlanuvchi vazifalarni yaratishga imkon beradi",
  },
  {
    id: 9,
    key: "recurring_issue.edit",
    text: "Takrorlanuvchi vazifalarga o‘zgartirish kiritishga imkon beradi",
  },
  {
    id: 10,
    key: "recurring_issue.delete",
    text: "Tizimdan takrorlanuvchi vazifalarni o‘chirishga imkon beradi",
  },
  {
    id: 11,
    key: "recurring_issue.pause_manage",
    text: "Takrorlanuvchi vazifalarni to‘xtatish va davom ettirish imkonini beradi",
  },
  {
    id: 12,
    key: "recurring_issue.view_history",
    text: "Har bir takrorlanuvchi vazifa bo‘yicha harakatlar tarixini ko‘rishga imkon beradi",
  },
  {
    id: 13,
    key: "user.show_phone_number",
    text: "\"Xodimlar\" modulida xodimlarning aloqa raqamlarini ko‘rish imkonini beradi",
  },
  {
    id: 14,
    key: "user.edit",
    text: "\"Xodimlar\" modulida xodimlar profillarini tahrirlash imkonini beradi",
  },
  {
    id: 15,
    key: "user.delete",
    text: "\"Xodimlar\" modulida xodimlar profillarini o‘chirishga imkon beradi",
  },
  {
    id: 16,
    key: "user.company_invite.add",
    text: "\"Xodimlar\" modulida xodimlarni tizimga qo‘shishga imkon beradi",
  },
  {
    id: 17,
    key: "user.company_invite.delete",
    text: "Tizimdan xodimlarni taklif qilish havolalarini o‘chirishga imkon beradi",
  },
  {
    id: 18,
    key: "company.edit.name_and_desc",
    text: "Kompaniyaning nomi va tavsifini o‘zgartirishga imkon beradi",
  },
  {
    id: 19,
    key: "company.edit.timezone",
    text: "Kompaniyaning vaqt mintaqasini o‘zgartirishga imkon beradi",
  },
  {
    id: 20,
    key: "company.edit.schedule",
    text: "Haftaning boshlanish kunini va kompaniya ish jadvalini o‘zgartirishga imkon beradi",
  },
  {
    key: "company.edit.mi",
    text: "Metrik oraliqlarini yaratish, tahrirlash va o‘chirishga imkon beradi",
  },
  {
    id: 21,
    key: "r.cm",
    text: "\"Vazifalar bo‘yicha hisobotlar\" moduliga kirish imkonini beradi",
  },
  {
    key: "company.employees.manage_permissions_for_resend_regulation",
    text: "\"Xodimlar\" modulida reglamentni o‘rganish uchun qayta tayinlash imkonini beradi",
  },
  {
    key: "company.notifications.manage_violations_notifications",
    text: "Vazifalarda qoidabuzarliklar haqida xabarlamalarni yoqish va o‘chirish imkonini beradi",
  },
  {
    key: "s.v.a",
    text: "Barcha funksiyalarning ish jadvalini ko‘rish imkonini beradi",
  },
  {
    key: "s.v.c",
    text: "Tashkilot tuzilmasida ostingizda joylashgan barcha funksiyalarning ish jadvalini ko‘rish imkonini beradi",
  },
  {
    key: "s.v.p",
    text: "Tashkilot tuzilmasida ustingizda joylashgan barcha funksiyalarning ish jadvalini ko‘rish imkonini beradi",
  },
  {
    key: "s.u.a",
    text: "Barcha funksiyalarning ish jadvalini tahrirlash imkonini beradi",
  },
  {
    key: "s.u.o",
    text: "Faqat o‘z funksiyalaringizning ish jadvalini tahrirlash imkonini beradi",
  },
  {
    key: "s.u.c",
    text: "Tashkilot tuzilmasida ostingizda joylashgan barcha funksiyalarning ish jadvalini tahrirlash imkonini beradi",
  },
  {
    key: "full_access",
    text: "Barcha bo‘limlar va hujjatlarni ko‘rish, tahrirlash va o‘rganishga yuborish huquqini beradi",
  },
  {
    key: "regulation.autonomous.approval",
    text: "Rahbarning tasdig‘isiz hujjatlarni mustaqil nashr qilish imkonini beradi",
  },
];
