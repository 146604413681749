export const carrotQuestLocaleEs = {
  thanks: "Gracias",
  dialogs_history: "Historial de chats",
  powered_by_text_before_logo: "Desarrollado&nbsp;por",
  powered_by_text_after_logo: "",
  enter_message: "Escribe un mensaje…",
  enter_email: "Introduce tu correo electrónico",
  enter_phone: "Introduce tu número de teléfono",
  leave_email: "Puedes dejar tu correo electrónico y continuar la conversación por email:",
  page_title_new_message: "Nuevo mensaje",
  privacy_policy: "Política de privacidad",
  errorFileType:
    "Formato de archivo no válido. Puedes subir archivos: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Archivo demasiado grande<br/>El tamaño máximo del archivo es de 10 MB.",
  errorManyRequests: "Demasiados archivos<br/>Estás subiendo archivos con demasiada frecuencia. Por favor, espera.",
  errorReplyTooBig: "El mensaje no puede tener más de 20,000 caracteres.",
  errorUserBan: "Estás bloqueado<br/>Ya no puedes enviar nuevos mensajes en el chat.",
  error: "Algo salió mal.<br/>Ocurrió un error durante la carga. Inténtalo de nuevo.",
  close: "Cerrar",
  button_start_message: "Escribir",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Iniciar o continuar una conversación en {{networkName}}",
  social_network_page_desc_vk: [
    "Abre VK con el botón de abajo para ir al chat",
    "Envíanos tu pregunta",
  ],
  social_network_page_desc_viber: [
    "Abre Viber con el botón de abajo para ir al chat",
    "Envíanos tu pregunta",
  ],
  social_network_page_desc_telegram: [
    "Abre Telegram con el botón de abajo para ir al chat",
    "Presiona el botón «Start» en Telegram",
    "Envíanos tu pregunta",
  ],
  social_network_page_desc_facebook: [
    "Abre Facebook con el botón de abajo para ir al chat",
    "Presiona el botón «Empezar» en Facebook",
    "Envíanos tu pregunta",
  ],
  social_network_page_desc_instagram: [
    "Abre Instagram usando el botón de abajo para ir a la página deseada",
    "Haz clic en el botón 'Enviar mensaje' en Instagram",
    "Haznos tu pregunta",
  ],
  social_network_page_desc_whatsapp: [
    "Abre WhatsApp usando el botón de abajo para ir al chat",
    "Envía el mensaje prellenado",
    "Haznos tu pregunta",
  ],
  social_network_page_open_button: "Abrir {{networkName}}",
  social_network_qr_code_title: "Escanea este código QR para abrir {{networkName}} desde otro dispositivo",
  bumperText:
    'Utilizamos cookies para mejorar el rendimiento del sitio, analizar el tráfico y personalizar el contenido. Al utilizar este sitio o hacer clic en "Estoy de acuerdo", aceptas nuestra política de privacidad y cookies según lo descrito en la <a href="{{privacy-policy-link}}" target="_blank">Política de privacidad</a>.',
  bumperButton: "Estoy de acuerdo",
  confirmSubscriptionWithCheckbox:
    'Estoy de acuerdo con los <a href="{{subscription-policy}}" target="_blank">términos de procesamiento de datos</a>',
  confirmSubscriptionWithoutCheckbox:
    'Al hacer clic en el botón, aceptas los <a href="{{subscription-policy}}" target="_blank">términos de procesamiento de datos</a>',
  appeal: "Tú",
  vote_comment: "¿Qué sucedió?",
  vote_text: "Por favor, califica el servicio del operador",
  vote_thanks: "¡Gracias por tu calificación!",
  cart_button: "Más información",
  cart_no_name: "Sin nombre",
  cart_zero: "Los datos del carrito no se pudieron cargar",
  article_link: "Leer artículo",
  to_knowledge_base: "Buscar una respuesta en la base de conocimientos",
  typing_status: "Escribiendo…",
  notification_enter_message: "Escribe un mensaje…",
  notification_message_sending: "Enviando mensaje…",
  notification_message_sent: "Mensaje enviado",
  notification_file_sending: "Subiendo archivo…",
  notification_file_sent: "Archivo enviado",
  notification_typing_status: "está escribiendo",
  history_zero_data_title: "Aún no hemos hablado",
  history_zero_data_desc: "Aquí aparecerá tu historial de chats",
  history_group_collapse: "Colapsar",
  history_section_actual: "Reciente",
  history_section_archive: "Historial",
  back_to_chat: "Volver al chat",
  integration_zoom_button_text: "Unirse",
  integration_zoom_tip: "☝️ Consejo: Puedes unirte a la videoconferencia directamente desde tu navegador",
  dateMonth: {
    "0": "enero",
    "1": "febrero",
    "2": "marzo",
    "3": "abril",
    "4": "mayo",
    "5": "junio",
    "6": "julio",
    "7": "agosto",
    "8": "septiembre",
    "9": "octubre",
    "10": "noviembre",
    "11": "diciembre",
  },
  fileSize: {
    "0": "B",
    "1": "KB",
    "2": "MB",
    "3": "GB",
  },
  at: "a las",
  edited: "Editado",
  removed: "Eliminado",
  removedMessage: "Mensaje eliminado",
  bot_answer_placeholder: "Ingrese su respuesta",
  bot_buttons_placeholder: "Elija una opción",
  connection_problem_toast: "<b>No hay conexión con el chat.</b> Intentaremos reconectarnos automáticamente.",
  input_error_email: "Ingrese un correo electrónico válido",
  input_error_phone: "Ingrese un número de teléfono válido",
  popup_close_all_button: "Ocultar todo",
  offline: "desconectado",
  online: "conectado",
  whatsapp_auth_text:
    "Envíanos este mensaje para transferir tu ID de chat web: {{userId}} aquí a WhatsApp. Si editas o eliminas este mensaje, crearás un nuevo chat y te comunicarás con otro operador.",
  popup_desc: "Ventana emergente",
  image_desc: "Imagen",
  emoji_search_placeholder: "Buscar",
  emoji_zero_data: "No se encontró nada",
  emoji_category_people: "Emojis y personas",
  emoji_category_nature: "Animales y naturaleza",
  emoji_category_food: "Comida y bebida",
  emoji_category_activity: "Actividad",
  emoji_category_places: "Viajes y lugares",
  emoji_category_objects: "Objetos",
  emoji_category_symbols: "Símbolos",
  emoji_category_flags: "Banderas",
};

export const carrotAdditionalSettingsEs = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Estamos aquí y listos para ayudar",
  messenger_offline_message: "Un operador se conectará especialmente para ti.",
  messenger_welcome_message:
    "¡Buen día! Te has comunicado con el soporte técnico de Business Booster Platform. Por favor, describe tu problema y te responderemos lo antes posible. ¡Gracias por contactarnos!",
};
