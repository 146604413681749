import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { removeLeadingZerosFromString } from "../../../../../../helpers/stringFunctions";
import { Button, Card, Empty, Icon, Spin, Text, Tooltip } from "../../../../../uiKit";
import { PlanningRejectDialog } from "../../../../dialogs/planningRejectDialog/PlanningRejectDialog";
import { SelectApproverDialog } from "../../../../dialogs/selectApproverDialog/SelectApproverDialog";
import { EmptyBlock } from "../../../../layouts/emptyBlock/EmptyBlock";
import { PlanHistoryModule } from "../../components/planHistoryModule/PlanHistoryModule";
import { PlanningCollapseContent } from "../../components/planningCollapseContent/PlanningCollapseContent";
import { IPlanningPageMainContentView, PlanUpdateStatus } from "./PlanningPageMainContent.interface";
import "./PlanningPageMainContent.scss";
import { PlanningPageMenu } from "../planningPageMenu/PlanningPageMenu";
import { HelpIcon } from "../../../../../../constants/icon";
import { useDateHelpers } from "../../../../../../hooks";
import { PlaningFilterType } from "../../../../../../constants/PlaningFilterType";
import { PlanningPageMetric } from "../planningPageMetric/PlanningPageMetric";

export const PlanningPageMainContentView = forwardRef((props: IPlanningPageMainContentView, ref) => {
  const url = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const padding = (url.pathname == "/communication/my-plan" && "pt-3") || (params.id && "");

  // onUpdatePlanStatus={handleUpdatePlanStatus}

  const handlePlanReject = useCallback(() => {
    props.onUpdatePlanStatus(PlanUpdateStatus.rejected);
  }, []);

  const handlePlanApproved = useCallback(() => {
    props.onUpdatePlanStatus(PlanUpdateStatus.approved);
  }, []);

  const { formatTimeSpan } = useDateHelpers();

  const getTimeFormat = (time: string) => {
    return formatTimeSpan(time, {
      formatWithoutDays: true
    }) || 0;
  };

  const [groupingType, setGroupingType] = useState<PlaningFilterType>(PlaningFilterType.roles);

  const handleChangeGroupingType = useCallback((type: PlaningFilterType) => {
    setGroupingType(type);
  }, []);

  return (
    <>
      <PlanningRejectDialog
        isOpen={props.isOpen}
        onOpeningChange={props.onIsOpenChange}
        onPlanReject={handlePlanReject}
        isLoadingButton={props.isLoadingButton!}
        plan={props.plan}
      />
      <SelectApproverDialog
        open={props.isOpenApproverDialog}
        onClose={() => props.isOpenApproverDialogChange(false)}
        onSuccess={props.onApproverSuccess}
      />
      <div className={`planning-page-main-content__wrapper ${padding} pl-4 pr-4`}>
        <PlanningPageMenu
          plan={props.plan}
          isSidebarOpen={props.isSidebarOpen}
          onSidebarOpenChange={props.onSidebarOpenChange}
          currentPlanId={props.currentPlanId}
          userId={props.userId}
          onPlanIdChange={props.onPlanIdChange}
          onMigrateIssuesToPlan={props.onMigrateIssuesToPlan}
          isApproval={props.isApproval}
          groupingType={groupingType}
          onChangeGroupingType={handleChangeGroupingType}
        />
        <PlanningPageMetric
          userId={props.userId!}
        />
        {props.plan && (!!props.issues.length || !!props.googleIssues?.length) && !props.isLoading && (
          // d-stack-row justify-center align-center
          <div className="planning-page-main" >
            <div className="mt-4">
              <PlanningCollapseContent
                issues={props.issues}
                // scheduleIssues={props.scheduleIssues}
                plan={props.plan}
                onLoadRestart={props.onLoadRestart}
                onIssueEdit={props.onIssueEdit}
                onIssueToPlanCreate={props.onIssueToPlanCreate}
                // issuesRestart={props.issuesRestart}
                weekReportStart={props.weekReportStart}
                userId={props.userId}
                groupingType={groupingType}
                googleIssues={groupingType ? undefined : props.googleIssues}
              />
              {!!props.googleIssues?.length && groupingType && (
                <PlanningCollapseContent
                  googleIssues={props.googleIssues}
                  // scheduleIssues={props.scheduleIssues}
                  plan={props.plan}
                  onLoadRestart={props.onLoadRestart}
                  onIssueEdit={props.onIssueEdit}
                  onIssueToPlanCreate={props.onIssueToPlanCreate}
                  // issuesRestart={props.issuesRestart}
                  weekReportStart={props.weekReportStart}
                  userId={props.userId}
                  groupingType={groupingType}
                />
              )}

              {/*TODO: Rewrite everything!*/}
              <Card
                variant="secondary"
                className="planing-info-block"
              >
                <Text className="mr-55">{t("common:planning.summary_by_function")}</Text>
                <div className="planing-info-wrap">
                  <div
                    className={`planing-info-item`}
                  >
                    <div className="planing-info-item__title">
                      {t("ui:time_block.total_tasks")}
                      <Tooltip title={t("ui:tooltip.table_planing.total_tasks")}>
                        <Icon className="planing-info-item__icon" component={() => <HelpIcon />} />
                      </Tooltip>
                    </div>
                    <div className="planing-info-item__value">
                      <span>{props.plan?.issuesDone ?? 0} </span> / {props.plan?.issuesOverall ?? 0}
                    </div>
                  </div>
                  <div
                    className={`planing-info-item`}
                  >
                    <div className="planing-info-item__title">
                      {t("ui:time_block.plan_actual")}
                      <Tooltip title={t("ui:tooltip.table_planing.plan_actual")}>
                        <Icon className="planing-info-item__icon" component={() => <HelpIcon />} />
                      </Tooltip>
                    </div>
                    <div className="planing-info-item__value">
                      <span>
                        {getTimeFormat(props.plan?.timeFactOverall ?? "00:00:00")}
                      </span>
                      {' / '}
                      {getTimeFormat(props.plan?.timePlanOverall ?? "00:00:00")}

                    </div>
                  </div>
                </div>
              </Card>
              <div className="mt-6">
                <div className="mt-4 mb-6 d-stack-row justify-end align-center">
                  {params.id && !props.approvalPlan && (
                    <Button onClick={props.onIssueToPlanCreate} type="default" style={{ color: "#5672ff" }}>
                      {t("ui:button.create_task")}
                    </Button>
                  )}
                  {props.approvalPlan && (
                    <>
                      <Button
                        onClick={() => props.onIsOpenChange(true)}
                        className="mr-3"
                        type="default"
                      >
                        {t("ui:button.reject")}
                      </Button>
                      <Button
                        // onClick={() =>
                        //   props.onPlanStatusEdit({ ...props.plan, status: 3 }, t("notifier:success.plan_approved"))
                        // }
                        onClick={handlePlanApproved}
                        loading={props.isLoadingButton}
                      >
                        {t("ui:button.approve")}
                      </Button>
                    </>
                  )}
                  {url.pathname == "/communication/my-plan" && (
                    <>
                      {props.plan.status == 2 || props.plan.status == 3 ? null : (
                        <Button
                          className="ml-3"
                          onClick={() => props.isOpenApproverDialogChange(true)}
                          loading={props.isLoadingButton}
                        >
                          {t("ui:button.submit_for_approval")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <PlanHistoryModule ref={ref} planId={props.plan?.id} />
              </div>
            </div>
          </div>
        )}
      </div>
      {props.plan && !props.issues.length && !props.isLoading && (
        <div className="empty-block-wrapper">
          <div className="d-stack-row justify-center align-center" >
            <Empty
              image={<img src={window.location.origin + "/planning-empty.png"} alt="Empty content" />}
              imageStyle={{ height: 300, width: 338, margin: "0 auto" }}
              description={
                <div className="d-stack-column justify-center align-center mt-12" style={{ width: "420px" }}>
                  <Text size="16px">
                    {t("text:no_open_tasks.content")}
                  </Text>
                </div>
              }
            />
          </div>
        </div>
      )}
      {!props.plan && !props.isLoading && (
        <div className="empty-block-wrapper">
          <div className="d-stack-row justify-center align-center">
            <EmptyBlock />
          </div>
        </div>
      )}
    </>
  );
});
