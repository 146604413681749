export const carrotQuestLocaleDe = {
  thanks: "Danke",
  dialogs_history: "Chatverlauf",
  powered_by_text_before_logo: "Bereitgestellt&nbsp;von",
  powered_by_text_after_logo: "",
  enter_message: "Nachricht eingeben…",
  enter_email: "E-Mail eingeben",
  enter_phone: "Telefonnummer eingeben",
  leave_email: "Sie können Ihre E-Mail hinterlassen und das Gespräch per E-Mail fortsetzen:",
  page_title_new_message: "Neue Nachricht",
  privacy_policy: "Datenschutzbestimmungen",
  errorFileType:
    "Ungültiges Dateiformat. Sie können folgende Dateien hochladen: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf, mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Datei zu groß<br/>Maximale Dateigröße: 10 MB.",
  errorManyRequests: "Zu viele Dateien<br/>Sie laden Dateien zu oft hoch. Bitte warten Sie einen Moment.",
  errorReplyTooBig: "Die Nachricht darf nicht länger als 20.000 Zeichen sein.",
  errorUserBan: "Sie sind gesperrt<br/>Sie können keine neuen Nachrichten mehr im Chat senden.",
  error: "Etwas ist schiefgelaufen.<br/>Beim Hochladen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  close: "Schließen",
  button_start_message: "Schreiben",
  button_vk: "VK",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Starten oder fortsetzen eines Chats auf {{networkName}}",
  social_network_page_desc_vk: [
    "Öffnen Sie VK mit der Schaltfläche unten, um zum Chat zu gelangen",
    "Stellen Sie uns Ihre Frage",
  ],
  social_network_page_desc_viber: [
    "Öffnen Sie Viber mit der Schaltfläche unten, um zum Chat zu gelangen",
    "Stellen Sie uns Ihre Frage",
  ],
  social_network_page_desc_telegram: [
    "Öffnen Sie Telegram mit der Schaltfläche unten, um zum Chat zu gelangen",
    "Drücken Sie die 'Start'-Taste in Telegram",
    "Stellen Sie uns Ihre Frage",
  ],
  social_network_page_desc_facebook: [
    "Öffnen Sie Facebook mit der Schaltfläche unten, um zum Chat zu gelangen",
    "Drücken Sie die 'Start'-Taste in Facebook",
    "Stellen Sie uns Ihre Frage",
  ],
  social_network_page_desc_instagram: [
    "Öffnen Sie Instagram mit der Schaltfläche unten, um zur gewünschten Seite zu gelangen",
    "Klicken Sie auf die Schaltfläche 'Nachricht senden' in Instagram",
    "Stellen Sie uns Ihre Frage",
  ],
  social_network_page_desc_whatsapp: [
    "Öffnen Sie WhatsApp mit der Schaltfläche unten, um zum Chat zu gelangen",
    "Senden Sie die vorausgefüllte Nachricht",
    "Stellen Sie uns Ihre Frage",
  ],
  social_network_page_open_button: "Öffnen {{networkName}}",
  social_network_qr_code_title: "Scannen Sie diesen QR-Code, um {{networkName}} von einem anderen Gerät zu öffnen",
  bumperText:
    'Wir verwenden Cookies, um die Leistung der Website zu verbessern, den Datenverkehr zu analysieren und Inhalte zu personalisieren. Durch die Nutzung dieser Website oder das Klicken auf "Ich stimme zu" akzeptieren Sie unsere Datenschutz- und Cookie-Richtlinien, wie in der <a href="{{privacy-policy-link}}" target="_blank">Datenschutzerklärung</a> beschrieben.',
  bumperButton: "Ich stimme zu",
  confirmSubscriptionWithCheckbox:
    'Ich stimme den <a href="{{subscription-policy}}" target="_blank">Datenverarbeitungsbedingungen</a> zu',
  confirmSubscriptionWithoutCheckbox:
    'Durch Klicken auf die Schaltfläche stimmen Sie den <a href="{{subscription-policy}}" target="_blank">Datenverarbeitungsbedingungen</a> zu',
  appeal: "Sie",
  vote_comment: "Was ist passiert?",
  vote_text: "Bitte bewerten Sie den Service des Operators",
  vote_thanks: "Danke für Ihr Feedback!",
  cart_button: "Mehr erfahren",
  cart_no_name: "Kein Name",
  cart_zero: "Warenkorbdaten konnten nicht geladen werden",
  article_link: "Artikel lesen",
  to_knowledge_base: "Antwort in der Wissensdatenbank finden",
  typing_status: "Schreibt…",
  notification_enter_message: "Nachricht eingeben…",
  notification_message_sending: "Nachricht wird gesendet…",
  notification_message_sent: "Nachricht gesendet",
  notification_file_sending: "Datei wird hochgeladen…",
  notification_file_sent: "Datei gesendet",
  notification_typing_status: "schreibt",
  history_zero_data_title: "Wir haben noch nicht gesprochen",
  history_zero_data_desc: "Hier wird Ihr Chat-Verlauf angezeigt",
  history_group_collapse: "Einklappen",
  history_section_actual: "Kürzlich",
  history_section_archive: "Verlauf",
  back_to_chat: "Zurück zum Chat",
  integration_zoom_button_text: "Beitreten",
  integration_zoom_tip: "☝️ Tipp: Sie können direkt über Ihren Browser an der Videokonferenz teilnehmen",
  dateMonth: {
    "0": "Januar",
    "1": "Februar",
    "2": "März",
    "3": "April",
    "4": "Mai",
    "5": "Juni",
    "6": "Juli",
    "7": "August",
    "8": "September",
    "9": "Oktober",
    "10": "November",
    "11": "Dezember",
  },
  fileSize: {
    "0": "B",
    "1": "KB",
    "2": "MB",
    "3": "GB",
  },
  at: "um",
  edited: "Bearbeitet",
  removed: "Gelöscht",
  removedMessage: "Nachricht gelöscht",
  bot_answer_placeholder: "Antwort eingeben",
  bot_buttons_placeholder: "Option auswählen",
  connection_problem_toast: "<b>Keine Verbindung zum Chat.</b> Wir versuchen automatisch, die Verbindung wiederherzustellen.",
  input_error_email: "Geben Sie eine gültige E-Mail-Adresse ein",
  input_error_phone: "Geben Sie eine gültige Telefonnummer ein",
  popup_close_all_button: "Alle ausblenden",
  offline: "offline",
  online: "online",
  whatsapp_auth_text:
    "Senden Sie uns diese Nachricht, um Ihre Webchat-ID: {{userId}} hier zu WhatsApp zu übertragen. Wenn Sie diese Nachricht bearbeiten oder löschen, erstellen Sie einen neuen Chat und verbinden sich mit einem anderen Operator.",
  popup_desc: "Popup",
  image_desc: "Bild",
  emoji_search_placeholder: "Suche",
  emoji_zero_data: "Nichts gefunden",
  emoji_category_people: "Smileys & Personen",
  emoji_category_nature: "Tiere & Natur",
  emoji_category_food: "Essen & Trinken",
  emoji_category_activity: "Aktivität",
  emoji_category_places: "Reisen & Orte",
  emoji_category_objects: "Objekte",
  emoji_category_symbols: "Symbole",
  emoji_category_flags: "Flaggen",
};

export const carrotAdditionalSettingsDe = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Wir sind hier und bereit zu helfen",
  messenger_offline_message: "Ein Operator wird sich speziell für Sie verbinden.",
  messenger_welcome_message:
    "Guten Tag! Sie haben den Support von Business Booster Platform kontaktiert. Bitte beschreiben Sie Ihr Problem, und wir werden Ihnen so schnell wie möglich antworten. Vielen Dank für Ihre Anfrage!",
};
