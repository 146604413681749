import React from "react";
import "./TaskRecurringCard.scss";
import { RecurringIssueDto, RecurringIssueExecutorConfigDto, RoleDto, type RolePositionDto } from "../../../../../api";
import { useTranslation } from "react-i18next";
import { FiPlay } from "@react-icons/all-files/fi/FiPlay";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { FiEdit2 } from "@react-icons/all-files/fi/FiEdit2";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { FiPause } from "@react-icons/all-files/fi/FiPause";
import { useClaims, useRootStore } from "../../../../../hooks";
import { permissionKeys } from "../../../../../utils/permissions";
import { Card, Icon, IconButton, Text } from "../../../../uiKit";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { If } from "../../../../service/conditionStatement";

export interface ITaskRecurringCardView {
  data: RecurringIssueDto;
  // allRoles: RoleDto[];
  hideButtons?: boolean;

  onPauseClick: () => void;
  onWatchHistoryClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

function TaskRecurringCardView(props: ITaskRecurringCardView) {
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const claims = useClaims();

  const periodTypes = [
    { id: 0, name: t("common:menu.period_select.every_day"), value: "every_day" },
    { id: 1, name: t("common:menu.period_select.every_week_at_day"), value: "every_week_at_day" },
    { id: 2, name: t("common:menu.period_select.every_month_at_day"), value: "every_month_at_day" },
    { id: 3, name: t("common:menu.period_select.every_n_days"), value: "every_n_days" },
  ];

  const list = {
    days: [
      { id: 0, name: t("common:date.day.sunday") },
      { id: 1, name: t("common:date.day.monday") },
      { id: 2, name: t("common:date.day.tuesday") },
      { id: 3, name: t("common:date.day.wednesday") },
      { id: 4, name: t("common:date.day.thursday") },
      { id: 5, name: t("common:date.day.friday") },
      { id: 6, name: t("common:date.day.saturday") },
    ],
    months: [
      { id: 0, name: t("common:misc.time_period_picker.every_month") },
      { id: 1, name: t("common:date.month.january") },
      { id: 2, name: t("common:date.month.february") },
      { id: 3, name: t("common:date.month.march") },
      { id: 4, name: t("common:date.month.april") },
      { id: 5, name: t("common:date.month.may") },
      { id: 6, name: t("common:date.month.june") },
      { id: 7, name: t("common:date.month.july") },
      { id: 8, name: t("common:date.month.august") },
      { id: 9, name: t("common:date.month.september") },
      { id: 10, name: t("common:date.month.october") },
      { id: 11, name: t("common:date.month.november") },
      { id: 12, name: t("common:date.month.december") },
    ],
  };

  const getExecutorConfigString = () => {
    let str = "";
    props.data.executorConfigs
      ?.filter((ex: RecurringIssueExecutorConfigDto) => !ex.isExcluded)
      ?.forEach((e, index) => {
        if (index > 0) {
          str += ", ";
        }
        // str += findRoleNameById(e.roleId!);
        str += e.role?.name
        if (authStore.getCurrentCompanyUiType != 2) {
          str += " - ";
          str += findRolePositionNameById(e.rolePositionType as any, e.rolePosition);
        }
      });
    return str;
  };

  // const findRoleNameById = (id: number): any =>
  //   props.allRoles.find((item) => item.id == id)?.name ?? t("ui:misc.radio_all");

  const findRolePositionNameById = (positionType?: 1 | 2, rolePosition?: RolePositionDto): any => {
    if (positionType != null) {
      if (positionType == 1) return t("common:user_selection_dialog.position_select_box.all_managers");
      if (positionType == 2) return t("common:user_selection_dialog.position_select_box.all_performers");
    } else {
      return rolePosition?.name ?? t("ui:misc.radio_all");
    }
  };

  const getDeadlineFromString = (deadline: string): string => {
    let str = t("parse:recurring_task_card.in") + " ";
    if ((deadline.match(/\./g) || []).length != 2) {
      return "";
    }
    const [months, days, time] = deadline?.split(".");
    const [hours, minutes] = time?.split(":");

    if (Number(months) != 0) {
      str += Number(months);
      str += " " + t("parse:recurring_task_card.months") + " ";
    }

    if (Number(days) != 0) {
      str += Number(days);
      str += " " + t("parse:recurring_task_card.days") + " ";
    }

    if (Number(hours) != 0) {
      str += Number(hours);
      str += " " + t("parse:recurring_task_card.hours") + " ";
    }

    if (Number(minutes) != 0) {
      str += Number(minutes);
      str += " " + t("parse:recurring_task_card.minutes") + " ";
    }

    return str;
  };

  const getPeriodicityString = () => {
    let str = "";
    props.data.rules?.forEach((r, index) => {
      if (index > 0) {
        str += ", ";
      }
      switch (r.type) {
        case "every_day":
          str += t("parse:recurring_task_card.day_at") + " ";
          str += r.timeCreateAt?.substring(0, 5);
          break;
        case "every_week_at_day":
          str += " ";
          str += list.days.find((d) => d.id == r.value)?.name ?? "";
          str += " " + t("parse:recurring_task_card.at") + " ";
          str += r.timeCreateAt?.substring(0, 5);
          break;
        case "every_month_at_day":
          str += " ";
          str +=
            list.months.find((d) => d.id == r.value)?.name == t("parse:recurring_task_card.every")
              ? t("parse:recurring_task_card.month")
              : list.months.find((d) => d.id == r.value)?.name ?? "";
          // if (r.value != 0) {
          str += " ";
          str += r.value2;
          str += " " + t("parse:recurring_task_card.day_d") + " ";
          // }
          str += " " + t("parse:recurring_task_card.at") + " ";
          str += r.timeCreateAt?.substring(0, 5);
          break;
        case "every_n_days":
          str += " ";
          str += r.value;
          str += " " + t("parse:recurring_task_card.days_at") + " ";
          str += r.timeCreateAt?.substring(0, 5);
          break;
      }
    });

    return str;
  };

  const getDeadlineString = () => {
    let str = "";
    if (props.data.dateDeadlineUntil != null) {
      str += list.days.find((d) => d.id == props.data.dateDeadlineUntil?.untilDayOfWeek)?.name ?? "";
      str += " " + t("parse:recurring_task_card.at") + " ";
      str += props.data.dateDeadlineUntil.timeDeadlineAt?.substring(0, 5);
    }
    if (props.data.dateDeadlineAfterTime != null && props.data.dateDeadlineAfterTime.length > 0) {
      str += getDeadlineFromString(props.data.dateDeadlineAfterTime);
    }
    if (
      props.data.dateDeadlineUntil == null &&
      (props.data.dateDeadlineAfterTime == null || props.data.dateDeadlineAfterTime.length == 0)
    ) {
      str = t("parse:recurring_task_card.not_specified");
    }

    return str;
  };

  const getSenderString = () => {
    let str = "";
    switch (props.data.senderType) {
      case "nearest_admin":
        str += t("parse:recurring_task_card.nearest_admin");
        break;
      case "executor_of_role":
        str += t("parse:recurring_task_card.executor_of_function") + " - ";
        str += props.data.senderRole?.name ?? "";
        // str += props.allRoles.find((r) => r.id == props.data.senderRoleId)?.name ?? "";
        break;
      case "admin_of_role":
        str += t("parse:recurring_task_card.admin_of_function") + " - ";
        // str += props.allRoles.find((r) => r.id == props.data.senderRoleId)?.name ?? "";
        str += props.data.senderRole?.name ?? "";
        break;
    }
    return str;
  };

  return (
    <Card variant="secondary" className="TaskRecurringCard-Main" isShadowed={false}>
      <div className="d-flex">
        <Text style={{ flexGrow: 1 }} className="task-recurring-card__title">
          {props.data.name}
        </Text>
        {!props.hideButtons && (
          <div style={{ alignSelf: "flex-start" }} className="d-stack spacing-1">
            <If
              condition={claims.has(permissionKeys.recurringIssue.pauseManage)}
              children={
                <IconButton
                  onClick={() => props.onPauseClick()}
                  size="small"
                  icon={
                    <Icon
                      component={() =>
                        props.data.isPaused ? (
                          <FiPlay stroke="var(--color-primary-base)" />
                        ) : (
                          <FiPause stroke="var(--color-primary-base)" />
                        )
                      }
                    />
                  }
                />
              }
            />
            <If
              condition={claims.has(permissionKeys.recurringIssue.viewHistory)}
              children={
                <IconButton
                  onClick={() => props.onWatchHistoryClick()}
                  size="small"
                  icon={<Icon component={() => <FiEye stroke="var(--color-primary-base)" />} />}
                />
              }
            />
            <If
              condition={claims.has(permissionKeys.recurringIssue.edit)}
              children={
                <IconButton
                  onClick={() => props.onEditClick()}
                  size="small"
                  icon={<Icon component={() => <FiEdit2 stroke="var(--color-primary-base)" />} />}
                />
              }
            />
            <If
              condition={claims.has(permissionKeys.recurringIssue.delete)}
              children={
                <IconButton
                  onClick={() => props.onDeleteClick()}
                  size="small"
                  icon={<Icon component={() => <FiTrash2 stroke="var(--color-error-base)" />} />}
                />
              }
            />
          </div>
        )}
      </div>
      <div className="d-stack-column">
        <Text className="task-recurring-card__subtitle">
          {t("parse:recurring_task_card.to_whom")}:{" "}
          <span className="__text">
            {/*{(props.allRoles ?? []).length == 0 ? t("text:loading_data") : getExecutorConfigString()}*/}
            { getExecutorConfigString() }
          </span>
        </Text>
        <Text className="task-recurring-card__subtitle">
          {t("parse:recurring_task_card.send_every")}: <span className="__text">{getPeriodicityString()}</span>
        </Text>
        <Text className="task-recurring-card__subtitle">
          {t("parse:recurring_task_card.deadline")}: <span className="__text">{getDeadlineString()}</span>
        </Text>
        <Text className="task-recurring-card__subtitle">
          {t("parse:recurring_task_card.sender")}: <span className="__text">{getSenderString()}</span>
        </Text>
      </div>
      <div className="d-flex justify-space-between align-center mr-n3 mb-n2">
        {props.data.isPaused ? (
          <Text style={{ fontSize: "12px", color: "var(--color-txt-error)" }}>
            {t("parse:recurring_task_card.paused_task")}
          </Text>
        ) : (
          <div />
        )}
        <div
          className="pa-2 d-flex align-center"
          style={{
            background: "var(--color-background-strong)",
            borderTopLeftRadius: "4px",
            overflow: "hidden",
          }}
        >
          <UserInfo user={props.data.createdByUser} />
        </div>
      </div>
    </Card>
  );
}

export default TaskRecurringCardView;
