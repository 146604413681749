import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { SearchInputField } from "../../../../elements/searchInputField/SearchInputField";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../../../hooks/useDebounce";
import { FilterDropdownContent } from "././filterDropdownContent/FilterDropdownContent";
import { FilterButton } from "../../../../elements/filterButton/FilterButton";
import { IIssuesArchiveHeader } from "./IssuesArchiveHeader.interface";
import "./IssuesArchiveHeader.scss";
import { valueType } from "../../../../elements/sortButton/SortButton.interface";
import { SortButton } from "../../../../elements/sortButton/SortButton";
import { IDropdownItem } from "../../../../uiKit/navigation/dropdown/Dropdown";
import { ArchiveSortFiltersKeys } from "../IssuesArchive.interface";

export const IssuesArchiveHeader = memo((
  {
    searchValue,
    changeSearch,
    orgchartId,
    onChangeOrgchartId,
    senderId,
    onChangeSenderId,
    executorId,
    onChangeExecutorId,
    sort,
    onChangeSort,
    isStatusNotDone,
    onChangeStatusNotDone,
    totalCount
  }: IIssuesArchiveHeader
) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState(searchValue ?? "");
  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const debouncedSearch = useDebounce(search, 400);
  useEffect(() => {
    changeSearch(debouncedSearch);
  }, [debouncedSearch]);

  const isActiveFilter = useMemo(() => {
    return !!orgchartId || !!senderId || !!executorId || isStatusNotDone;
  }, [orgchartId, senderId, executorId, isStatusNotDone])

  const handleSortAsc = useCallback(() => {
    onChangeSort(ArchiveSortFiltersKeys.asc);
  }, []);

  const handleSortDesc = useCallback(() => {
    onChangeSort(ArchiveSortFiltersKeys.desc);
  }, []);

  const sortMenuValues: IDropdownItem[] = [
    {
      id: 1,
      key: ArchiveSortFiltersKeys.desc,
      text: t("common:misc.sort_items.ones_first"),
      onClick: handleSortDesc
    },
    {
      id: 2,
      key: ArchiveSortFiltersKeys.asc,
      text: t("common:misc.sort_items.old_ones"),
      onClick: handleSortAsc,
    },
  ];

  const sortValue: valueType = useMemo(() => {
    const sortItem =  sortMenuValues.find((item) => item.key === sort)!;
    return {
      id: sortItem.key!,
      key: sortItem.key!,
      text: sortItem.text!,
    }
  }, [sort])


  return (
    <div className="archive-issues-header">
      <SubheaderText
        text={`${t("ui:subheader.issue_name")}${typeof totalCount === 'number' ? ` (${totalCount})` : ''}`}
        noBottomOffset
      />
      <div className="archive-issues-header-row">
        <SearchInputField
          value={search}
          setValue={handleChangeSearch}
          placeholder={t("ui:placeholder.issue_name")}
        />
        <SortButton
          value={sortValue}
          sortMenuValues={sortMenuValues}
          dropDownClassName="archive-issues-header__sort"
          isShowBorder
        />

        <FilterButton
          dropdownContent={
            <FilterDropdownContent
              orgchartId={orgchartId}
              onChangeOrgchartId={onChangeOrgchartId}
              senderId={senderId}
              onChangeSenderId={onChangeSenderId}
              executorId={executorId}
              onChangeExecutorId={onChangeExecutorId}
              isStatusNotDone={isStatusNotDone}
              onChangeStatusNotDone={onChangeStatusNotDone}
            />
          }
          isActiveFilter={isActiveFilter}
          isShowBorder
        />
      </div>
    </div>
  )
});