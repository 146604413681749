import React, { useEffect, useState } from "react";
import CreateEditCompanyDialogView from "./CreateEditCompanyDialogView";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { CompanyForAdminDto, CompanyModuleTypeDto } from "../../../../api";
import { api } from "../../../../services";
import { AxiosError } from "axios";
import { CompanyUserLimitDto, User2CompanyAccessLevel } from "../../../../api/models/CompanyUserLimitDto";
import { USER_LIMITS } from "../../../../constants/userLimits";
import { CompanyFeatureDto } from "../../../../api/models/CompanyForAdminDto";

interface ICreateEditCompanyDialog {
  open: boolean;
  companyId?: number | null;

  onSave?: () => void;
  onCreate?: (data: CompanyForAdminDto) => void;
  onClose: () => void;
}

function CreateEditCompanyDialog(props: ICreateEditCompanyDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [companyModuleArray, setCompanyModuleArray] = useState<CompanyFeatureDto[]>([]);
  const [companyModuleTypeArray, setCompanyModuleTypeArray] = useState<CompanyFeatureDto[]>([]);
  const [companyModuleTypeArrayActive, setCompanyModuleTypeArrayActive] = useState<number[]>([]);
  const [companyToEditData, setCompanyToEditData] = useState<CompanyForAdminDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [settingsState, setSettingsState] = useState<CompanyForAdminDto>({
    contactName: "",
    contactPhoneNumber: "",
    uiType: 2,
    userLimit: null,
    externalId: "",
    userLimits: [
      {
        accessLevel: User2CompanyAccessLevel.Full,
        limit: 0,
      },
      {
        accessLevel: User2CompanyAccessLevel.Restricted,
        limit: 0,
      }
    ],
    tariffCode: null,
    endAccessTime: null,
    billingType: null,
  });


  useEffect(() => {
    if (props.open) initializeCompanyToEdit();
  }, [props.open]);

  const initializeCompanyModuleType = async () => {
    const resp = await api.helper.companyModuleType();
    if (resp == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }

    const modules: CompanyFeatureDto[] = resp.map(({key}) => ({
      key,
      isEnable: false,
    }))
    setCompanyModuleArray(modules ?? []);
  };

  useEffect(() => {
    initializeCompanyModuleType()
  }, []);

  const initializeCompanyToEdit = async () => {
    if (props.companyId == null) {
      setCompanyModuleTypeArray(companyModuleArray);
      return;
    }
    setIsLoading(true);
    const r = await api.adminCompany.getById(props.companyId);
    setIsLoading(false);
    if (r == null) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    const { availableFeatures } = r;
    setCompanyModuleTypeArray(availableFeatures ?? []);
    setCompanyToEditData(r);
    const userLimits: CompanyUserLimitDto[] = r.userLimits
      ? USER_LIMITS.map((item) => {
        const companySetting = (r.userLimits ?? [])?.find(( { accessLevel }) => accessLevel === item.accessLevel);
        return {
          accessLevel: item.accessLevel,
          limit: companySetting ? companySetting.limit : item.limit,
        }
      })
      : USER_LIMITS;

    setSettingsState({
      ...settingsState,
      isInactive: r.isInactive ?? false,
      uiType: r.uiType,
      contactName: r.contactName ?? "",
      contactPhoneNumber: r.contactPhoneNumber ?? "",
      userLimit: r.userLimit ?? null,
      externalId: r.externalId ?? "",
      userLimits,
      tariffCode: r.tariffCode ?? null,
      endAccessTime: r.endAccessTime ?? null,
      billingType: r.billingType ?? null,
    });
  };

  const handleApiErrorMessage = (error?: AxiosError) => {
    const message =
      error?.response?.status == 409 ? error?.response?.data.message : t("notifier:error.company_created");
    notifier.show({
      message: message ?? t("notifier:error.existing_external_id"),
      theme: "error",
    });
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const r = await api.adminCompany.create(
      {
        ...settingsState,
        availableFeatures: companyModuleTypeArray,
      },
      handleApiErrorMessage
    );
    setIsLoading(false);
    // notifier.showSuccessError(t("notifier:success.company_created"), handleApiErrorMessage(), r != null);
    if (r != null) {
      notifier.show({
        message: t("notifier:success.company_created"),
        theme: "success",
      });
      props.onCreate && props.onCreate(r);
      handleClose();
      return;
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    const r = await api.adminCompany.edit(
      props.companyId as number,
      {
        ...companyToEditData,
        ...settingsState,
        availableFeatures: companyModuleTypeArray,
      },
      undefined,
      handleApiErrorMessage
    );
    setIsLoading(false);
    // notifier.showSuccessError(t("notifier:success.company_edited"), t("notifier:error.company_edited"), r != null);
    if (r != null) {
      notifier.show({
        message: t("notifier:success.company_edited"),
        theme: "success",
      });
      props.onSave && props.onSave();
      handleClose();
    }
  };

  const handleModuleTypeChange = (key: string, isEnable: boolean) => {
    setCompanyModuleTypeArray((prev) => prev.map((item) => item.key === key
      ? {...item, isEnable}
      : item
    ));
  };

  const handleClose = () => {
    props.onClose();
    setCompanyToEditData(null);
    setIsLoading(false);
    setCompanyModuleTypeArrayActive([]);
    setSettingsState({
      ...settingsState,
      uiType: 2,
      contactName: "",
      contactPhoneNumber: "",
      userLimit: null,
      externalId: "",
    });
  };

  return (
    <CreateEditCompanyDialogView
      open={props.open}
      companyModuleTypeArrayActive={[]}
      settingsState={settingsState}
      setSettingsState={setSettingsState}
      companyId={props.companyId}
      isLoading={isLoading}
      companyModuleTypeArray={companyModuleTypeArray}
      handleModuleTypeChange={handleModuleTypeChange}
      onClose={handleClose}
      onSave={handleSave}
      onCreate={handleCreate}
    />
  );
}

export default CreateEditCompanyDialog;
